import { ReactElement, useState } from "react";
import Typography from "components/Typography";
import { createStyles, withStyles } from "@material-ui/styles";

type TabsProps = {
  children: ReactElement[]
}

const styles = createStyles((theme: any) => ({
  root: {
    padding: "48px 0 0 0",
    [theme.breakpoints.down('xs')]: {
      padding: "24px 0 16px 0",
    },
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    borderTopLeftRadius: 33,
    borderTopRightRadius: 33,
    backgroundColor: "#565966",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      borderRadius: 0,
    },
  },
  tab: {
    display: "flex",
    position: "relative",
    flexBasis: ({ children }: TabsProps) => `${100/children.length}%`,
    height: "64px",
    justifyContent: "start",
    paddingLeft: "30px",
    alignItems: "center",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    cursor: "pointer",
    transition: "all .3s ease-out",
    [theme.breakpoints.down('xs')]: {
      flexBasis: "unset !important",
      borderRadius: 0,
      color: "#565966",
      backgroundColor: "#f5f5f5",
      paddingLeft: "16px",
    },
  },
  seltab: {
    display: "flex",
    position: "relative",
    flexBasis: ({ children }: TabsProps) => `${100/children.length}%`,
    height: "64px",
    backgroundColor: "#FFF",
    justifyContent: "start",
    paddingLeft: "30px",
    alignItems: "center",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    cursor: "pointer",
    transition: "all .3s ease-out",
    [theme.breakpoints.down('xs')]: {
      width: "100vw !important",
      border: 0,
      display: "none",
      paddingLeft: "16px",
    },
  },
  button: {
    position: "relative",
    display: "none",
    height: "64px",
    justifyContent: "start",
    paddingLeft: "30px",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      paddingLeft: "16px",
      fontSize: "1.127em",
    },
    "&:after": {
      content: "''",
      position:"absolute",
      border:"10px solid #000",
      borderColor: "#FFF transparent transparent transparent",
      display: "block",
      right: 16,
      top: "45%",
      transition: "borderColor .2s ease-out"
    },
  },
  text: {
    color: "#FFF",
    fontSize: "20px",
    [theme.breakpoints.down('xs')]: {
      color: "#565966",
      fontSize: "18px",
    },
  },
  textselected: {
    color: "#111216",
    fontSize: "20px",
    [theme.breakpoints.down('xs')]: {
      color: "#FFF",
      fontSize: "18px",
    },
  },
  opened: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  closed: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  content: {
    backgroundColor: "#FFF",
    padding: "42px 32px",
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: "24px 16px",
    },
  },
  separator: {
    position: "absolute",
    top: 18,
    right: 0,
    width: "1px",
    height: "28px",
    backgroundColor: "#fff",
  },
  separatorxs: {
    width: 0,
    height: 0,
    backgroundColor: "#CCCED4",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "1px",
    },
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "tabs" | "tab" | "seltab" | "button" | "text" | "textselected" | "opened" | "closed" | "content" | "separator" | "separatorxs", string>> };

export default withStyles(styles)(({ classes, children }: TabsProps & ClassProps ) => {  
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(0);

  const select = (index: number) => {
    setSelected(index);
    setOpened(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <div onClick={() => setOpened(!opened)} className={classes.button}>
          <Typography color={"#FFF"}>{children[selected].props.title}</Typography>
        </div>
        <div className={opened ? classes.opened : classes.closed}>
          {children.map((item, index) => (
            <div onClick={() => {
              select(index);
            }} className={selected === index ? classes.seltab : classes.tab} key={index}>
              <Typography align={"center"} className={selected === index ? classes.textselected : classes.text}>{item.props.title}</Typography>
              {selected !== index && selected-1 != index && index !== children.length-1 && <div className={classes.separator}></div>}
            </div>
          ))}
          <div className={classes.separatorxs}></div>
        </div>        
      </div>
      {children.map((child, index) => (
        selected === index && <div className={classes.content}>
          {child}
        </div>
      ))}
    </div>
  )
});
