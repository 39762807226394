import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    height: "100%",
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint2 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box className={classes.content}>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Whilst the vast majority of people describe their gender as either man or woman, and never question that, we now offer options that enable everyone to tell us about their gender. This includes people whose gender is neither man nor woman (non-binary), and/or whose gender is not fixed (gender-fluid), and/or those who may be actively questioning whether that is their experience (gender questioning) as well as those who experience very little or no sense of gender (agender)."}</Typography>
    </Box>
  );
});