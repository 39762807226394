import { styled } from "@material-ui/styles";
import theme from "theme";
import Box from "components/Box";

interface LogoProps {
  variant?: "top" | "bottom";
}

export default styled(Box)((({ variant }: LogoProps) => ({
  position: "absolute",
  top: variant === "bottom" ? undefined : 38,
  right: 48,
  bottom: variant === "bottom" ? 14 : undefined,
  display: "flex",
  zIndex: 1,
  justifyContent: "flex-end",
  "& img": {
    width: "50%",
  },
  [theme.breakpoints.down('xs')]: {
    top: variant === "bottom" ? undefined : 16,
    right: 28,
  },
})));