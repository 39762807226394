import { styled } from "@material-ui/styles";
import Box from "components/Box";

interface ImageProps {
  ratio?: number;
  src: string;
}

export default styled(Box)(({ ratio = 0, src }: ImageProps) => ({
  paddingBottom: `${100 / ratio}%`,
  backgroundImage: `url(${src})`,
  backgroundPosition: `50% 50%`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `contain`,
}));
