import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import { BoxProps } from './Box';
import {Tween, PlayState} from "react-gsap"
import Button from './Button';
import Typography from './Typography';
import { HintsManager } from "components/HintsManager";

interface HintProps {
    order?: number;
    invisible: boolean;
    onOpen?: (value: boolean) => void;
}

const styles = createStyles((theme: any) => ({
    root: {
        width: "400px",
        height: "230px",
        alignItems: "center",
        justifyContent: "flex-end",
        fontSize: "1.25em",
        color: '#fff',
        marginLeft: "-368px",
        [theme.breakpoints.down('sm')]: {
            width: "200px",
            marginLeft: "-168px",
        }
    },
    "@keyframes pulse": {
        "0%": {
            transform: "translate(0px)",
        },
        "14%": {
            transform: "translate(12px)",
        },
        "34%": {
            transform: "translate(0px)",
        },
        "51%": {
            transform: "translate(12px)",
        },
        "65%": {
            transform: "translate(0px)",
        },
        "79%": {
            transform: "translate(12px)",
        },
        "100%": {
            transform: "translate(0px)",
        },
    },
    background: {
        display: "flex",
        borderRadius: 32,
        width: "100%",
        height: "100%",
        flexDirection: "row",
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 55.47%)",
        animation: "$pulse 4000ms ease-out",
        animationDelay: "500ms",
    },
    still: {
        display: "flex",
        borderRadius: 32,
        width: "100%",
        height: "100%",
        flexDirection: "row",
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 55.47%)",
    },
    img: {
        cursor: "pointer",
        display: "flex",
        width: "32px",
        paddingRight: "10px",
    },
    hintOpen: {
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        transition: "opacity .3s ease-in",
        opacity: 1,
        transitionDelay: ".5s"
    },
    hintClose: {
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        transition: "opacity .1s ease-in",
        opacity: 0,
        transitionDelay: 0,
    }
}));

type ClassProps = { classes: Partial<Record<"root" | "img" | "background" | "hintOpen" | "hintClose" | "still", string>> };

export const Hint = withStyles(styles)(({ classes, order, invisible, onClick, onOpen }: HintProps & ClassProps & BoxProps) => {
    const [open, setOpen] = useState(0);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const click = (event: any) => {
        event.stopPropagation();
        setDialogVisible(true);
        if (onClick) onClick(event);
    }
    const toggle = (event: any) => {
        setOpen(open !== 1 ? 1 : 2);
        if (onOpen) onOpen(open !== 1);
    }

    useEffect(() => {
        if (invisible && open === 1) {
            setOpen(0);            
        }
    }, [invisible]);

    return (
    <Tween to={{marginLeft: "0"}} duration={0.5} playState={open ? open === 1 ? PlayState.play : PlayState.reverse : PlayState.stop}>
            <div className={classes.root} style={{ opacity: invisible ? 0 : 1, height: invisible ? 0 : "230px", pointerEvents: invisible ? 'none' : 'all'}}>
            <div className={open === 1 ? classes.still : classes.background}>
                <div className={open === 1 ? classes.hintOpen : classes.hintClose}>
                    <Typography variant={"caption"}>{"Would you like to know more?"}</Typography>
                    <Button onClick={click} size={"small"} variant={"border"} style={{minWidth: 84}}>{"Yes"}</Button>
                </div>
                <img className={classes.img} onClick={toggle} src={`${process.env.PUBLIC_URL}/questionmark.svg`} alt={""}/>
                </div>
                <HintsManager isDialogVisible={isDialogVisible} setDialogVisible={setDialogVisible} order={order} />
        </div>
    </Tween>
    );
});
