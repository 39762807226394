import React, { useContext, useState } from "react";
import Response from "components/Response";
import Button from "components/Button";
import DropdownAnswer from "./DropdownAnswer";
import { Question, QuestionProps } from "./TextQuestion";
import { Tween, PlayState } from "react-gsap";
import { createStyles, withStyles } from "@material-ui/styles";
import { AnswersContext } from "providers/answers";

export interface SingleAnswerQuestionProps extends QuestionProps {
  options?: Question[];
  subOptions?: Question[];
  columns: number;
  children: React.ReactNode;
  variant?: string;
  onAnimationEnd?: () => void;
  order: number;
  hasHint?: boolean;
}

const styles = createStyles((theme: any) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  edit: {
    position: "relative",
    display: "flex",
    marginLeft: "-80px",
    paddingLeft: "80px",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 25.3%, rgba(0, 0, 0, 0) 75.3%, rgba(0, 0, 0, 0.1) 100%)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      paddingBottom: "48px",
    },
  },
  options: {
    paddingTop: "16px",
    display: "flex",
    width: "calc(50vw + 20px)",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      width: "calc(43vw + 20px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "85vw",
      paddingTop: 0,
      marginTop: "-8px",
    },
  },
  optionsAutocomplete: {
    display: "flex",
    width: "50vw",
    flexWrap: "wrap",
    height: "auto",
    overflow: "hidden",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "85vw",
      paddingTop: 0,
    },
  },
  option: {
    flexBasis: ({ columns }: SingleAnswerQuestionProps) => `${100 / columns}%`,
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
      paddingBottom: "8px",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100% !important",
    },
  },
  optionLevel2: {
    flexBasis: "100%",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
      paddingBottom: "8px",
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      height: ({ variant }: SingleAnswerQuestionProps) => (variant === "single-icon" ? "73px !important" : "unset !important"),
      whiteSpace: "unset",
      borderRadius: 32,
    },
  },
  optionsbox: {
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "16px",
    },
  },
  bubble: {
    paddingBottom: "32px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "8px",
    },
  },
  wrap: {
    paddingLeft: "48px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
    },
  },
  levelOne: {
    width: "100%",
    transition: "opacity .3s ease-out",
  },
  levelTwo: {
    opacity: 0,
    width: 0,
    transition: "opacity .3s ease-out",
  },
  back: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

type ClassProps = {
  classes: Partial<
    Record<"options" | "option" | "root" | "edit" | "button" | "optionsbox" | "bubble" | "responseWrap" | "optionsAutocomplete" | "wrap" | "levelOne" | "levelTwo" | "optionLevel2" | "back", string>
  >;
};

export const SingleAnswerQuestion = withStyles(styles)(
  ({ classes, children, variant, columns, options, subOptions, hasHint, order, onChange, onSkip, onAnimationEnd }: SingleAnswerQuestionProps & ClassProps) => {
    const [answered, setAnswered] = useState<Question | undefined>();
    const autocomplete = options && options?.length > 50 ? true : false;
    const [respond, setRespond] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [selectedSub, setSelectedSub] = useState(-1);
    const [level, setLevel] = useState(1);
    const { data, setData } = useContext(AnswersContext);
    const [skipUpdate, setSkipUpdate] = useState(false);
    const [_subOptions, setSubOptions] = useState<Question[]>([]);
    const [open, setOpen] = useState<boolean | undefined>();

    const skipQuestion = () => {
      onSkip && onSkip();
      setAnswered({ key: -1, text: "Prefer not to say", shortAnswer: "" });
      setSelected(-1);
      setSelectedSub(-1);
      if (data?.[order]?.edit) {
        setSkipUpdate(true);
        let firstItem: any = data?.find((item) => item.order === order + 1) || {};
        firstItem.edit = false;
        if (setData) setData([...(data || [])]);
      }
      if(open)
        setOpen(!open);
    };

    const submitAnswer = (option: Question) => {
      if (!respond) {
        let responseText = option.text;
        if (subOptions && subOptions.length > 0) {
          responseText = options?.[selected - 1] ? `${options?.[selected - 1].text}, ${option.text}` : `${option.text}`;
        }
        onChange && onChange([{ ...option, text: responseText }]);
        setAnswered({ ...option, text: responseText });
      }
      if (level === 2) {
        setSelectedSub(option.key);
      }
    };

    const animationEnd = () => {
      setRespond(true);
      var _skip = false;
      setSkipUpdate((s) => {
        if (s) _skip = s;
        return s;
      });
      if (onAnimationEnd && !_skip) onAnimationEnd();
    };

    const submitChangedAnswer = (skipUpdate?: boolean) => {
      if (options && selected) {
        if (!skipUpdate) {
          let responseText = "";
          if (subOptions && subOptions.length > 0 && options[selected - 1]?.text?.toLowerCase() !== "prefer not to say") {
            if (selected > -1) {
              responseText = `${options?.[selected - 1].text}, ${subOptions[selectedSub - 1].text}`;
            } else {
              responseText = subOptions[selectedSub - 1].text;
            }
          } else {
            responseText = options[selected - 1].text;
          }

          onChange && onChange([{ text: responseText, key: selected }]);
          setAnswered({ text: responseText, key: selected, shortAnswer: "" });
        }

        setSkipUpdate(true);
        let firstItem: any = data?.find((item) => item.order === order + 1) || {};
        firstItem.edit = false;
        if (setData) setData([...(data || [])]);
        setOpen(false);
      }
    };

    const showLevelOne = () => {
      setLevel(2);
    };

    const basePadding = 8;

    return (
      <div className={classes.wrap}>
        <div className={classes.bubble}>{children}</div>
        <div className={data?.[order]?.edit ? classes.edit : classes.root}>
          <Tween
            onComplete={autocomplete && animationEnd}
            from={{ opacity: 1, height: "100%" }}
            to={{ opacity: 0, height: 0 }}
            delay={0.5}
            duration={0.5}
            playState={answered ? (data?.[order]?.edit ? PlayState.reverse : PlayState.play) : PlayState.stop}
          >
            {autocomplete ? (
              <div className={classes.optionsAutocomplete} style={{ height: data?.[order]?.edit ? "unset !important" : undefined }}>
                {(!answered || selected) && (
                  <>
                    <DropdownAnswer
                      width={"100%"}
                      onChange={(value) => {
                        setSelected(value.key);
                        submitAnswer(value);
                      }}
                      edit={data?.[order]?.edit ? answered : undefined}
                      autocomplete={options}
                      placeholder={"Enter here"}
                    ></DropdownAnswer>
                    <div style={{ display: "flex", paddingTop: `${3 * basePadding}px` }}>
                      <Button variant={"text"} onClick={skipQuestion}>
                        {"Prefer not to say"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div style={{ height: data?.[order]?.edit ? "100%" : "auto" }}>
                <Tween onComplete={animationEnd} to={{ opacity: 0 }} duration={0.5} playState={answered ? (data?.[order]?.edit ? PlayState.reverse : PlayState.play) : PlayState.stop}>
                  <div className={classes.optionsbox} style={{ display: data?.[order]?.edit || !respond ? "flex" : "none" }}>
                    {(!answered || selected) && (
                      <>
                        <div className={classes.levelOne} style={{ opacity: level === 1 ? 1 : 0, width: level === 1 ? "100%" : 0, height: level === 1 ? "100%" : 0 }}>
                          <div className={classes.options}>
                            {options?.map((option, i) => (
                              <div className={classes.option} key={option.key} style={{ paddingRight: `${2 * basePadding}px` }}>
                                <Button
                                  className={classes.button}
                                  multiline={columns === 1}
                                  size={"small"}
                                  variant={selected === option.key ? "filled" : "border"}
                                  disabled={answered ? (data?.[order]?.edit ? false : true) : false}
                                  icon={
                                    variant === "single-icon"
                                      ? selected === option.key
                                        ? `${process.env.PUBLIC_URL}/${option.key}-selected.svg`
                                        : `${process.env.PUBLIC_URL}/${option.key}.svg`
                                      : undefined
                                  }
                                  onClick={() => {
                                    if (subOptions) {
                                      if (option.text.toLowerCase() !== "prefer not to say") {
                                        setSubOptions(
                                          subOptions?.filter((so) => {
                                            if (so?.categories) {
                                              return so?.categories?.indexOf(option?.key) > -1;
                                            }
                                            return false;
                                          })
                                        );
                                        showLevelOne();
                                        setSelected(option?.key);
                                      } else {
                                        setSelected(option?.key);
                                        submitAnswer(option);
                                      }
                                    } else {
                                      setSelected(option?.key);
                                      submitAnswer(option);
                                    }
                                  }}
                                >
                                  {option.text}
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={classes.levelTwo} style={{ opacity: level === 2 ? 1 : 0, width: level === 2 ? "100%" : 0, height: level === 2 ? "100%" : 0 }}>
                          <div className={classes.options}>
                            {_subOptions?.map((option, i) => (
                              <div className={classes.optionLevel2} key={option.key} style={{ paddingRight: `${2 * basePadding}px` }}>
                                <Button
                                  className={classes.button}
                                  multiline={columns === 1}
                                  size={"small"}
                                  variant={selectedSub === option.key ? "filled" : "border"}
                                  disabled={answered ? (data?.[order]?.edit ? false : true) : false}
                                  icon={
                                    variant === "single-icon"
                                      ? selected === option.key
                                        ? `${process.env.PUBLIC_URL}/${option.key}-selected.svg`
                                        : `${process.env.PUBLIC_URL}/${option.key}.svg`
                                      : undefined
                                  }
                                  onClick={() => submitAnswer(option)}
                                >
                                  {option.text}
                                </Button>
                              </div>
                            ))}
                          </div>
                          <div style={{ width: "90px" }}>
                            <Button
                              onClick={() => {
                                setLevel(1);
                              }}
                              variant={"text"}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <img alt={""} src={`${process.env.PUBLIC_URL}/back-white.svg`} />
                                <div style={{ paddingLeft: "6px" }} className={classes.back}>
                                  {"Back"}
                                </div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Tween>
              </div>
            )}
          </Tween>
          {answered && respond && (
            <Response
              open={open}
              onOpen={() => {
                if (open) {
                  submitChangedAnswer(true);
                }
                setOpen(!open);
              }}
              order={order}
              hasHint={hasHint}
              icon={variant === "single-icon" && selected !== -1 ? `${selected}.svg` : undefined}
              onSubmit={() => {
                submitChangedAnswer();
                setOpen(!open);
              }}
            >
              {answered.text}
            </Response>
          )}
        </div>
      </div>
    );
  }
);
