import React from "react";
import { styled } from "@material-ui/styles";
import Box, { BoxProps } from "components/Box";
import theme from "theme";

interface ModalProps {
  visible?: boolean;
  size?: "small" | "medium" | "large";
  variant?: "filled" | "black" | "none";
  onClose?: () => void;
}

interface CloseButtonProps {
  visible?: boolean;
  variant?: "filled" | "black" | "none";
}

const ModalOverlay = styled(({ children, onClick, ...props }: BoxProps) => (
  <Box {...props} onClick={onClick}>
    {children}
  </Box>
))({
  position: "fixed",
  overflow: "hidden",
  opacity: ({ visible }: ModalProps) => (visible ? 1 : 0),
  visibility: ({ visible }: ModalProps) => (visible ? "visible" : "hidden"),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  zIndex: 10002,
});

const CloseButton = styled(({ variant, onClick, ...props }: CloseButtonProps & BoxProps) => <Box {...props} onClick={onClick}></Box>)({
  position: "absolute",
  cursor: "pointer",
  height: "23px",
  width: "23px",
  top: "32px",
  right: "32px",
  "&:before": {
    content: "no-close-quote",
    position: "absolute",
    display: "inline-block",
    width: "1px",
    height: "23px",
    left: "9px",
    borderRight: ({ variant = "none" }: CloseButtonProps) => (variant === "none" ? "3px solid #fff" : variant === "filled" ? "3px solid #0680C2" : "3px solid #565966"),
    transform: "rotate(-45deg)",
    transformOrigin: "center center",
  },
  "&:after": {
    content: "no-close-quote",
    position: "absolute",
    display: "inline-block",
    width: "1px",
    height: "23px",
    left: "9px",
    borderRight: ({ variant = "none" }: CloseButtonProps) => (variant === "none" ? "3px solid #fff" : variant === "filled" ? "3px solid #0680C2" : "3px solid #565966"),
    transform: "rotate(45deg)",
    transformOrigin: "center center",
  },
});

export default styled(({ children, variant, size, visible, onClose, ...props }: ModalProps & BoxProps) => (
  <ModalOverlay onClick={onClose} visible={visible}>
    <Box style={{ position: "relative" }} onClick={(e) => e.stopPropagation()} {...props}>
      <Box className={"inner-wrap"} style={{}}>
        {children}
      </Box>
      <Box style={{position: 'absolute', left: 0, right: 0, top: 0, background: '#fff', height: '56px'}}>
        {onClose && <CloseButton variant={variant} onClick={onClose} />}
      </Box>
    </Box>
  </ModalOverlay>
))({
  position: "fixed",
  overflow: "hidden",
  opacity: ({ visible }: ModalProps) => (visible ? 1 : 0),
  visibility: ({ visible }: ModalProps) => (visible ? "visible" : "hidden"),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 32,
  minWidth: "300px",
  maxWidth: "1034px",
  minHeight: "321px",
  maxHeight: "600px",
  [theme.breakpoints.down("md")]: {
    width: "calc(100vw - 64px)",
    minHeight: "calc(100vh - 288px)",
  },
  [theme.breakpoints.down("xs")]: {
    top: "24px",
    bottom: "24px",
    padding: "24px",
    paddingTop: "60px",
  },
  "& .inner-wrap": {
    overflowY: "auto",
    maxHeight: "600px",
    height: "100%",
    padding: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "12px",
    },
  },
  padding: "0 12px",
  backgroundColor: ({ variant = "none" }: ModalProps) => (variant === "none" ? "transparent" : "#fff"),
});
