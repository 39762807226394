import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
    },
  },
  subtitle: {
    color: "#0680C2",
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "subtitle" | "text", string>> };

export const Hint23 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{ backgroundColor: "#FFF" }}>
        <Box className={classes.content}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Is your organisation diverse and inclusive, with a focus on creating a sense of belonging in relation to your world views, values and work/life needs?"}
            </Typography>
        </Box>
      </Box>
    </Box>
  );
});
