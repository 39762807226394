import React, { useEffect } from "react";
import { FirebaseAuthContext } from "providers/firebase";
import { Redirect, Route } from "react-router-dom";
import { useAuthorization, useAuthorizationIdle } from "hooks/authorization";
import DashboardPage from "pages/Dashboard";
import { DASHBOARD, EMPLOYER_HOME } from "routes/types";

const { Consumer } = FirebaseAuthContext;

const AuthorizedScreen = ({ children }: { children: React.ReactNode }) => {
  const [isAuthorize, , , , , , isEmployer] = useAuthorization();
  return <>{isAuthorize && isEmployer ? children : <Redirect to={EMPLOYER_HOME} />}</>;
};

const EmployerRoutes = () => {
  useAuthorizationIdle();

  return (
    <>
      <AuthorizedScreen>
        <Route path={DASHBOARD} exact>
          <DashboardPage />
        </Route>
      </AuthorizedScreen>
    </>
  );
};

export default EmployerRoutes;
