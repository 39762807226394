import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    height: "100%",
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint38 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box className={classes.content}>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>Intersex people are born with sex characteristics (including genitals, reproductive organs and/or chromosome patterns) that vary from typical binary notions of male or female bodies. Please note, not all intersex traits become known at birth – they may appear later on, for example at puberty when secondary sexual development doesn’t take the expected course, or through investigations carried out when someone is experiencing infertility.</Typography>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>Many people may have variations to their sex characteristics arising from consensual surgery or use of hormone therapy, or non-consensual / violent acts such as FGM performed after they were born, but these are not intersex experiences and do not make someone intersex. </Typography>
    </Box>
  );
});