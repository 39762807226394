import { styled } from "@material-ui/styles";
import React, { forwardRef, useEffect, useState } from "react";
import { Theme } from "theme";
import Box, { BoxProps, BoxRef } from "components/Box";
import Typography from "components/Typography";
import { Question } from "./TextQuestion";
import { Tween, PlayState } from "react-gsap";
import Input from "components/Input";

interface DropdownProps {
  errorMessage?: string;
  placeholder?: string;
  value?: string;
  autocomplete: Question[] | undefined;
  onChange?: (e: Question) => void;
  onAnimationEnd?: () => void;
  edit?: Question | undefined;
}

const DivBase = styled(({ ...props }) => <div {...props} />)(({ theme }: { theme: Theme }) => ({
  padding: "8px 40px",
  color: "#000",
  cursor: "pointer",
  fontSize: "18px",
  [theme.breakpoints.up("sm")]: {
    "&:hover": {
      backgroundColor: "#E5E5E5",
    },
  },
}));

export default forwardRef(({ errorMessage, onChange, onAnimationEnd, autocomplete, placeholder, width, edit, ...other }: DropdownProps & Omit<BoxProps, "onChange">, ref: BoxRef) => {
  const [value, setValue] = useState("");
  const [filtered, setFiltered] = useState<Question[] | undefined>(autocomplete);
  const [open, setOpened] = useState<boolean | undefined>();
  const [answered, setAnswered] = useState(false);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    function handleKeyDown(event: any) {
      if (open) {
        if (event.keyCode === 40) console.log("down");
        if (event.keyCode === 38) console.log("up");
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref, open]);

  const parseList = (val: string) => {
    setOpened(val.length > 1);
    setValue(val);
    if (val.length < 2) {
      setFiltered([]);
      return;
    }

    let list =
      autocomplete &&
      autocomplete.filter((v) => {
        if (v && v.text.toLowerCase().includes(val.toLowerCase(), 0)) return { ...v };
        return null;
      });
    setFiltered(list);
  };

  const submit = (answer: Question, e: any) => {
    setAnswered(true);
    setValue(answer.text);
    setSelected(answer.key);
    onChange && onChange(answer);
  };

  return (
    <Box ref={ref} width={width} pt={3}>
      <Tween onComplete={onAnimationEnd} to={{ opacity: 0, display: "none" }} duration={0.5} playState={answered ? (edit ? PlayState.reverse : PlayState.play) : PlayState.stop}>
        <Box borderRadius={32} style={{ display: "block", backgroundColor: "#F5F5F5", height: "unset" }}>
          <Input placeholder={placeholder} value={value} size={"small"} filled onChange={(e) => parseList(e)} />
          {errorMessage && (
            <Box position={"absolute"} px={3} py={3}>
              <Typography variant={"body1"} color={"#F5F5F5"}>
                {errorMessage}
              </Typography>
            </Box>
          )}
          <Tween to={{ opacity: 1, maxHeight: 200, display: "block" }} duration={0.5} playState={open ? PlayState.play : PlayState.stop}>
            <Box padding={0} style={{ overflowY: "scroll", display: "none", opacity: 0, maxHeight: 0, borderBottomLeftRadius: 32, borderBottomRightRadius: 32 }}>
              {filtered?.map((answer, i) => (
                <DivBase key={i} style={{ background: selected === answer.key ? "#ffffff" : "transparent" }} onClick={(e: any) => submit(answer, e)}>
                  {answer.text}
                </DivBase>
              ))}
            </Box>
          </Tween>
        </Box>
      </Tween>
    </Box>
  );
});
