import { createBreakpoints } from "services/utils";

const theme = {
  primaryColor: "#076598",
  textDisabledColor: "#CCCED4",
  errorColor: "#FD5050",
  typography: {
    h1: {
      fontSize: "6.5em"
    },
    h2: {
      fontSize: "4em",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2.375em",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.5em",
      fontWeight: 500,
    },
    h5: {
      fontSize: "19px",
      fontWeight: 600,
    },
    caption: {
      fontSize: "1.25em",
      fontWeight: 500,
    },
    body1: {
      color: "#565966",
      fontSize: "1.5em"
    },
    title1: {
      fontSize: "2.375em",
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1.0em",
      color: "#0680C2",
    },
    subtitle2: {
      fontSize: "1.5em",
      fontWeight: 700,
      color: "#565966",
    }
  },
  breakpoints: createBreakpoints(),
};

export type Theme = typeof theme;

export default theme;