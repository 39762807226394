import { styled, withStyles } from "@material-ui/styles";
import React from "react";
import Box from "components/Box";
import Bubble from "components/Bubble";
import theme from 'theme';

const Dots = withStyles((theme) => ({
  root: {
    position: "relative",
    width: 9,
    height: 9,
    borderRadius: 4.5,
    backgroundColor: theme.primaryColor,
    color: theme.primaryColor,
    animation: "dotFlashing 1s infinite linear alternate",
    animationDelay: ".5s",
    "&:before, &:after": {
      content: "''",
      display: "inline-block",
      position: "absolute",
      top: 0,
    },
    "&:before": {
      left: -19,
      width: 9,
      height: 9,
      borderRadius: 4.5,
      backgroundColor: theme.primaryColor,
      color: theme.primaryColor,
      animation: "dotFlashing 1s infinite alternate",
      animationDelay: "0s",
    },
    "&:after": {
      left: 19,
      width: 9,
      height: 9,
      borderRadius: 4.5,
      backgroundColor: theme.primaryColor,
      color: theme.primaryColor,
      animation: "dotFlashing 1s infinite alternate",
      animationDelay: "1s",
    },
  },
  "@global": {
    "@keyframes dotFlashing": {
      "0%": { opacity: theme.primaryColor },
      "50%, 100%": { backgroundColor: "rgba(7,101,152, 0.5)" }
    },
  }
}))(Box);

export default styled(
  ({ children, ...props }: any) => {
    return (
      <Bubble {...props}>
        <Dots />
      </Bubble>
    );
  }
)(() => ({
  width: 112,
  height: 56,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px 32px",
}));