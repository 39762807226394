import { createStyles, withStyles } from "@material-ui/styles";

type TabProps = {
    children: any;
    title?: string;
}

const styles = createStyles((theme: any) => ({
    root: {
        color: "#484955",
        backgroundColor: "#FFF",
        borderRadius: 32,
    },
  }));

type ClassProps = { classes: Partial<Record<"root", string>> };

export default withStyles(styles)(({ classes, children }: TabProps & ClassProps ) => {  
    return (
        <div className={classes.root}>
            {children}
        </div>
    );
});
