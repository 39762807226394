import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  title: {
    color: "#0680C2",
    fontSize: "40px",
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: "24px",
    }
  },
  subtitle: {
    color: "#0680C2",
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "title" | "subtitle" | "text", string>> };

export const Hint17 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
              <Typography className={classes.text} align={"left"} variant={"body1"}>{"Sometimes people don’t identify with being a carer. It can be difficult for them to see their caring role as separate from the relationship they have with the person for whom they care, whether that relationship is as a parent, fostering parent, child, sibling, partner, friend, or other."}</Typography>
              <div style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <Typography className={classes.text} align={"left"} variant={"body1"}>{"A "}<strong>Secondary carer</strong>{" is defined as someone supporting a primary carer. The primary carer could be a person or an organisation such as a care home or one which provides supported living services for example."}</Typography>
              </div>
              <div style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"A "}<strong>Child</strong>{" is defined as a person aged under 18."}</Typography>
              </div>
            </Box>
        </Box>
    </Box>
  );
});