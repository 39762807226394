import { createStyles, withStyles } from "@material-ui/styles";
import Box from "components/Box";
import Button from "components/Button";
import Image from "components/Image";
import Logo from "components/Logo";
// import Modal from "components/Modal";
import Typography from "components/Typography";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { CONTACT_EMAIL_ADDRESS, CONTACT_EMAIL_SUBJECT, CONTACT_EMAIL_BODY } from "../services/config";
import { LOGO_WHITE_PATH } from "services/config";
// import { CONVERSATION, SCIENCE, DATAUSAGE } from "routes";

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "120px",
    },
  },
  button: {
    marginTop: "16px",
    marginLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      height: 43,
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100vw",
    },
  },
  border: {
    width: "90vw",
    marginRight: "5vw",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid white",
      marginBottom: "10px",
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexDirection: "row",
    paddingTop: "16px",
    paddingLeft: "48px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      paddingLeft: "8px",
    },
  },
  text: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  contact: {
    color: "#FFF",
    fontWeight: 600,
    fontSize: "18px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingLeft: "16px",
      height: "28px !important",
    },
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "button" | "box" | "footer" | "border" | "text" | "contact", string>> };

export const CompletedPage = withStyles(styles)(({ classes }: ClassProps) => {
  // const [isVideoVisible, setVideoVisible] = useState(false);
  const history = useHistory();

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  return (
    <Box display={"flex"} p={4} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      <Box className={classes.root}>
        <Box>
          <Typography className={classes.text} variant={"h2"}>
            Thanks for <br />
            participating
          </Typography>
        </Box>
        <Box mt={7}>
          <Typography className={classes.text} variant={"h3"}>
            Try our RUBY App - Coming Soon
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text} variant={"h4"}>
              Learn more about yourself and how to <br /> improve your work life balance.
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} mt={4}>
            <Box mr={2} style={{ opacity: 0.4 }} onClick={() => {}}>
              <img src={"google-play-badge-128x128.png"} alt='Download on Play sStore' height={55} />
            </Box>
            <Box style={{ opacity: 0.4 }}>
              <img src={"app-store-badge.svg"} alt='Download on App Store' height={55} />
            </Box>
          </Box>
        </Box>
      </Box>
      <div className={classes.footer}>
        <a style={{ textDecoration: "none", paddingBottom: "12px" }} href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=${CONTACT_EMAIL_SUBJECT}&body=${CONTACT_EMAIL_BODY}`}>
          <Typography className={classes.contact} color={"#A03A1F"} variant={"caption"}>
            {"Contact"}
          </Typography>
        </a>
      </div>
      <Logo variant={"bottom"}>
        <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
      </Logo>
    </Box>
  );
});
