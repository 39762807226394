export type Optional<T> = T | undefined | null;
export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export const hasTouch = matchMedia('(hover: none), (pointer: coarse)').matches;

export const timeout = (duration: number = 1000) => new Promise((resolve, _) => {
  setTimeout(() => {
    resolve(undefined);
  }, duration);
});

export const keys = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

export type BreakpointDefaults = Record<ElementType<typeof keys>, true>;
export type Breakpoint = keyof BreakpointDefaults;
export type BreakpointValues = { [key in Breakpoint]: number };

export interface Breakpoints {
  keys: Breakpoint[];
  values: BreakpointValues;
  up: (key: Breakpoint | number) => string;
  down: (key: Breakpoint | number) => string;
  between: (start: Breakpoint | number, end: Breakpoint | number) => string;
  only: (key: Breakpoint) => string;
  width: (key: Breakpoint) => number;
}

export const createBreakpoints = (breakpoints: Partial<{ unit: string; step: number; } & Breakpoints> = {}) => {
  const {
    values = {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    unit = 'px',
    step = 5,
  } = breakpoints;

  const up = (key: Breakpoint) => {
    const value = typeof values[key] === 'number' ? values[key] : key;
    return `@media (min-width:${value}${unit})`;
  };

  const down = (key: Breakpoint) => {
    const endIndex = keys.indexOf(key) + 1;
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      return up('xs');
    }

    const value = Number(typeof upperbound === 'number' && endIndex > 0 ? upperbound : key);
    return `@media (max-width:${value - step / 100}${unit})`;
  }

  return {
    keys,
    values,
    up,
    down,
  }
};