import firebase from "firebase/app";
import { AUTHORIZE_FAILED } from "providers/types";
import { useCallback, useContext } from "react";
import { store } from "providers/authorization";
import { storeCompletionStatus, clearAuthorization } from "services/autorization";
import { clearTimer } from "services/autorization";

export const useLogout = (): [() => void, () => void] => {
  const { dispatch, timeoutIdRef } = useContext(store);  

  const logout = useCallback(async () => {
    try {
        dispatch!({ type: AUTHORIZE_FAILED, payload: "Logging out" });
        if (timeoutIdRef) {
          clearTimer(timeoutIdRef); 
        }
        clearAuthorization();
        storeCompletionStatus();
        await firebase.auth().signOut();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const logoutEmployer = useCallback(async () => {
    try {
      dispatch!({ type: AUTHORIZE_FAILED, payload: "" });

      if (timeoutIdRef) {
        clearTimer(timeoutIdRef); 
      }

      clearAuthorization();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return [logout, logoutEmployer];
};
