import React, { createContext, SetStateAction, useState } from "react";
import { AnswerProps } from "services/questions";
import { submit, getAnswers, getResultsCount, getEmployerStats } from "services/answers";
import { getQuestions } from "services/questions";
import { isNonNullChain } from "typescript";

const mapStats = (input: any, optionsLeft: any, optionsRight: any) => {
  const aggregatedTotals = Object.keys(input)
    .sort((k1, k2) => {
      return input[k1] > input[k2] ? -1 : 1;
    })
    .map((k) => {
      const options = k.split("-");
      return {
        k1: optionsLeft?.options?.find((so: any) => so.key.toString() === options[0])?.text,
        k2: optionsRight?.subOptions
          ? optionsRight?.subOptions?.find((so: any) => so.key.toString() === options[1])?.text
          : optionsRight?.options?.find((so: any) => so.key.toString() === options[1])?.text,
        answerKey: options[1],
        total: input[k],
      };
    })
    .reduce((previousValue: any, currentValue: any) => {
      const currentK1 = currentValue["k1"];
      if (currentK1) {
        if (previousValue[currentK1]) {
          previousValue[currentK1] = {
            total: previousValue[currentK1]["total"] + currentValue["total"],
            items: [...previousValue[currentK1]["items"], { answerKey: currentValue["answerKey"], title: [currentValue["k2"]], value: currentValue["total"] }],
          };
        } else {
          previousValue[currentK1] = {
            total: currentValue["total"],
            items: [{ answerKey: currentValue["answerKey"], title: [currentValue["k2"]], value: currentValue["total"] }],
          };
        }
      }

      return previousValue;
    }, {});
  const sortedStats = Object.keys(aggregatedTotals)
    .sort((k1: any, k2: any) => {
      return aggregatedTotals[k1].total > aggregatedTotals[k2].total ? -1 : 1;
    })
    .map((b: any) => ({ ...aggregatedTotals[b], key: b }));

  return sortedStats;
};

export const AnswersContext = createContext<{
  data?: AnswerProps[];
  stats: any | undefined;
  setData?: React.Dispatch<SetStateAction<AnswerProps[]>>;
  submit?: (id: string) => {};
  getAnswers?: () => any;
  getResultsCount?: (filters: any, tenant: string) => {};
  getEmployerStats?: (tenant: string, options: any) => {};
  getAnswersCount?: () => number;
}>({ stats: undefined });

const AnswersProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<AnswerProps[]>([]);
  const [answersCount, setAnswersCount] = useState<any>(null);
  const [stats, setStats] = useState<any>({});

  return (
    <AnswersContext.Provider
      value={{
        data: data,
        stats,
        setData: setData,
        submit: async (id: string) => {
          submit({
            tenant: id,
            answers: data,
          });
        },
        getAnswers: async () => {
          //TODO: Optimise load if users already have answers.
          const _answers = await getAnswers();
          if (answersCount === null) {
            try {
              setAnswersCount(Object.keys(_answers)?.length);
            } catch (e) {}
          }
          //setAnswers(_answers);
          return _answers;
        },
        getResultsCount: async (filters: any, tenant: string) => {
          try {
            return await getResultsCount(filters, tenant);
          } catch (e) {}
        },
        getEmployerStats: async (tenant: string, options: any) => {
          const genderUnpaidResponsibilities = await getEmployerStats(tenant, "3", "23");
          const availabilityWorkJobEnjoy = await getEmployerStats(tenant, "22", "26");
          const annualEarningsRace = await getEmployerStats(tenant, "20", "8");

          setStats({
            sortedAnnualEarningsRace: annualEarningsRace.map((b: any) => ({
              ...b,
              key: b.items?.[0]?.titlea || "",
              items: b.items.sort((i1: any, i2: any) => (i1.amount > i2.amount ? -1 : 1)),
            })),
            sortedAvailabilityWorkJobEnjoy: availabilityWorkJobEnjoy.map((b: any) => ({
              ...b,
              key: b.items?.[0]?.titlea || "",
              items: b.items.sort((i1: any, i2: any) => (i1.amount > i2.amount ? -1 : 1)),
            })),
            sortedGenderUnpaidResponsibilities: genderUnpaidResponsibilities.map((b: any) => ({
              ...b,
              key: b.items?.[0]?.titlea || "",
              items: b.items.sort((i1: any, i2: any) => (i1.amount > i2.amount ? -1 : 1)),
            })),
          });
        },
        getAnswersCount: () => {
          return answersCount;
        },
      }}
    >
      {children}
    </AnswersContext.Provider>
  );
};

export default AnswersProvider;
