import { createStyles, withStyles } from "@material-ui/styles";
import React, {useState, useEffect} from "react";
import Box, { BoxProps } from "components/Box";
import { Tween, PlayState } from "react-gsap";
import Typography from "./Typography";
import clsx from 'clsx';
import {ReactComponent as Icon1} from '../data/icons/1.svg';
import {ReactComponent as Icon2} from '../data/icons/2.svg';
import {ReactComponent as Icon3} from '../data/icons/3.svg';
import {ReactComponent as Icon4} from '../data/icons/4.svg';
import {ReactComponent as Icon5} from '../data/icons/5.svg';

interface IconBubbleProps {
  title: string;
  data: any[];
}

const styles = createStyles((theme: any) => ({
  root: {
    width: "255px",
    height: "260px",
    position: "relative",
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      flexBasis: "50%",
      height: "200px",
    },
  },
  bubble: {
    position: 'absolute',
    borderRadius: "50%",
    zIndex: 10001,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4
  },
  xl: {
    '& path': {
      fill: '#A03A1F',
    },
    '& svg': {
      width: "117px",
      height: "117px",
      [theme.breakpoints.down('xs')]: {
        width: "77.18px",
        height: "77.18px",
      },
    },
    left: 14,
    top: 34,    
    [theme.breakpoints.down('xs')]: {
      left: 10,
      top: 37,
    },
  },
  large: {
    '& path': {
      fill: '#565966',
    },
    '& svg': {
      width: "77.14px",
      height: "77.14px",
      [theme.breakpoints.down('xs')]: {
        width: "50.88px",
        height: "50.88px",
      },
    },
    left: 140,
    top: 72,    
    [theme.breakpoints.down('xs')]: {
      left: 93.12,
      top: 62.06,
    },
  },
  normal: {
    '& path': {
      fill: '#565966',
    },
    '& svg': {
      width: "63.36px",
      height: "63.36px",
      [theme.breakpoints.down('xs')]: {
        width: "41.8px",
        height: "41.8px",
      },
    },
    left: 95,
    top: 144,
    [theme.breakpoints.down('xs')]: {
      left: 63.43,
      top: 109.56,
    },
  },
  medium: {
    '& path': {
      fill: '#565966',
    },
    '& svg': {
      width: "48.67px",
      height: "48.67px",
      [theme.breakpoints.down('xs')]: {
        width: "32.1px",
        height: "32.1px",
      },
    },
    left: 46,
    top: 180,
    [theme.breakpoints.down('xs')]: {
      left: 31.11,
      top: 133.3,
    },
  },
  small: {
    '& path': {
      fill: '#565966',
    },
    '& svg': {
      width: "33.06px",
      height: "33.06px",
      [theme.breakpoints.down('xs')]: {
        width: "21.81px",
        height: "21.81px",
      },
    },
    left: 159,
    top: 191,
    [theme.breakpoints.down('xs')]: {
      left: 105.65,
      top: 140.57,
    },
  },
  pxl:{
    fontSize: "14px",
    color: "#A03A1F",
    [theme.breakpoints.down('xs')]: {
      fontSize: "8.54px",
    },
  },
  plg:{
    fontSize: "14px",
    color: "#565966",
    [theme.breakpoints.down('xs')]: {
      fontSize: "8.54px",
    },
  },
  pmd:{
    fontSize: "14px",
    color: "#565966",
    [theme.breakpoints.down('xs')]: {
      fontSize: "8.54px",
    },
  },
  psm: {
    fontSize: "13px",
    color: "#565966",
    [theme.breakpoints.down('xs')]: {
      fontSize: "7.93px",
    },
  },
  pxs: {
    fontSize: "12px",
    color: "#565966",
    [theme.breakpoints.down('xs')]: {
      fontSize: "7.32px",
    },
  },
  border: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "1px",
    height: "264px",
    backgroundColor: "#eeeef0",
    [theme.breakpoints.down('xs')]: {
      height: "185px",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#111216",
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
    },
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "bubble" | "xl" | "large" | "normal" | "medium" | "small" | "pxl" | "plg" | "pmd" | "psm" | "pxs" | "border" | "title", string>> };

export default withStyles(styles)(({ classes, data, title, ...props }: IconBubbleProps & BoxProps & ClassProps ) => {  
  const [playState, setPlayState] = useState(PlayState.stop);
  const [mainAvatarPosition, setMainAvatarPosition] = useState<number>(0);

  useEffect(() => {
    setPlayState(PlayState.play);
  }, [data]);

  return (
    <Box className={classes.root} {...props}>
      <Typography align={"left"} className={classes.title} variant={"body1"}>{title}</Typography>
      {data.map((d, i) => (
        <Tween key={i} from={{opacity: 0}} to={{opacity: 1}} delay={(i+1)*0.3} duration={2.5} playState={playState}>
          <div data-id={i} className={clsx(
            classes!.bubble,
            !i ? classes!.xl : i === 1 ? classes!.large : i === 2 ? classes!.normal : i === 3 ? classes!.medium : classes.small
          )}>
            {d.avatar === "1" && <Icon1></Icon1>}
            {d.avatar === "2" && <Icon2></Icon2>}
            {d.avatar === "3" && <Icon3></Icon3>}
            {d.avatar === "4" && <Icon4></Icon4>}
            {d.avatar === "5" && <Icon5></Icon5>}
            <Typography align={"center"} className={!i ? classes.pxl : i === 1 ? classes.plg : i === 2 ? classes.pmd : i === 3 ? classes.psm : classes.pxs} variant={"body1"}>{d.percentage}</Typography>
          </div>
        </Tween>
      ))}
      <div className={classes.border}></div>
    </Box>   
  )
});