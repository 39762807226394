import {
  borders,
  compose,
  display,
  flexbox,
  grid,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  css,
} from '@material-ui/system';
import { styled, StyledComponent } from "@material-ui/styles";
import { RefObject } from 'react';

export const styleFunction = css(
  compose(
    borders,
    display,
    flexbox,
    grid,
    positions,
    palette,
    shadows,
    sizing,
    spacing,
    typography,
  ),
);

const Box = styled('div')(styleFunction);

interface ComponentProps extends React.ComponentProps<typeof Box> {
  component?: React.ElementType;
}

export type BoxProps = Omit<ComponentProps, 'css'>;

export type BoxRef = string | ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined;

export default Box as StyledComponent<BoxProps>;