import { createContext, useReducer, useRef } from "react";
import { Action } from "./store";
import TimerModal from "components/TimerModal";
import { AUTHORIZE, AUTHORIZE_FAILED, AUTHORIZE_SUCCESS, AUTHORIZE_PROMPT } from "./types";

const initialState = {
  loading: false,
  prompt: false,
  isAuthorized: false,
  isEmployer: false,
  error: "",
  tenant: undefined,
  validated: false,
};

type AuthorizationState = Readonly<Partial<Pick<typeof initialState, "error">> & Omit<typeof initialState, "error">>;

export const store = createContext<{
  timeoutIdRef?: React.MutableRefObject<NodeJS.Timeout | undefined>;
  timeoutAlertIdRef?: React.MutableRefObject<NodeJS.Timeout | undefined>;
  state?: AuthorizationState;
  dispatch?: React.Dispatch<Action>;
}>({});

const { Provider } = store;

export const AuthorizationProvider = ({ children, isAuthorized }: { children?: React.ReactNode; isAuthorized?: boolean }) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const timeoutAlertIdRef = useRef<NodeJS.Timeout>();
  const [state, dispatch] = useReducer(
    (state: AuthorizationState, action: Action) => {
      switch (action.type) {
        case AUTHORIZE:
          return { ...state, timeouted: false, prompt: false, loading: true, tenant: undefined };
        case AUTHORIZE_SUCCESS:
          return {
            ...state,
            isAuthorized: true,
            validated: true,
            prompt: false,
            loading: false,
            isEmployer: action.payload?.isEmployer || state.isEmployer,
            tenant: action.payload?.tenant || state.tenant,
          };
        case AUTHORIZE_FAILED:
          return { ...state, isAuthorized: false, validated: false, prompt: false, error: action.payload, loading: false, tenant: undefined };
        case AUTHORIZE_PROMPT:
          return { ...state, timeouted: true, prompt: true, loading: false };
        default:
          throw new Error();
      }
    },
    { ...initialState, isAuthorized: !!isAuthorized }
  );

  return (
    <Provider value={{ state, timeoutIdRef, timeoutAlertIdRef, dispatch }}>
      {children}
      <TimerModal
        variant={state.isEmployer ? "red" : "blue"}
        visible={state.isAuthorized && state.prompt}
        onClose={() => {
          dispatch({ type: AUTHORIZE_SUCCESS });
        }}
        onLogout={() => {
          dispatch({ type: AUTHORIZE_FAILED });
        }}
      />
    </Provider>
  );
};
