import React, { useState, useEffect } from 'react';
import Box, { BoxProps } from 'components/Box';
import Modal from "components/Modal";
import Button from "components/Button";
import { useLogout } from "hooks/logout";
import useInterval from "hooks/useInterval";
import { createStyles, withStyles } from "@material-ui/styles";
import theme from "theme";

const styles = createStyles((t: any) => ({
    main: {
        maxWidth: "660px",
        fontSize: "28px",
        color: "#111216",
        lineHeight: "38px",
        textAlign: "center",
        fontWeight: 500,
        marginTop: "24px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "16px",
            marginTop: "62px",
        }
    },
    seconds: {
        fontSize: "72px",
        lineHeight: "86px",
        color: ({ variant }: TimerModalProps) => variant !== "red" ? "#0680C2" : "#A03A1F",
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            fontSize: "64px",
            marginTop: "18px",
        }
    },
    secondsText: {
        fontSize: "24px",
        lineHeight: "30px",
        color: ({ variant }: TimerModalProps) => variant !== "red" ? "#0680C2" : "#A03A1F",
    },
    box: {
        marginTop: "72px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "64px",
        }
    },
}));

interface TimerModalProps {
  visible?: boolean;
  variant?: "blue" | "red"; 
    onClose: () => void;
    onLogout: () => void;
}

type ClassProps = { classes: Partial<Record<"main" | "seconds" | "secondsText" | "option" | "box", string>> };
const TIMEOUT = 60;

const TimerModal =
    withStyles(styles)(({ classes, visible, variant, onClose, onLogout, ...props }: ClassProps & TimerModalProps & BoxProps) => {
        const [seconds, setSeconds] = useState(TIMEOUT);
        const [logout] = useLogout();
        
        useInterval(() => {
            setSeconds(seconds - 1);
        }, 1000);        

        useEffect(() => {
            setSeconds(TIMEOUT);
        }, [visible]);

        return <Modal variant={variant !== "red" ? "filled" : "black"} visible={visible} onClose={() => {
            onClose();
        }} size="medium">
            <Box height={'100%'} maxHeight={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                <Box display={"flex"} justifyContent={"center"}><span className={classes.main}>You've been inactive for 3 minutes so we are going to log you out in...</span></Box>
                <Box><span className={classes.seconds}>{ seconds }</span></Box>
                <Box><span className={classes.secondsText}>seconds</span></Box>
                <Box className={classes.box}>
                    <Button variant={"simple"} style={{fontWeight: 600}} color={variant !== "red" ? "#0680C2" : "#A03A1F"} onClick={() => {
                        onLogout();
                    }}>Log out</Button>
                </Box>
            </Box>
        </Modal> 
    },
);

export default TimerModal;