import { Question } from "components/questions/TextQuestion";
import firebase from "firebase/app";

export interface AnswerProps {
    id: string,
    title: string; 
    originalTitle: string; 
    options: Question[] | undefined; 
    subOptions: Question[] | undefined; 
    group: string | undefined; 
    subtitle: string | undefined; 
    hint: string | undefined; 
    type: string; key: string; 
    order: number; 
    shortLabel: string; 
    columns: number; 
    answer: Question[];
    avatar: string | undefined;
    edit: boolean;
};

export const getQuestions = async () => {
    var questions: AnswerProps[] = [];
    var result = await firebase.firestore().collection("questions").where('category', '==', 'seeme').get();
    
    questions = result.docs.sort((o1:any, o2: any) => parseInt(o1.data().order) > parseInt(o2.data().order) ? 1 : -1).map((doc, i) => {
        var cols = doc.data().onePerRow ? 1 : 2; 
        return {
            id: doc.id,
            key: doc.data().key,
            order: i + 1,
            group: doc.data().group,
            shortLabel: doc.data().shortLabel,
            subtitle: undefined,
            hint: doc.data().hint,
            type: doc.data().type,
            title: doc.data().title,
            originalTitle: doc.data().title,
            options: doc.data().options,
            subOptions: doc.data().subOptions,
            answer: [],
            avatar: undefined,
            columns: cols,
            edit: false,
        };
    }).sort((q1:any, q2:any) => q1.order > q2.order ? 1 : -1);

    return questions;
};