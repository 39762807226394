import { styled } from "@material-ui/styles";
import React from "react";
import { Theme } from "theme";
import Box, { BoxProps } from "./Box";
import Typography from "./Typography";

interface TooltipProps {
    text: string;
}

export default styled(
  ({ text, top, left, ...props }: TooltipProps & BoxProps) => {
    return (
        <Box {...props} top={top} left={left}>
            <Typography variant={"body2"}>{text}</Typography>
        </Box>   
    );
  }
)(({ theme }: { theme: Theme }) => ({
    position: "absolute",
    background: "#0680C2",
    whiteSpace: "nowrap",
    color: "#fff",
    height: "34px",
    padding: "2px 20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:after": {
        content: "''",
        position:"absolute",
        left:"45%",
        marginLeft:"-5px",
        top:"128%",
        transform:"translateY(-50%)",
        border:"10px solid #000",
        borderColor: "#0680C2 transparent transparent transparent",
        display: "block",
        zIndex: 10002
    },
}));