const FIREBASE_CONFIGS: { [k: string]: any } = {
  dev: {
    apiKey: "AIzaSyBRvr3z_-ORFNfJnJYETOeRTJQtANnECoY",
    authDomain: "glassmoon-dev.firebaseapp.com",
    projectId: "glassmoon-dev",
    storageBucket: "glassmoon-dev.appspot.com",
    messagingSenderId: "801413851963",
    appId: "1:801413851963:web:4c08ca1570e83d1d77a51e",
    measurementId: "G-YNXTNW198E"
  },
  uat: {
    apiKey: "AIzaSyA4u-mZJlA7IUZKV-jWDnu0SJKiBjhbzm4",
    authDomain: "glassmoon-uat.firebaseapp.com",
    projectId: "glassmoon-uat",
    storageBucket: "glassmoon-uat.appspot.com",
    messagingSenderId: "28660889936",
    appId: "1:28660889936:web:79658c05c47aebd8395455"
  },
  prod: {
    apiKey: "AIzaSyCIje-DXQgyCdGmK0_77zjMXVbbTptbYyo",
    authDomain: "glassmoon-production.firebaseapp.com",
    projectId: "glassmoon-production",
    storageBucket: "glassmoon-production.appspot.com",
    messagingSenderId: "769790792622",
    appId: "1:769790792622:web:a9e46466f3d89fbea3ae3f"
  },
  test: {
    apiKey: "AIzaSyC4dpNRzj_E6iRns3flrq8ghiChOw1JrWg",
    authDomain: "glassmoon-test.firebaseapp.com",
    projectId: "glassmoon-test",
    storageBucket: "glassmoon-test.appspot.com",
    messagingSenderId: "52469677228",
    appId: "1:52469677228:web:9542ac6a56cd3bed507309"
  },
};

export const LOGO_PATH = process.env.REACT_APP_LOGO_PATH || 'logo.svg';
export const LOGO_WHITE_PATH = process.env.REACT_APP_LOGO_WHITE_PATH || 'logo-white.svg';
export const LOGO_BLACK_PATH = process.env.REACT_APP_LOGO_BLACK_PATH || 'logo-black.svg';

export const TENANT = process.env.REACT_APP_TENANT;

export const FIREBASE =
  FIREBASE_CONFIGS[process.env.REACT_APP_ENV || "dev"];

export const EMAIL_ADDRESS = "support@glassmoon.co.uk";
export const EMAIL_SUBJECT = "Request%20See%20Me%20code";
export const EMAIL_BODY = "Body%20goes%20here";

export const CONTACT_EMAIL_ADDRESS = "support@glassmoon.co.uk";
export const CONTACT_EMAIL_SUBJECT = "Contact%20us";
export const CONTACT_EMAIL_BODY = "Body%20goes%20here";