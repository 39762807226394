import React from 'react';
import { styled } from '@material-ui/styles';
import Box, { BoxProps } from 'components/Box';
import theme from "theme";

interface CloseButtonProps {
  visible?: boolean;
  variant?: "filled" | "none";
}

export default styled(
  ({ variant, onClick, ...props }: CloseButtonProps & BoxProps) => (
    <Box {...props} onClick={onClick}>
    </Box>
  ),
)({
  position: "relative",
  cursor: "pointer",
  height: "23px",
  width: "23px",
  "&:before": {
    content: "no-close-quote",
    position: "absolute",
    display: "inline-block",
    width: "1px",
    height: "23px",
    left: "9px",
    borderRight: ({ variant = "none" }: CloseButtonProps) => variant === "none" ? "3px solid #fff" : "3px solid #0680C2",
    transform: "rotate(-45deg)",
    transformOrigin: "center center"
  },
  "&:after": {
    content: "no-close-quote",
    position: "absolute",
    display: "inline-block",
    width: "1px",
    height: "23px",
    left: "9px",
    borderRight: ({ variant = "none" }: CloseButtonProps) => variant === "none" ? "3px solid #fff" : "3px solid #0680C2",
    transform: "rotate(45deg)",
    transformOrigin: "center center"
  },
})
