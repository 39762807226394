import firebase from "firebase/app";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { getCompletionStatus, getCookieConsentStatus, setCookieConsentStatus } from "services/autorization";
import { activateGA } from "services/ga";

const defaultFirebaseContext = {
  authStatusReported: false,
  isAnonymous: true,
  isUserSignedIn: false,
  uid: "",
  completed: false,
  isCookieConsentConfirmed: false,
  setConsent: (optionalTracking: boolean) => {},
};

export const FirebaseAuthContext = createContext(defaultFirebaseContext);

const FirebaseAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [authStatusReported, setAuthStatusReported] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCookieConsentConfirmed, setCookieConsentConfirmed] = useState(false);

  const handleCookieConsent = useCallback(() => {
    const cookieConsent = getCookieConsentStatus();
    setCookieConsentConfirmed(cookieConsent === "1" || cookieConsent === "2");
    if (cookieConsent === "2") {
      activateGA();
    }
  }, []);

  useEffect(() => {
    setCompleted(getCompletionStatus());
    handleCookieConsent();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user?.isAnonymous) {
          setCompleted(getCompletionStatus());
          setUser(user!);
        } else {
          setUser(user!);
        }
      }

      setAuthStatusReported(true);
    });
  }, []);

  return (
    <FirebaseAuthContext.Provider
      value={{
        isUserSignedIn: !!user,
        isAnonymous: user?.isAnonymous,
        authStatusReported,
        uid: user?.uid,
        completed,
        isCookieConsentConfirmed,
        setConsent: (optionalTracking: boolean) => {
          setCookieConsentStatus(optionalTracking);
          handleCookieConsent();
        },
      }}
    >
      {authStatusReported && children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
