import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  subtitle: {
    color: "#0680C2",
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "subtitle" | "text", string>> };

export const Hint10 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Professional occupations"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: teacher, nurse, physiotherapist, social worker, musician, police officer (sergeant or above), software designer, accountant, solicitor, medical practitioner, scientist, civil / mechanical engineer."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Managers or administrators"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: finance manager, chief executive, large business owner, office manager, retail manager, bank manager, restaurant manager, warehouse manager."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Clerical and intermediate occupations"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: secretary, personal assistant, call centre agent, clerical worker, nursery nurse."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Technical and craft occupations"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: motor mechanic, plumber, printer, electrician, gardener, train driver."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Manual and service occupations"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: postal worker, machine operative, security guard, caretaker, farm worker, catering assistant, sales assistant, HGV driver, cleaner, porter, packer, labourer, waiter/waitress, bar staff."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Long-term unemployed"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"(claimed Jobseeker’s Allowance or earlier unemployment benefit for more than a year)."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Small business owners who employed less than 25 people"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: corner shop owners, small plumbing companies, retail shop owner, single restaurant or cafe owner, taxi owner, garage owner."}</Typography>
              </div>
              <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Other"}</Typography>
              <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"such as: retired, this question does not apply to me, I don’t know."}</Typography>
            </Box>
        </Box>
    </Box>
  );
});