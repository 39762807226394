import Box from "components/Box";
import { useState, useContext, useEffect, useCallback } from "react";
import CommunityChart from "components/CommunityChart";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import Typography from "components/Typography";
import { AnswersContext } from "providers/answers";
import { store } from "providers/authorization";
import { useLogout } from "hooks/logout";
import { createStyles, withStyles } from "@material-ui/styles";
import Image from "components/Image";
import { getQuestions } from "services/questions";
import EmployerStats from "components/EmployerStats";
import clsx from "clsx";
import { CONTACT_EMAIL_ADDRESS, CONTACT_EMAIL_SUBJECT, CONTACT_EMAIL_BODY } from "../services/config";
import { SCIENCE, DATAUSAGE } from "routes/types";
import { useHistory } from "react-router";
import { LOGO_BLACK_PATH } from "services/config";

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5",
    padding: "48px 48px 0 48px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 0 0 0",
    },
  },
  chartWrap: {
    transform: "scale(0.9) translateY(-40px)",
    height: "880px",
    [theme.breakpoints.down("sm")]: {
      height: "420px",
      transform: "scale(0.4) translateY(-340px)",
    },
  },
  topBar: {
    width: "100vw",
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },
  filters: {
    marginTop: "8px",
    padding: "0 48px 40px",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 48px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      width: "100%",
    },
  },
  filterWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "16px",
      flexDirection: "column",
    },
  },
  title: {
    paddingLeft: "16px",
    fontSize: "32px",
    color: "#111216",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "48px 32px 24px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  separator: {
    display: "inline",
    marginLeft: "8px",
    marginRight: "8px",
    fontSize: "28px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "4px",
      marginRight: "4px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      fontSize: 0,
      marginBottom: "8px",
    },
  },
  footer: {
    height: "85px",
    width: "100vw",
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "234px",
      bottom: "-25px",
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "48px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
      flexDirection: "column",
      width: "calc(100% - 16px)",
      marginTop: "32px",
      marginLeft: "16px",
    },
  },
  link: {
    color: "#A03A1F",
    fontWeight: 600,
    padding: "0 24px 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      height: "28px !important",
    },
  },
  contact: {
    color: "#A03A1F",
    fontWeight: 600,
    fontSize: "18px",
    height: "50px",
    marginRight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginRight: 0,
      padding: "0 18px",
      height: "28px !important",
    },
  },
  logoWrap: {
    display: "flex",
    paddingRight: "48px",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
      justifyContent: "flex-end",
      width: "100vw",
      paddingRight: "16px",
      marginBottom: "16px",
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
  },
  border: {
    display: "flex",
    flex: 1,
    width: "calc(100% - 32px)",
    marginLeft: "16px",
    marginRight: "16px",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #CCCED4",
      marginTop: "16px",
      marginBottom: "16px",
    },
  },
  textbox: {
    width: "100vw",
    bottom: "118px",
    position: "absolute",
    paddingLeft: "96px",
    [theme.breakpoints.down("xs")]: {
      bottom: "258px",
      paddingLeft: "32px",
    },
  },
  text: {
    color: "#B5B7BD",
    fontWeight: 500,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  whitebox: {
    width: "100%",
    borderRadius: 32,
    backgroundColor: "#FFF",
    padding: "10px 30px 0 30px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      padding: "16px 0 0 0",
    },
  },
  logo: {
    width: "100%",
    height: "90px",
    backgroundColor: "#f5f5f5",
    zIndex: 1,
    display: "flex",
    padding: "0 38px",
    justifyContent: "space-between",
    fontSize: "0.75em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
      height: "86px",
    },
  },
  image: {
    backgroundPosition: "top",
    marginTop: "-4px",
  },
  more: {
    position: "absolute",
    top: 156,
    right: 32,
    zIndex: 1,
    fontWeight: 600,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  morebox: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  titletop: {
    color: "#565966",
    fontWeight: 600,
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  compare: {
    color: "#111216",
    fontWeight: 600,
    paddingLeft: "78px",
    width: "100vw",
    [theme.breakpoints.down("xs")]: {
      color: "#565966",
      fontWeight: 700,
      fontSize: "18px",
      paddingLeft: "20px",
    },
  },
  logoImg: {
    width: "auto !important",
    maxWidth: "270px",
    maxHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "171px",
      maxHeight: "44px",
    },
  },
}));

type ClassProps = {
  classes: Partial<
    Record<
      | "chartWrap"
      | "footer"
      | "topBar"
      | "filters"
      | "filterWrap"
      | "separator"
      | "box"
      | "link"
      | "logoWrap"
      | "title"
      | "button"
      | "root"
      | "border"
      | "chart"
      | "textbox"
      | "text"
      | "whitebox"
      | "logo"
      | "image"
      | "more"
      | "morebox"
      | "titletop"
      | "compare"
      | "contact"
      | "logoImg",
      string
    >
  >;
};

const DashboardPage = withStyles(styles)(({ classes }: ClassProps) => {
  const [dd1, setDD1] = useState("2:1");
  const [dd2, setDD2] = useState("3:1");
  const [dd3, setDD3] = useState("4:1");
  const [count, setCount] = useState<number>(0);
  const { data, stats, getResultsCount, getEmployerStats } = useContext(AnswersContext);
  const { state } = useContext(store);
  const [logout, logoutEmployer] = useLogout();
  const [options, setOptions] = useState<any>([]);
  const { total = 0, current = 0 } = state?.tenant || { current: 0, total: 0 };

  const TENANT_NAME = state?.tenant?.["name"] || "";
  const history = useHistory();

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (getEmployerStats && options.length > 0) {
        await getEmployerStats(state?.tenant?.["id"] || "", options);
      }
    })();
  }, [state?.tenant, options]);

  useEffect(() => {
    const _getResultsCount = async () => {
      if (getResultsCount) {
        let sortedFilter = [dd1, dd2, dd3];
        sortedFilter.sort((f1: any, f2: any) => {
          const q1i = parseInt(f1.substr(0, f1.indexOf(":")));
          const q2i = parseInt(f2.substr(0, f2.indexOf(":")));
          if (q1i < q2i) {
            return -1;
          } else return 1;
        });

        const _count = await getResultsCount(sortedFilter.join("-"), state?.tenant?.["id"] || "");

        try {
          setCount(parseInt(_count.toString()));
        } catch (e) {}
      }

      return 0;
    };

    _getResultsCount();
  }, [dd1, dd2, dd3, getResultsCount]);

  useEffect(() => {
    (async () => {
      if (getQuestions) {
        let _options: any[] = [];
        let questions = await getQuestions();
        questions.map((q) => {
          if (q.shortLabel !== "") {
            let subOptions = q.options;
            if (q.subOptions) {
              subOptions = q.subOptions.map((so: any) => ({
                ...so,
                text: `${q.options?.find((o: any) => o.key === so.categories[0])?.text}: ${so.text}`,
              }));

              const preferNotToSayOption = q.options?.find((o: any) => o.text === "Prefer not to say");
              if (preferNotToSayOption) subOptions.push(preferNotToSayOption);
            }
            _options.push({ text: q.shortLabel, key: q.order.toString(), suboptions: subOptions });
          }
          return q;
        });
        setOptions(_options);
      }
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.topBar}>
        <Box className={classes.logo}>
          <Typography color={"#111216"} variant={"body1"}>
            {"Employer Portal"}
          </Typography>
          <img src={`${process.env.PUBLIC_URL}/${LOGO_BLACK_PATH}`} alt='Company logo.' className={classes.logoImg} />
        </Box>
        <Box className={classes.filters}>
          <Typography align={"center"} className={classes.titletop} variant={"h3"}>
            {"Employer insights"}
          </Typography>
          <EmployerStats stats={stats} />
        </Box>
      </Box>
      <Box className={classes.morebox}>
        <a style={{ textDecoration: "none" }} href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=${CONTACT_EMAIL_SUBJECT}&body=${CONTACT_EMAIL_BODY}`}>
          <Typography className={classes.more} color={"#A03A1F"} variant={"caption"}>
            {"Request more data"}
          </Typography>
        </a>
      </Box>
      <Box className={clsx(classes!.root, classes!.whitebox)}>
        <Box p={2} pl={3} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography className={classes.compare} align={"left"}>
            How do people at {TENANT_NAME} compare?
          </Typography>
        </Box>
        <Box mt={4} mb={4} className={classes.filterWrap}>
          <Dropdown
            isNoneDisabled={!dd2 || !dd3}
            isMultilevel={true}
            disabledOptions={[dd2, dd3]}
            onSelected={(option) => {
              setDD1(option.key ? `${option.key}:${option.sub.key}` : "");
            }}
            value={dd1}
            options={options}
          ></Dropdown>
          <Box className={classes.separator} ml={2} mr={2}>
            <Typography fontWeight={800} color={"#565966"}>
              +
            </Typography>
          </Box>
          <Dropdown
            isNoneDisabled={!dd1 || !dd3}
            isMultilevel={true}
            disabledOptions={[dd1, dd3]}
            onSelected={(option) => {
              setDD2(option.key ? `${option.key}:${option.sub.key}` : "");
            }}
            value={dd2}
            options={options}
          ></Dropdown>
          <Box className={classes.separator} ml={2} mr={2}>
            <Typography fontWeight={800} color={"#565966"}>
              +
            </Typography>
          </Box>
          <Dropdown
            isNoneDisabled={!dd1 || !dd2}
            isMultilevel={true}
            disabledOptions={[dd1, dd2]}
            onSelected={(option) => {
              setDD3(option.key ? `${option.key}:${option.sub.key}` : "");
            }}
            value={dd3}
            options={options}
          ></Dropdown>
        </Box>
        <Box mt={2}>
          <Typography className={classes.title}>
            Did you know that <strong>{count}</strong> people in <strong>{TENANT_NAME}</strong> match that?
          </Typography>
        </Box>
        <Box className={classes.chartWrap}>
          <CommunityChart variant={"blue"} count={count} nickname={data?.[0]?.answer?.[0]?.text || ""} avatar={data?.[0]?.avatar || ""} />
        </Box>
        <Box className={classes.textbox}>
          <Typography className={classes.text} variant={"body1"} align={"left"}>{`${current} completed SeeMe profiles out of a possible ${total} in the organisation`}</Typography>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.box}>
          <Button onClick={() => navigateTo(DATAUSAGE)} size={"small"} className={classes.link} variant={"simple"}>
            {"How we use data"}
          </Button>
          <Button onClick={() => navigateTo(SCIENCE)} size={"small"} className={classes.link} variant={"simple"}>
            {"How SeeMe works"}
          </Button>
          <Box py={"2px"}>
            <a style={{ textDecoration: "none" }} href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=${CONTACT_EMAIL_SUBJECT}&body=${CONTACT_EMAIL_BODY}`}>
              <Typography className={classes.contact} color={"#A03A1F"} variant={"caption"}>
                {"Contact"}
              </Typography>
            </a>
          </Box>
          <Button onClick={() => logoutEmployer()} size={"small"} className={classes.link} variant={"simple"}>
            {"Log out"}
          </Button>
        </Box>
        <Box className={classes.border}></Box>
        <Box className={classes.logoWrap}>
          <Image src={`${process.env.PUBLIC_URL}/powered-by.svg`} width={200} ratio={234 / 21} />
        </Box>
      </Box>
    </Box>
  );
});

export default DashboardPage;
