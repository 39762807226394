import Box from "components/Box";
import Input from "components/Input";
import Image from "components/Image";
import RoundedBox from "components/RoundedBox";
import Button from "components/Button";
import Typography from "components/Typography";
import React, { useCallback, useContext, useEffect, useState } from "react";
import theme from "theme";
import { useSubmit } from "hooks/submit";
import { avatars } from "../providers/types";
import { useHistory } from "react-router-dom";
import { PROFILE, CONVERSATION } from "routes/types";
import { generateRandomName } from "../providers/name-generator";
import { AnswersContext } from "../providers/answers";
import { createStyles, withStyles } from "@material-ui/styles";
import { getQuestions } from "services/questions";
import Logo from "components/Logo";
import { LOGO_WHITE_PATH } from "services/config";
import { FirebaseAuthContext } from "providers/firebase";

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "60px 0",
    },
  },
  box: {
    display: "flex",
    paddingTop: "80px",
    width: "90vw",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  whitebox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "8px 8px 0 8px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: 162,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0,
      borderRadius: 16,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      justifyContent: "flex-start",
      height: 154,
    },
  },
  image: {
    width: 96,
    height: 96,
    border: "6px solid transparent",
    borderRadius: 48,
    cursor: "pointer",
    opacity: 1,
    margin: "0 16px 16px 0",
    transition: "all .3s ease-out",
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        opacity: 0.7,
        border: "6px solid #0680C2",
      },
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 32,
      width: 64,
      height: 64,
      border: "4px solid transparent",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 26,
      width: 52,
      height: 52,
      margin: "8px",
      border: "3px solid transparent",
    },
  },
  selectedimage: {
    width: 96,
    height: 96,
    border: "6px solid #0680C2",
    borderRadius: 48,
    cursor: "pointer",
    margin: "0 16px 16px 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 32,
      width: 64,
      height: 64,
      border: "4px solid #0680C2",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 26,
      width: 52,
      height: 52,
      margin: "8px",
      border: "3px solid #0680C2",
    },
  },
  menu: {
    overflowY: "scroll",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: "0 20px 0 20px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: "0 10px 0 10px",
    },
  },
  avatarsWrap: {
    display: "flex",
    flexWrap: "wrap",
    margin: "16px 0 0 16px",
  },
  edit: {
    display: "flex",
    height: "24px !important",
    color: "#0680C2",
    paddingLeft: "0 !important",
    fontSize: "16px",
    fontWeight: 600,
    alignItems: "flex-start",
  },
  nickname: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    flex: 1,
    marginLeft: 32,
    marginRight: 16,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 48px)",
      height: 78,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: "none",
      margin: "8px auto",
    },
  },
  generate: {
    minWidth: 340,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      minWidth: 240,
    },
  },
  text: {
    color: "#0680C2",
    fontWeight: 600,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
    },
  },
  input: {
    width: "680px",
    padding: 0,
    height: 73,
    color: "#0680C2",
    overflow: "visible",
    borderRadius: 0,
    fontSize: "64px",
    borderBottom: "solid 2px #0680C2",
    "&:placeholder-shown": {
      borderBottom: "solid 2px #CCCED4",
    },
    "&::placeholder": {
      fontSize: "64px",
      fontWeight: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: "390px",
      height: 48,
      fontSize: "36px",
      "&::placeholder": {
        fontSize: "36px",
        fontWeight: 600,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 118px)",
      fontSize: "24px",
      flex: 1,
      "&::placeholder": {
        fontSize: "24px",
        fontWeight: 600,
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 88px)",
    },
  },
  done: {
    display: "flex",
    alignItems: "center",
    height: 60,
    paddingTop: "32px",
    marginBottom: 48,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  generateButtonWrap: {
    minWidth: "240px",
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 44px)",
      margin: "0 22px",
      height: "46px",
    },
  },
  nicknametext: {
    width: "100%",
    color: "#0680C2",
    fontSize: "4em",
    position: "absolute",
    top: -4,
    left: 0,
    [theme.breakpoints.down("md")]: {
      top: 1,
      fontSize: "2.25em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "11px",
    },
  },
  nonickname: {
    pointerEvents: "none",
    position: "absolute",
    top: 1,
    left: 0,
    opacity: 0,
  },
}));

type ClassProps = {
  classes: Partial<
    Record<
      "root" | "box" | "whitebox" | "image" | "selectedimage" | "menu" | "edit" | "nickname" | "text" | "input" | "done" | "avatarsWrap" | "generateButtonWrap" | "nicknametext" | "nonickname",
      string
    >
  >;
};

export const AvatarPage = withStyles(styles)(({ classes }: ClassProps) => {
  const { data, setData, getAnswers } = useContext(AnswersContext);
  const [selected, setSelected] = useState(-1);
  const [edit, setEdit] = useState(true);
  const [nickname, setNickname] = useState(data?.[0]?.answer[0]?.text || undefined);
  const history = useHistory();
  const { isAnonymous } = useContext(FirebaseAuthContext);

  const navigateToConversation = useCallback(() => {
    history.push(CONVERSATION);
  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (getAnswers) {
        let answers = await getAnswers();
        if (!data?.length) {
          if (!answers || Object.keys(answers).length === 0) {
            let questions = await getQuestions();
            if (setData) setData(questions);
          } else {
            let questions = await getQuestions();
            let d = questions.map((q) => {
              return { ...q, answer: answers[q.key]?.answer, avatar: answers[q.key]?.avatar };
            });
            if (setData) setData(d);
            setNickname(d?.[0]?.answer[0].text === "Prefer not to say" ? undefined : d?.[0]?.answer[0].text);
            let a: any = avatars.find((avatar) => avatar.text === d?.[0]?.avatar) || {};
            if (a?.key) setSelected(a.key - 1);
          }
        }
      }
    })();
  }, [data, navigateToConversation, getAnswers, setData]);

  const navigateToProfile = useCallback(() => {
    let firstItem: any = data?.find((item) => item.key === "nickname") || {};
    if (firstItem.answer.length === 0) {
      firstItem.answer.push({});
    }
    firstItem.answer[0].text = nickname;
    firstItem.avatar = `${avatars[selected].text}`;

    if (setData) setData([...(data || [])]);
    history.push(CONVERSATION);
  }, [history, data, nickname, selected, setData]);

  const handleSubmit = useSubmit(
    (e: any) => {
      if (nickname) setEdit(false);
      saveNickname(nickname);
    },
    [nickname]
  );

  const generateProfileName = () => {
    let name = generateRandomName();
    setNickname(name);
    saveNickname(name);
    setEdit(false);
  };

  const saveNickname = (name: string | undefined) => {
    let firstItem: any = data?.find((item) => item.key === "nickname") || {};
    if (firstItem.answer.length === 0) {
      firstItem.answer.push({});
    }
    firstItem.answer[0].text = name;

    if (setData) setData([...(data || [])]);
  };

  const cancelEdit = () => {
    let firstItem: any = data?.find((item) => item.key === "nickname") || {};
    let newNickname = firstItem?.answer?.[0]?.text || "";
    setNickname(newNickname);

    if (newNickname) {
      setEdit(!edit);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} textAlign={"center"} mb={3}>
          <Typography variant={"h3"} color={"#fff"}>
            Before we start, let’s choose a nickname and avatar
          </Typography>
        </Box>
        <RoundedBox className={classes.whitebox}>
          <div className={classes.nickname}>
            <Typography noWrap={true} variant={"h3"} className={edit ? classes.nonickname : classes.nicknametext}>
              {nickname}
            </Typography>
            <form onSubmit={handleSubmit}>
              <div>
                <Input className={classes.input} style={{ opacity: edit ? 1 : 0 }} filled onChange={setNickname} placeholder={"Type your nickname"} value={nickname} size={"xl"} />
              </div>
            </form>
            <Box display={"flex"} flexDirection={"row"}>
              {nickname && !edit && (
                <Button
                  variant={"text"}
                  className={classes.edit}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  {"Edit"}
                </Button>
              )}
              {nickname && edit && (
                <Button
                  variant={"text"}
                  className={classes.edit}
                  onClick={() => {
                    setEdit(!edit);
                    saveNickname(nickname);
                  }}
                >
                  {"Save"}
                </Button>
              )}
              {nickname && edit && (
                <Button
                  variant={"text"}
                  className={classes.edit}
                  onClick={() => {
                    cancelEdit();
                  }}
                >
                  {"Cancel"}
                </Button>
              )}
              {!nickname && (
                <Button
                  variant={"text"}
                  className={classes.edit}
                  onClick={() => {
                    setNickname("Prefer not to say");
                    setEdit(false);
                    saveNickname("Prefer not to say");
                  }}
                >
                  {"Prefer not to say"}
                </Button>
              )}
            </Box>
          </div>
          <Button className={classes.generateButtonWrap} color={"#0680C2"} size={"large"} onClick={generateProfileName} variant={"border"}>
            {"Generate profile name"}
          </Button>
        </RoundedBox>
        <RoundedBox variant={"grey"} className={classes.menu}>
          <Box pl={2.5} pt={3} pb={0.5}>
            <Typography color={theme.primaryColor} variant={"subtitle2"} fontWeight={500}>
              {"Choose your avatar"}
            </Typography>
          </Box>
          <div className={classes.avatarsWrap}>
            {avatars?.map((option, i) => (
              <img key={option.key} className={i === selected ? classes.selectedimage : classes.image} src={`${process.env.PUBLIC_URL}/avatars/${option.text}`} onClick={() => setSelected(i)}></img>
            ))}
          </div>
        </RoundedBox>
      </div>
      <div className={classes.done}>
        <Button onClick={navigateToProfile} disabled={selected === -1 || !nickname || edit} style={{ fontWeight: 600 }} variant={"border"}>
          {"Done"}
        </Button>
      </div>
      {isAnonymous && (
        <Logo variant={"bottom"}>
          <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
        </Logo>
      )}
    </div>
  );
});
