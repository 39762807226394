import { createStyles, withStyles } from "@material-ui/styles";
import { forwardRef, useContext } from "react";
import Box, { BoxProps, BoxRef } from "components/Box";
import { useHistory } from "react-router-dom";
import { COMMUNITY, CONVERSATION } from "routes/types";
import { FirebaseAuthContext } from "providers/firebase";
import ConsentModal from "components/ConsentModal";
import Logo from "components/Logo";
import { LOGO_PATH } from "services/config";

const _SIZE = 140;

const styles = createStyles((theme: any) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "66.6%",
    },
  },
  rootLoggedIn: {
    minHeight: "100vh",
    position: "relative",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "66.6%",
      paddingBottom: "80px",
    },
  },
  background: (_: any, size = 100) => ({
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/background@sm.png)`,
      backgroundSize: "100% 200%",
      "&:before": {
        display: "none",
      },
      "&:after": {
        marginTop: `-${size / 2}vh`,
        left: "60%",
        width: `${size}vh`,
        height: `${size}vh`,
      },
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/background@md.png)`,
      "&:before": {
        display: "block",
      },
      "&:after": {
        marginTop: `-${_SIZE / 2}vh`,
        left: "50%",
        width: `${_SIZE}vh`,
        height: `${_SIZE}vh`,
      },
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/background@lg.png)`,
    },
    backgroundSize: "100% auto",
    backgroundPosition: "0% 0%",
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }),
  backgroundLines: (_: any, size = 100) => {
    const { pathname } = _.location;
    const shouldAnimate = pathname === COMMUNITY || pathname == CONVERSATION;
    return {
      [theme.breakpoints.down("sm")]: {
        "&:before": {
          display: "none",
        },
        "&:after": {
          marginTop: `-${size / 2}vh`,
          left: "60%",
          width: `${size}vh`,
          height: `${size}vh`,
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&:before": {
          display: "block",
        },
        "&:after": {
          marginTop: `-${_SIZE / 2}vh`,
          left: "50%",
          width: `${_SIZE}vh`,
          height: `${_SIZE}vh`,
        },
      },
      backgroundSize: "100% auto",
      backgroundPosition: "0% 0%",
      top: 0,
      left: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      "&:before": {
        content: "''",
        position: "absolute",
        right: "100%",
        top: "50vh",
        marginTop: `-${(_SIZE - 20) / 2}vh`,
        marginRight: -150,
        width: `${_SIZE - 20}vh`,
        height: `${_SIZE - 20}vh`,
        borderRadius: "50%",
        border: "1px solid #fff",
        zIndex: 0,
        transform: shouldAnimate ? "translateX(-30%)" : "translateX(0)",
        opacity: shouldAnimate ? 0.2 : 1,
        transition: "all .6s ease-out",
        transitionDelay: ".1s",
      },
      "&:after": {
        content: "''",
        position: "absolute",
        top: "50vh",
        marginRight: 100,
        borderRadius: "50%",
        border: "1px solid #fff",
        zIndex: 0,
        transform: shouldAnimate ? "translateX(60%)" : "translateX(0)",
        opacity: shouldAnimate ? 0 : 1,
        transition: "all .6s ease-out",
        transitionDelay: ".1s",
      },
    };
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
  logo: {
    width: "auto !important",
    maxWidth: "270px",
    maxHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "171px",
      maxHeight: "44px",
    },
  },
}));

type WrapperProps = Omit<BoxProps, "classes"> & { classes: Partial<Record<"root" | "inner" | "background" | "backgroundLines" | "rootLoggedIn" | "logo", string>> };

export default withStyles(styles)(
  forwardRef(({ classes, children }: WrapperProps, ref: BoxRef) => {
    const { isAnonymous, isCookieConsentConfirmed, setConsent } = useContext(FirebaseAuthContext);
    const history = useHistory();
    const {
      location: { pathname },
    } = history;

    return (
      <Box ref={ref} className={isAnonymous ? classes.root : classes.rootLoggedIn}>
        <Box className={classes.background} />
        <Box className={classes.backgroundLines} style={{ background: pathname !== COMMUNITY && pathname !== CONVERSATION ? "" : "" }} />
        <Box className={classes.inner}>
          {isAnonymous && (
            <Logo>
              <img src={`${process.env.PUBLIC_URL}/${LOGO_PATH}`} alt='Company logo.' className={classes.logo} />
            </Logo>
          )}
          {children}
        </Box>
        <ConsentModal
          visible={!isCookieConsentConfirmed}
          onAccept={(optionalTracking: boolean) => {
            setConsent(optionalTracking);
          }}
          onClose={() => {}}
        />
      </Box>
    );
  })
);
