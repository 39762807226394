import { styled } from "@material-ui/styles";
import { BoxProps } from "components/Box";
import theme from "theme";

interface ButtonProps {
  disabled?: boolean;
  color?: string;
  size?: "small" | "medium" | "large";
  variant?: "filled" | "border" | "text" | "simple";
  icon?: string;
  multiline?: boolean;
}

export default styled(({ children, variant, color, size, disabled, multiline, icon, onClick, ...props }: ButtonProps & BoxProps) => (
  <div {...props} onClick={disabled ? undefined : onClick}>
    {icon && <img src={icon} alt={""} />}
    <span>{children}</span>
  </div>
))({
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  opacity: ({ disabled }: ButtonProps) => (disabled ? 0.5 : 1),
  border: ({ variant = "border", color = "#fff" }: ButtonProps) => (variant !== "border" ? "2px solid transparent" : `2px solid ${color}`),
  borderRadius: ({ variant = "border", multiline }: ButtonProps) => (multiline ? 32 : 100000),
  fontSize: ({ size }) => (size === "small" ? "1.125em" : size === "large" ? "1.5em" : "1.25em"),
  fontWeight: ({ size }) => (size === "large" ? 600 : 400),
  whiteSpace: ({ variant = "border", multiline }: ButtonProps) => (multiline ? undefined : "nowrap"),
  color: ({ color, variant }) => (variant === "filled" ? "#0680C2" : color || "#fff"),
  height: ({ size, icon, multiline }) => (multiline ? undefined : icon ? 73 : size === "small" ? 50 : size === "large" ? 73 : 46),
  userSelect: "none",
  padding: ({ variant = "border" }: ButtonProps) => (variant === "simple" ? "0 16px" : variant !== "filled" ? "12px 32px" : "12px 32px"),
  backgroundColor: ({ variant = "border" }: ButtonProps) => (variant === "filled" ? "#fff" : "transparent"),
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      "&:after": {
        display: ({ disabled }: ButtonProps) => (disabled ? "none" : "block"),
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        backgroundColor: ({ variant }) => (variant === "simple" ? "transparent" : "rgba(255, 255, 255, 0.2)"),
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    height: ({ size, icon, multiline }) => (multiline ? undefined : icon ? 60 : size === "small" ? 50 : size === "large" ? 60 : 46),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: ({ size }) => (size === undefined || size === "small" ? "18px" : undefined),
    padding: ({ size, icon, multiline }) => (size !== "large" ? (icon || multiline ? "9px 22px !important" : "6px 16px !important") : undefined),
  },
  [theme.breakpoints.down("xs")]: {
    height: ({ multiline }) => (multiline ? undefined : "46px !important"),
  },
});
