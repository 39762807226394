import React, { cloneElement } from "react";
import Box from "components/Box";
import { HomePage } from "pages/Home";
import LoginPage from "pages/Login";
import RubyPage from "pages/Ruby";
import { FirebaseAuthContext } from "providers/firebase";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useAuthorization } from "hooks/authorization";
import { CompletedPage } from "pages/Completed";
import { SciencePage } from "pages/Science";
import { DataPage } from "pages/DataUsage";
import { EmployerHomePage } from "pages/EmployerHome";
import Wrapper from "components/Wrapper";
import EmployeeRoutes from "routes/EmployeeRoutes";
import EmployerRoutes from "routes/EmployerRoutes";
import { COMPLETED, LOGIN, HOME, SCIENCE, DATAUSAGE, TOKEN, PROTECTED, EMPLOYER_HOME, EMPLOYER_PROTECTED, COOKIE, RUBY } from "routes/types";
import { useHistory } from "react-router-dom";

const { Consumer } = FirebaseAuthContext;

const ProtectedScreen = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return (
    <Consumer>
      {({ isUserSignedIn, completed }) => {
        if ([SCIENCE, DATAUSAGE, EMPLOYER_HOME, RUBY].indexOf(`/${pathname.replace(/\//g, "")}`) > -1) {
          return children;
        }

        if (completed) {
          return <Redirect to={COMPLETED} />;
        }

        return isUserSignedIn ? children : <Redirect to={LOGIN} />;
      }}
    </Consumer>
  );
};

const ProtectedEmployerScreen = () => {
  return (
    <Consumer>
      {({ isUserSignedIn, isCookieConsentConfirmed, completed }) => {
        if (completed) {
          return <Redirect to={COMPLETED} />;
        }
        return isUserSignedIn ? <EmployerRoutes /> : <Redirect to={EMPLOYER_HOME} />;
      }}
    </Consumer>
  );
};

const LoginScreen = ({ children }: { children: React.ReactNode }) => {
  return (
    <Consumer>
      {({ isUserSignedIn, isCookieConsentConfirmed, completed, setConsent }) => {
        if (completed) {
          return <Redirect to={COMPLETED} />;
        }

        return isUserSignedIn ? <Redirect to={HOME} /> : <LoginPage isCookieConsentConfirmed={isCookieConsentConfirmed} />;
      }}
    </Consumer>
  );
};

const RubyScreen = ({ children }: { children: React.ReactNode }) => {
  return (
    <Consumer>
      {(props: any) => {
        return children;
      }}
    </Consumer>
  );
};

const AuthorizedScreen = ({ children }: { children: React.ReactNode }) => {
  const [isAuthorize] = useAuthorization();
  return <>{isAuthorize ? children : <Redirect to={HOME} />}</>;
};

const Routes = () => {
  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <Switch>
        <Wrapper>
          <Route path={SCIENCE} exact>
            <SciencePage />
          </Route>
          <Route path={DATAUSAGE} exact>
            <DataPage />
          </Route>
          <Route path={COOKIE} exact>
            <CompletedPage />
          </Route>
          <Route path={COMPLETED} exact>
            <CompletedPage />
          </Route>
          <Route path={LOGIN} exact>
            <LoginScreen>
              <LoginPage></LoginPage>
            </LoginScreen>
          </Route>
          <Route path={RUBY}>
            <RubyScreen>
              <RubyPage />
            </RubyScreen>
          </Route>
          <Route path={TOKEN} exact>
            <LoginScreen>
              <Box>{"TOKEN"}</Box>
            </LoginScreen>
          </Route>

          <Route path={EMPLOYER_HOME} exact>
            <EmployerHomePage />
          </Route>
          <Route path={EMPLOYER_PROTECTED}>
            <ProtectedEmployerScreen></ProtectedEmployerScreen>
          </Route>

          <ProtectedScreen>
            <Route path={HOME} exact>
              <HomePage />
            </Route>
            <Route path={PROTECTED}>
              <EmployeeRoutes />
            </Route>
          </ProtectedScreen>
        </Wrapper>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
