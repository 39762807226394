import { styled } from "@material-ui/styles";
import React from "react";
import { Theme } from "theme";
import Box, { BoxProps } from "./Box";
import Bubble from "components/Bubble";
import Typography from "components/Typography";

interface TitleProps {
  subtitle?: string;
}

export default styled(
  ({ children, subtitle, ...props }: TitleProps & BoxProps) => {
    return (
        <Box {...props} px={4} py={2}>
            <Typography paragraph variant={"title1"}>{children}</Typography>
            {subtitle && <Bubble>{subtitle}</Bubble>}
        </Box>   
    );
  }
)(({ theme }: { theme: Theme }) => ({
  paddingLeft: 14,
  borderRadius: 32,
  color: "#fff",
}));