import { createStyles, withStyles } from "@material-ui/styles";
import { forwardRef } from "react";
import { BoxProps } from "components/Box";
import clsx from "clsx";

type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "title1" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit";

type Alignment = "center" | "left" | "right" | "justify" | "inherit";

const defaultVariantMapping: Partial<Record<Variant, string>> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  title1: "title1",
  subtitle1: "h6",
  body1: "p",
  body2: "p",
};

const stylesFunction = (theme: any) => ({
  /* Styles applied to the root element. */
  root: {
    margin: 0,
    color: ({ color }: { color?: string }) => color,
  },
  /* Styles applied to the root element if `variant="body2"`. */
  body2: theme.typography.body2,
  /* Styles applied to the root element if `variant="body1"`. */
  body1: theme.typography.body1,
  /* Styles applied to the root element if `variant="caption"`. */
  caption: theme.typography.caption,
  /* Styles applied to the root element if `variant="button"`. */
  button: theme.typography.button,
  /* Styles applied to the root element if `variant="h1"`. */
  h1: theme.typography.h1,
  /* Styles applied to the root element if `variant="h2"`. */
  h2: theme.typography.h2,
  /* Styles applied to the root element if `variant="h3"`. */
  h3: theme.typography.h3,
  /* Styles applied to the root element if `variant="h4"`. */
  h4: theme.typography.h4,
  /* Styles applied to the root element if `variant="h5"`. */
  h5: theme.typography.h5,
  /* Styles applied to the root element if `variant="h6"`. */
  h6: theme.typography.h6,
  /* Styles applied to the root element if `variant="title"`. */
  title1: theme.typography.title1,
  /* Styles applied to the root element if `variant="subtitle1"`. */
  subtitle1: theme.typography.subtitle1,
  /* Styles applied to the root element if `variant="subtitle2"`. */
  subtitle2: theme.typography.subtitle2,
  /* Styles applied to the root element if `variant="overline"`. */
  overline: theme.typography.overline,
  /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
  srOnly: {
    position: "absolute",
    height: 1,
    width: 1,
    overflow: "hidden",
  },
  /* Styles applied to the root element if `align="left"`. */
  alignleft: {
    textAlign: "left",
  },
  /* Styles applied to the root element if `align="center"`. */
  aligncenter: {
    textAlign: "center",
  },
  /* Styles applied to the root element if `align="right"`. */
  alignright: {
    textAlign: "right",
  },
  /* Styles applied to the root element if `align="justify"`. */
  alignjustify: {
    textAlign: "justify",
  },
  fontWeight: {
    fontWeight: ({ fontWeight = 400 }: { fontWeight?: number }) => fontWeight,
  },
  /* Styles applied to the root element if `nowrap={true}`. */
  noWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  /* Styles applied to the root element if `gutterBottom={true}`. */
  gutterBottom: {
    marginBottom: "0.35em",
  },
  /* Styles applied to the root element if `paragraph={true}`. */
  paragraph: {
    marginBottom: 16,
  },
  [theme.breakpoints.down("sm")]: {
    caption: {
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  [theme.breakpoints.up("lg")]: {
    h5: {
      fontSize: "24px",
    },
  },
  [theme.breakpoints.up("xl")]: {
    h5: {
      fontSize: "42px",
    },
  },
});

const styles = createStyles(stylesFunction);

type StyleType = typeof stylesFunction;

type FunctionResult<T> = T extends (theme: any) => infer R ? R : T;

type KeysEnum<T> = { [P in keyof T | Variant]: string };

interface TypographyProps {
  paragraph?: boolean;
  variantMapping?: Partial<Record<Variant, string>>;
  variant?: Variant;
  display?: "initial" | "block" | "inline";
  gutterBottom?: boolean;
  noWrap?: boolean;
  align?: Alignment;
  color?: string;
  fontWeight?: number;
}

const Typography = forwardRef((props: TypographyProps & Pick<BoxProps, "classes" | "component" | "className">, ref) => {
  const {
    display = "initial",
    classes: cls,
    component,
    paragraph = false,
    variantMapping = defaultVariantMapping,
    variant = "body1",
    className,
    gutterBottom = false,
    noWrap = false,
    align = "inherit",
    fontWeight,
    ...other
  } = props;

  let classes: KeysEnum<FunctionResult<StyleType>>;

  if (cls) {
    classes = cls as KeysEnum<FunctionResult<StyleType>>;
  }

  const Component = component || (paragraph ? "p" : variantMapping[variant] || defaultVariantMapping[variant]) || "span";

  return (
    <Component
      className={clsx(
        classes!.root,
        {
          [classes![variant]]: variant !== "inherit",
          [classes!.noWrap]: noWrap,
          [classes!.gutterBottom]: gutterBottom,
          [classes!.paragraph]: paragraph,
          [classes!.fontWeight]: fontWeight,
          [classes![`align${align}` as keyof FunctionResult<StyleType>]]: align !== "inherit",
          [classes![`display${display}` as keyof FunctionResult<StyleType>]]: display !== "initial",
        },
        className
      )}
      ref={ref}
      {...other}
    ></Component>
  );
});

export default withStyles(styles)(Typography);
