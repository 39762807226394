import Box from "components/Box";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import CommunityChart from "components/CommunityChart";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import Typography from "components/Typography";
import { AnswersContext } from "providers/answers";
import { store } from "providers/authorization";
import { FirebaseAuthContext } from "providers/firebase";
import { AVATAR, SCIENCE, DATAUSAGE, COMPLETED } from "routes/types";
import { useLogout } from "hooks/logout";
import { createStyles, withStyles } from "@material-ui/styles";
import { getQuestions } from "services/questions";
import Image from "components/Image";
import { LOGO_WHITE_PATH } from "services/config";

const styles = createStyles((theme: any) => ({
  chartWrap: {
    transform: "scale(0.9) translateY(-40px)",
    height: "880px",
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      height: "540px",
      transform: "scale(0.4) translateY(-340px)",
    },
    [theme.breakpoints.down("xs")]: {
      height: "600px",
      marginBottom: "0px",
      transform: "scale(0.4) translateY(-420px)",
    },
  },
  topBar: {
    width: "100vw",
    background: "linear-gradient(93.19deg, #3C7CBB 0%, #407FBE 23.44%, #2F7BCF 61.53%, #1061B5 82.18%, #177095 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingTop: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  filters: {
    width: "1210px",
    marginTop: "80px",
    [theme.breakpoints.down("md")]: {
      marginTop: "8px",
      width: "100%",
    },
  },
  filterWrap: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "16px",
      flexDirection: "column",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "16px 32px",
    },
  },
  separator: {
    display: "inline",
    [theme.breakpoints.down("md")]: {
      display: "inline",
      fontSize: 0,
      marginBottom: "8px",
    },
  },
  footer: {
    height: "85px",
    width: "100vw",
    background: "linear-gradient(93.19deg, #3C7CBB 0%, #407FBE 23.44%, #2F7BCF 61.53%, #1061B5 82.18%, #177095 100%)",
    position: "absolute",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "164px",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.6)",
  },
  border: {
    width: "calc(100% - 32px)",
    marginLeft: "16px",
    marginRight: "16px",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid white",
      marginTop: "16px",
      marginBottom: "16px",
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "calc(100% - 16px)",
      marginTop: "32px",
      marginLeft: "16px",
    },
  },
  link: {
    color: "#fff",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      height: "28px !important",
    },
  },
  logoWrap: {
    display: "flex",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
      justifyContent: "flex-end",
      width: "100vw",
      paddingRight: "16px",
      marginBottom: "16px",
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "160px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "80px",
    },
  },
  headerlogged: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 0,
  },
  textbox: {
    width: "100vw",
    bottom: "128px",
    position: "absolute",
    paddingLeft: "48px",
    [theme.breakpoints.down("sm")]: {
      bottom: "188px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "32px",
    },
  },
}));

type ClassProps = {
  classes: Partial<
    Record<
      "chartWrap" | "footer" | "topBar" | "filters" | "overlay" | "filterWrap" | "separator" | "box" | "link" | "border" | "logoWrap" | "title" | "button" | "header" | "headerlogged" | "textbox",
      string
    >
  >;
};

const CommunityPage = withStyles(styles)(({ classes }: ClassProps) => {
  const history = useHistory();
  const [letMeIn, setLetMeIn] = useState(false);
  const [dd1, setDD1] = useState("");
  const [dd2, setDD2] = useState("");
  const [dd3, setDD3] = useState("");
  const [count, setCount] = useState<number>(0);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const { data, setData, getAnswers, getResultsCount, getAnswersCount } = useContext(AnswersContext);
  const { state } = useContext(store);
  const { isAnonymous } = useContext(FirebaseAuthContext);
  const [logout] = useLogout();
  const [options, setOptions] = useState<any>([]);

  const { total = 0, current = 0 } = state?.tenant || { current: 0, total: 0 };

  const TENANT_NAME = state?.tenant?.["name"] || "";

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data, navigateTo]);

  useEffect(() => {
    const _getResultsCount = async () => {
      const params = [dd1, dd2, dd3];
      if (params.filter((p) => !!p).length < 2) {
        return;
      }
      try {
        if (getResultsCount && data?.length) {
          let sortedFilter = [dd1, dd2, dd3];
          sortedFilter.sort((f1: any, f2: any) => {
            const q1i = parseInt(f1.substr(0, f1.indexOf(":")));
            const q2i = parseInt(f2.substr(0, f2.indexOf(":")));
            if (q1i < q2i) {
              return -1;
            } else return 1;
          });

          const _count = await getResultsCount(sortedFilter.join("-"), state?.tenant?.["id"] || "");
          if (_count) {
            let parsedCount = parseInt(_count.toString());
            setCount(parsedCount > 1 ? parsedCount - 1 : 0);
          } else {
            setCount(0);
          }
        }
      } catch (e) {
        console.log(e);
      }

      return 0;
    };

    _getResultsCount();
    if (getAnswersCount) {
      const count = getAnswersCount();
      setIsFirstTime(count ? false : true);
    }
  }, [dd1, dd2, dd3, data, getResultsCount]);

  useEffect(() => {
    (async () => {
      if (data?.length) {
        let _options: any[] = [];
        Object.keys(data).forEach((d: any) => {
          if (d !== "0" && data[d].id !== "11" && data[d].id !== "12" && data[d].id !== "13" && data[d].id !== "14" && data[d]?.answer?.[0] && data[d]?.answer?.[0].text != "Prefer not to say") {
            _options.push({
              key: `${data[d].id}:${data[d].answer.map((a) => a.key).join(",")}`,
              text: data[d].answer.map((a) => a.text).join(", "),
              category: data[d].shortLabel,
            });
          }
        });

        if (_options && _options.length > 2) {
          setOptions(_options);

          setDD1(_options[0].key);
          setDD2(_options[1].key);
          setDD3(_options[2].key);
        }
      } else if (getAnswers) {
        let answers = await getAnswers();

        if (!answers || Object.keys(answers).length === 0) {
          navigateTo(AVATAR);
        } else {
          let questions = await getQuestions();
          let d = questions.map((q) => {
            return { ...q, answer: answers[q.key]?.answer, avatar: answers[q.key]?.avatar };
          });
          if (setData) setData(d);
        }
      }
    })();
  }, [data, navigateTo, getAnswers, setData]);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
        <Box className={classes.topBar}>
          <Box className={classes.filters}>
            <Box pl={2} className={letMeIn || !isAnonymous ? classes.headerlogged : classes.header}>
              <Typography fontWeight={600} color={"#fff"}>
                Use the filters to see if others in {TENANT_NAME} share the same data points as you:
              </Typography>
              {isAnonymous && (
                <Button
                  variant={"simple"}
                  onClick={() => {
                    logout();
                    navigateTo(COMPLETED);
                  }}
                >
                  Log out
                </Button>
              )}
            </Box>
            {(letMeIn || !isAnonymous) && (
              <Box mt={4} mb={4} display={"flex"} className={classes.filterWrap} alignItems={"center"} justifyContent={"center"}>
                <Dropdown
                  isNoneDisabled={!dd2 || !dd3}
                  disabledOptions={[dd2, dd3]}
                  onSelected={(option) => {
                    setDD1(option.key ? `${option.key}` : "");
                  }}
                  value={dd1}
                  options={options}
                ></Dropdown>
                <Box className={classes.separator} ml={2} mr={2}>
                  <Typography fontWeight={800} color={"rgba(255, 255, 255, 0.5)"}>
                    +
                  </Typography>
                </Box>
                <Dropdown
                  isNoneDisabled={!dd1 || !dd3}
                  disabledOptions={[dd1, dd3]}
                  onSelected={(option) => {
                    setDD2(option.key ? `${option.key}` : "");
                  }}
                  value={dd2}
                  options={options}
                ></Dropdown>
                <Box className={classes.separator} ml={2} mr={2}>
                  <Typography fontWeight={800} color={"rgba(255, 255, 255, 0.5)"}>
                    +
                  </Typography>
                </Box>
                <Dropdown
                  isNoneDisabled={!dd1 || !dd2}
                  disabledOptions={[dd1, dd2]}
                  onSelected={(option) => {
                    setDD3(option.key ? `${option.key}` : "");
                  }}
                  value={dd3}
                  options={options}
                ></Dropdown>
              </Box>
            )}
          </Box>
        </Box>
        <Box mt={2} className={classes.title}>
          {(letMeIn || !isAnonymous) && (
            <Typography color={"#fff"}>
              Did you know that <strong>{count}</strong> people in <strong>{TENANT_NAME}</strong> match that?
            </Typography>
          )}
        </Box>
        <Box className={classes.chartWrap}>
          <CommunityChart count={count} params={`${dd1}-${dd2}-${dd3}`} nickname={data?.[0]?.answer?.[0]?.text || ""} avatar={data?.[0]?.avatar || ""} />
        </Box>
        {isAnonymous && (
          <Box className={classes.textbox}>
            <Typography color={"#FFF"} align={"left"}>{`${current + (isFirstTime ? 1 : 0)} completed SeeMe profiles out of a possible ${total} in the organisation`}</Typography>
          </Box>
        )}
        {isAnonymous && (
          <Box className={classes.footer}>
            <Box className={classes.box} ml={6}>
              <Button onClick={() => navigateTo(DATAUSAGE)} size={"small"} className={classes.link} style={{ marginRight: "24px" }} variant={"simple"}>
                {"How we use data"}
              </Button>
              <Button onClick={() => navigateTo(SCIENCE)} size={"small"} className={classes.link} variant={"simple"}>
                {"How SeeMe works"}
              </Button>
            </Box>
            <Box className={classes.border}></Box>
            <Box className={classes.logoWrap}>
              <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
            </Box>
          </Box>
        )}
      </Box>
      {!letMeIn && isAnonymous && (
        <Box className={classes.overlay}>
          <Box className={classes.topBar} pt={12}>
            <Box mt={4}>
              <Typography fontWeight={600} color={"#fff"}>
                Ready to see the wider {TENANT_NAME} community?
              </Typography>
            </Box>
            <Box mt={5} flexDirection={"row"} display={"flex"}>
              <Box mr={2} pb={7}>
                <Button
                  className={classes.button}
                  size={"large"}
                  variant={"filled"}
                  onClick={() => {
                    setLetMeIn(true);
                  }}
                >
                  Yes, let me in
                </Button>
              </Box>
              {isAnonymous && (
                <Button
                  className={classes.button}
                  size={"large"}
                  onClick={() => {
                    logout();
                    navigateTo(COMPLETED);
                  }}
                >
                  Log out
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
});

export default CommunityPage;
