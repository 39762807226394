import React, { useState, useEffect } from "react";
import Box, { BoxProps } from "components/Box";
import Modal from "components/Modal";
import Button from "components/Button";
import { createStyles, withStyles } from "@material-ui/styles";
import theme from "theme";

const styles = createStyles((t: any) => ({
  main: {
    maxWidth: "660px",
    fontSize: "18px",
    color: "#565966",
    lineHeight: "28px",
    textAlign: "left",
    fontWeight: 500,
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  seconds: {
    fontSize: "72px",
    lineHeight: "86px",
    color: ({ variant }: ConsentModalProps) => (variant !== "red" ? "#0680C2" : "#A03A1F"),
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "64px",
      marginTop: "18px",
    },
  },
  secondsText: {
    fontSize: "24px",
    lineHeight: "30px",
    color: ({ variant }: ConsentModalProps) => (variant !== "red" ? "#0680C2" : "#A03A1F"),
  },
  box: {
    marginTop: "48px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "64px",
      marginBottom: "24px",
    },
  },
  checkbox: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridGap: "0.5em",
    fontSize: "1.3rem",
    color: "#B5B7BD",
    gridTemplateArea: "checkbox",

    "& input": {
      opacity: 0,
      width: "1em",
      height: "1em",
    },

    "& input[disabled]:checked + span": {
      border: "1px solid #B5B7BD",
      backgroundColor: "#B5B7BD",
    },

    "& input:checked + span": {
      border: "1px solid #0680C2",
      backgroundColor: "#0680C2",
    },

    "> *": {
      gridArea: "checkbox",
    },
  },
  checkboxControl: {
    display: "inline-grid",
    width: "23px",
    height: "23px",
    borderRadius: "4px",
    border: "1px solid #B5B7BD",
    transition: "all .3s ease-out",
  },
  accordionLink: {
    color: "#0680C2",
  },
  desc: {
    maxWidth: "660px",
    padding: 12,
    marginTop: 8,
    background: "#eee",
  },
  descTitle: {
    fontSize: 12,
    fontWeight: 800,
  },
  descText: {
    fontSize: 12,
  },
}));

interface ConsentModalProps {
  visible?: boolean;
  variant?: "blue" | "red";
  onClose: () => void;
  onAccept: (optionalTracking: boolean) => void;
}

type ClassProps = { classes: Partial<Record<"main" | "seconds" | "secondsText" | "option" | "box" | "checkbox" | "checkboxControl" | "accordionLink" | "desc" | "descTitle" | "descText", string>> };

const ConsentModal = withStyles(styles)(({ classes, visible, variant, onClose, onAccept, ...props }: ClassProps & ConsentModalProps & BoxProps) => {
  const [optionalTracking, setOptionalTracking] = useState(false);
  const [isAccordionOpened, setIsAccordionOpened] = useState(false);

  return (
    <Modal variant={variant !== "red" ? "filled" : "black"} visible={visible} size='medium'>
      <Box height={"100%"} maxHeight={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
        <Box display={"flex"} justifyContent={"center"}>
          <span className={classes.main}>
            This website uses cookies to ensure you get the best experience.
            <br />
            <a
              href='#'
              className={classes.accordionLink}
              onClick={() => {
                setIsAccordionOpened(!isAccordionOpened);
              }}
            >
              Read {isAccordionOpened ? "less" : "more"}
            </a>
            .
          </span>
        </Box>
        {isAccordionOpened && (
          <Box className={classes.desc}>
            <Box mb={1} className={classes.descTitle}>
              Strictly necessary cookies
            </Box>
            <Box className={classes.descText}>
              These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request
              for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site
              will not then work. These cookies do not store any personally identifiable information.
            </Box>
            <Box mt={1.5} mb={1} className={classes.descTitle}>
              Performance cookies
            </Box>
            <Box className={classes.descText}>
              These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and
              see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have
              visited our site, and will not be able to monitor its performance.
            </Box>
          </Box>
        )}
        <Box display={"flex"} flex={1} width={"100%"} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <label className={classes.checkbox}>
            <span>
              <input type='checkbox' name='strictly-required' disabled checked />
              <span className={classes.checkboxControl}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden='true' focusable='false'>
                  <path fill='none' stroke='#ffffff' stroke-width='2' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                </svg>
              </span>
            </span>
            <span className={classes.main}>Strictly necessary cookies</span>
          </label>
        </Box>
        <Box display={"flex"} flex={1} width={"100%"} justifyContent={"flex-start"} alignItems={"center"}>
          <label className={classes.checkbox}>
            <span>
              <input
                type='checkbox'
                name='performance-cookies'
                checked={optionalTracking}
                onChange={(val) => {
                  setOptionalTracking(!optionalTracking);
                }}
              />
              <span className={classes.checkboxControl}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden='true' focusable='false'>
                  <path fill='none' stroke='#ffffff' stroke-width='2' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                </svg>
              </span>
            </span>
            <span className={classes.main}>Performance cookies (optional)</span>
          </label>
        </Box>
        <Box className={classes.box}>
          <Button
            size={"small"}
            variant={"border"}
            style={{ fontWeight: 600 }}
            color={variant !== "red" ? "#0680C2" : "#A03A1F"}
            onClick={() => {
              onAccept(optionalTracking);
            }}
          >
            Accept &amp; Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ConsentModal;
