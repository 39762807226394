import React, { useState, useRef, useEffect } from "react";
import Box, { BoxProps } from "components/Box";
import Typography from "./Typography";
import { useClickOutside } from "hooks/clickOutside";
import { createStyles, withStyles } from "@material-ui/styles";
import { Tween, PlayState } from "react-gsap";

const styles = createStyles((theme: any) => ({
  closed: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    border: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "2px solid #E8E8E9" : "2px solid #fff"),
    borderRadius: 100000,
    whiteSpace: "nowrap",
    color: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F" : "#fff"),
    width: 370,
    height: 73,
    userSelect: "none",
    padding: "9px 26px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      height: 46,
      width: 280,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      border: "10px solid #000",
      borderColor: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F transparent transparent transparent" : "#fff transparent transparent transparent"),
      display: "block",
      right: 18,
      top: "45%",
      transition: "borderColor .2s ease-out",
      [theme.breakpoints.down("md")]: {
        border: "8px solid #000",
      },
    },
  },
  opened: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    border: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "2px solid #E8E8E9" : "2px solid #fff"),
    borderRadius: 36,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F" : "#0680C2"),
    width: 370,
    height: 73,
    userSelect: "none",
    padding: "9px 26px",
    backgroundColor: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#E8E8E9" : "#fff"),
    [theme.breakpoints.down("md")]: {
      height: 46,
      borderRadius: 23,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: 280,
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#fff !important",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      border: "10px solid #000",
      borderColor: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "transparent transparent #A03A1F transparent" : "transparent transparent #0680C2 transparent"),
      display: "block",
      right: 18,
      top: "30%",
      transition: "borderColor .2s ease-out",
      [theme.breakpoints.down("md")]: {
        border: "8px solid #000",
      },
    },
  },
  optonsWrap: {
    width: 732,
    display: "flex",
    flexDirection: "row",
    transform: "translateX(0)",
    height: 430,
    overflow: "hidden",
    transition: "transform 0.5s ease-out",
    [theme.breakpoints.down("md")]: {
      width: 568,
    },
  },
  options: {
    width: 370,
    height: 460,
    overflowX: "hidden",
    overflowY: "scroll",
    backgroundColor: "#F5F5F5",
    position: "absolute",
    display: "flex",
    top: 63,
    left: "-2px",
    zIndex: 10001,
    padding: "24px 24px",
    borderBottomLeftRadius: 36,
    borderBottomRightRadius: 36,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      top: 42,
      width: 280,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 24px",
      backgroundColor: "#E8E8E9 !important",
    },
  },
  option: {
    transition: "opacity .2s ease-out",
    padding: "8px 0",
    width: 330,
    wordWrap: "normal",
    [theme.breakpoints.down("md")]: {
      width: 245,
    },
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        opacity: 0.7,
      },
    },
  },
  suboption: {
    display: "flex",
    justifyContent: "space-between",
    transition: "opacity .2s ease-out",
    padding: "8px 0",
    width: 330,
    wordWrap: "normal",
    [theme.breakpoints.down("md")]: {
      width: 245,
    },
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        opacity: 0.7,
      },
    },
  },
  left: {
    overflowY: "auto",
    height: "100%",
    marginRight: "40px",
  },
  right: {
    overflowY: "auto",
    height: "100%",
  },
  back: {
    color: "#A03A1F",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    paddingBottom: "8px",
    paddingTop: "8px",
    width: 330,
    "& div": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("md")]: {
      width: 242,
    },
  },
  forth: {
    paddingRight: "8px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "8px",
    },
  },
  text: {
    fontSize: "20px",
    color: "#565966",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  selectedtext: {
    fontSize: "20px",
    color: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F" : "#0680C2"),
    fontWeight: 700,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  none: {
    fontSize: "20px",
    color: "#565966",
    fontStyle: "italic",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  backtext: {
    fontSize: "20px",
    color: "#A03A1F",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: 600,
    color: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F" : "#FFF"),
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  titleopened: {
    fontWeight: 600,
    color: ({ options }: DropdownProps) => (options?.[0]?.suboptions ? "#A03A1F" : "#0680C2"),
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
}));

interface DropdownProps {
  disabled?: boolean;
  options?: Array<any>;
  isNoneDisabled?: boolean;
  value?: string;
  isMultilevel?: boolean;
  disabledOptions?: Array<any>;
  onSelected: (option: any) => void;
}

type ClassProps = {
  classes: Partial<
    Record<
      "closed" | "options" | "opened" | "option" | "suboption" | "left" | "right" | "back" | "forth" | "text" | "selectedtext" | "optonsWrap" | "title" | "titleopened" | "backtext" | "none",
      string
    >
  >;
};

const Dropdown = withStyles(styles)(({ classes, disabled, disabledOptions, isMultilevel, options, value, isNoneDisabled, onSelected, ...props }: ClassProps & DropdownProps & BoxProps) => {
  const [opened, setOpened] = useState(false);
  const [text, setText] = useState("");
  const [level, setLevel] = useState<number | undefined>();
  const [category, setCategory] = useState(-1);
  const ref = useRef<any>();

  useClickOutside(ref, () => {
    setOpened(false);
    setLevel(undefined);
  });

  useEffect(() => {
    if (isMultilevel) {
      const values = value?.split(":");

      if (values?.length === 2) {
        const parent = options?.find((o: any) => o.key.toString() === values[0]);

        if (parent) {
          if (parent.suboptions) {
            setCategory(parseInt(values[0]));
            const child = parent.suboptions.find((so: any) => so.key.toString() === values[1]);

            if (child) {
              setText(child.text);
            }
          }
        }
      } else {
        setText("Blank");
      }
    } else {
      const option = options?.find((o: any) => o.key.toString() === value);

      setText(option?.text || "Blank");
    }
  }, [value, options]);

  const selectOption = (e: any, index: number, option: any) => {
    if (index === -1) {
      onSelected({ text: "Blank" });
    }

    if (disabledOptions && disabledOptions?.find((o) => o?.substr(0, o.indexOf(":")) === option.key)) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (option?.suboptions) {
      e.preventDefault();
      e.stopPropagation();
      setCategory(option.key);
      setLevel(1);
    } else {
      onSelected(option);
    }
  };

  const back = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLevel(0);
  };

  const selectSuboption = (e: any, option: any) => {
    setLevel(undefined);

    if (options) {
      onSelected({
        ...options.find((o: any) => o.key === category),
        sub: option,
      });
    }
  };

  let playState = PlayState.stop;
  if (level !== undefined) {
    if (level === 1) {
      playState = PlayState.play;
    } else {
      playState = PlayState.reverse;
    }
  }

  return (
    <Box
      ref={ref}
      onClick={() => {
        setOpened(!opened);
      }}
      className={opened ? classes.opened : classes.closed}
    >
      <Box width={"208px"} display={"flex"} justifyContent={"flex-start"}>
        <Typography noWrap={true} variant={"body1"} className={opened ? classes.titleopened : classes.title}>
          {text}
        </Typography>
      </Box>
      {opened && (
        <Box pt={1} className={classes.options}>
          <div className={classes.optonsWrap} style={{ transform: playState === PlayState.play ? "translateX(-50%)" : "translateX(0)" }}>
            <div className={classes.left}>
              <div
                className={classes.option}
                style={{ opacity: isNoneDisabled ? 0.5 : 1 }}
                onClick={(e) => {
                  if (!isNoneDisabled) selectOption(e, -1, { text: "None" });
                  else {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                title={"Clear selection"}
              >
                <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                  <Typography className={classes.none} paragraph={false} variant={"body2"}>
                    {"Leave blank"}
                  </Typography>
                </div>
              </div>
              {options?.map((option: any, i: number) => {
                let isDisabled = false;
                if (isMultilevel) {
                  if (disabledOptions && disabledOptions?.find((o) => o?.substr(0, o.indexOf(":")) === option.key)) {
                    isDisabled = true;
                  }
                } else if (disabledOptions && disabledOptions?.find((o) => o === option.key)) {
                  isDisabled = true;
                }

                return (
                  <div
                    className={classes.option}
                    style={{ opacity: isDisabled ? 0.5 : 1 }}
                    onClick={(e) => {
                      if (!isDisabled) selectOption(e, i, option);
                      else {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    key={i}
                    title={option?.text}
                  >
                    <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                      <Typography
                        className={(isMultilevel && category.toString() === option.key) || (!isMultilevel && value === option.key) ? classes.selectedtext : classes.text}
                        paragraph={false}
                        variant={"body2"}
                      >
                        {option?.category ? `${option?.category} - ${option?.text}` : option?.text}
                      </Typography>
                      {option?.suboptions && <img className={classes.forth} src={value === option.key ? `${process.env.PUBLIC_URL}/forth-selected.svg` : `${process.env.PUBLIC_URL}/forth.svg`}></img>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.right}>
              <div className={classes.back} onClick={back}>
                <img style={{ paddingRight: "6px" }} src={`${process.env.PUBLIC_URL}/back2.svg`}></img>
                <Typography className={classes.backtext} variant={"body2"}>
                  {options?.find((o: any) => o.key === category.toString())?.text}
                </Typography>
              </div>
              {category !== -1 &&
                options
                  ?.find((o: any) => o.key === category.toString())
                  .suboptions?.map((option: any, i: number) => (
                    <div className={classes.option} onClick={(e) => selectSuboption(e, option)} key={i} title={option?.text}>
                      <Typography className={option.text === text ? classes.selectedtext : classes.text} paragraph={false} variant={"body2"}>
                        {option?.text}
                      </Typography>
                    </div>
                  ))}
            </div>
          </div>
        </Box>
      )}
    </Box>
  );
});

export default Dropdown;
