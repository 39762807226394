import React from "react";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import Loader from "components/Loader";
import Typography from "components/Typography";
import { EMAIL_ADDRESS, EMAIL_SUBJECT, EMAIL_BODY, LOGO_WHITE_PATH } from "../services/config";
import { useAuthorization } from "hooks/authorization";
import { Redirect } from "react-router-dom";
import { INTRO } from "routes/types";
import { createStyles, withStyles } from "@material-ui/styles";
import Logo from "components/Logo";
import Image from "components/Image";
import { useSubmit } from "hooks/submit";

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    padding: "48px",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "60px 8px",
      justifyContent: "center",
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    maxWidth: "430px",
    minWidth: "370px",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      minWidth: "90vw",
      padding: "4px",
    },
    "&::placeholder": {
      fontSize: "24px",
    },
  },
  button: {
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
  title: {
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
    },
  },
  ppwrap: {
    position: "absolute",
    left: 50,
    bottom: 14,
    [theme.breakpoints.down("md")]: {
      left: 16
    }
  },
  link: {
    color: "#fff",
    fontSize: 18,
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "box" | "input" | "button" | "title" | "subtitle" | "ppwrap" | "link", string>> };

export const HomePage = withStyles(styles)(({ classes }: ClassProps) => {
  const [isAutorized, code, error, loading, updateCode, authorize] = useAuthorization();

  const submit = useSubmit(() => {
    if (authorize) authorize();
  }, []);

  return (
    <>
      {isAutorized && <Redirect to={INTRO} />}
      <Box className={classes.root} alignItems={"center"} p={4} height={"100%"}>
        <Box className={classes.title}>
          <Typography variant={"h1"} color={"#fff"}>
            {"SeeMe"}
          </Typography>
          <Typography paragraph variant={"caption"} className={classes.subtitle}>
            {"Create a self-defined picture of you at work"}
          </Typography>
        </Box>
        <Box>
          <Box className={classes.box} display={"flex"}>
            <form onSubmit={submit}>
              <Input className={classes.input} textAlign={"center"} errorMessage={error} placeholder={"Enter your access code"} filled value={code} onChange={updateCode} />
            </form>
            <Button size={"large"} className={classes.button} disabled={loading} onClick={() => authorize()}>
              <Box display={"flex"}>
                {"Log in"}
                {loading && <Loader ml={2} width={26} />}
              </Box>
            </Button>
          </Box>
          {error && (
            <Box position={"absolute"} mt={8} px={3} py={2}>
              <a style={{ textDecoration: "none" }} href={`mailto:${EMAIL_ADDRESS}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`}>
                <Typography color={"#fff"} variant={"caption"}>
                  {"Request access"}
                </Typography>
              </a>
            </Box>
          )}
        </Box>
        <Logo variant={"bottom"}>
          <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
        </Logo>

        <Box className={classes.ppwrap}>
          <a target='_blank' href='/seeme-policy.html' title='SeeMe Privacy Policy' className={classes.link}>
            {"Privacy Policy"}
          </a>
        </Box>
      </Box>
    </>
  );
});
