import { createStyles, withStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import Box, { BoxProps } from "components/Box";
import { Tween, PlayState } from "react-gsap";
import Typography from "./Typography";
import Dotdotdot from "react-dotdotdot";

import clsx from "clsx";

interface BubbleProps {
  title: string;
  data: any[];
}

const styles = createStyles((theme: any) => ({
  root: {
    width: "255px",
    height: "320px",
    position: "relative",
    paddingBottom: "32px",
    [theme.breakpoints.down("xs")]: {
      flexBasis: "50%",
      height: "200px",
    },
  },
  bubble: {
    position: "absolute",
    borderRadius: "50%",
    zIndex: 10001,
    alignItems: "start",
    justifyContent: "center",
    textAlign: "center",
    lineHeight: 1,
    padding: "0 4px",
  },
  large: {
    left: 20,
    top: 15,
    width: "138.2px",
    height: "138.2px",
    paddingTop: "42px",
    backgroundColor: "#A03A1F",
    [theme.breakpoints.down("xs")]: {
      left: 10,
      top: 24,
      width: "84.31px",
      height: "84.31px",
      paddingTop: "26px",
    },
  },
  normal: {
    left: 128.36,
    top: 126.59,
    width: "85.64px",
    height: "85.64px",
    backgroundColor: "#565966",
    paddingTop: "16px",
    [theme.breakpoints.down("xs")]: {
      left: 90.75,
      top: 77.44,
      width: "52.25px",
      height: "52.25px",
      paddingTop: "10px",
    },
  },
  medium: {
    left: 62.18,
    top: 183.04,
    width: "70px",
    height: "70px",
    backgroundColor: "#B5B7BD",
    paddingTop: "12px",
    [theme.breakpoints.down("xs")]: {
      left: 50.38,
      top: 111.88,
      width: "42.75px",
      height: "42.75px",
      paddingTop: "8px",
    },
  },
  small: {
    left: 138.09,
    top: 220.99,
    width: "52.93px",
    height: "52.93px",
    backgroundColor: "#B5B7BD",
    paddingTop: "6.5px",
    [theme.breakpoints.down("xs")]: {
      left: 96.69,
      top: 135.03,
      width: "32.29px",
      height: "32.29px",
      paddingTop: "2px",
    },
  },
  contentlg: {
    fontSize: "22px",
    height: 44,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: 32,
      fontSize: "13.42px",
    },
  },
  contentmd: {
    fontSize: "14px",
    margin: "0 6px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "9.76px",
    },
  },
  contentsm: {
    fontSize: "14px",
    height: "32px",
    margin: "0 2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "9.76px",
    },
  },
  contentxs: {
    fontSize: "12px",
    margin: "0 2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "7.32px",
    },
  },
  plg: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8.54px",
    },
  },
  pmd: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8.54px",
    },
  },
  psm: {
    fontSize: "13px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "7.93px",
    },
  },
  pxs: {
    fontSize: "12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "7.32px",
    },
  },
  box: {
    transform: "translateY(32px)",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(0)",
    },
  },
  boxsmall: {
    transform: "translateY(0)",
  },
  border: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "1px",
    height: "264px",
    backgroundColor: "#eeeef0",
    [theme.breakpoints.down("xs")]: {
      height: "185px",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#111216",
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },
  titleWrap: {
    margin: "4px 0",
  },
}));

const helperTextLength2Clamp = (index: number, text: string) => {
  if (!text) {
    return 1;
  }
  if (index === 0) {
    if (text.length > 11 && (text.indexOf(" ") === -1 || text.indexOf(" ") > 10)) {
      return 1;
    } else {
      return 2;
    }
  }

  if (index === 1) {
    if (text.length > 9 && (text.indexOf(" ") === -1 || text.indexOf(" ") > 9)) {
      return 1;
    } else {
      return 2;
    }
  }

  if (index === 2) {
    if (text.length > 7 && (text.indexOf(" ") === -1 || text.indexOf(" ") > 7)) {
      return 1;
    } else {
      return 2;
    }
  }

  if (index === 3) {
    if (text.length > 6 && (text.indexOf(" ") === -1 || text.indexOf(" ") > 6)) {
      return 1;
    } else {
      return 2;
    }
  }

  return 2;
};

type ClassProps = {
  classes: Partial<
    Record<
      | "root"
      | "bubble"
      | "large"
      | "normal"
      | "medium"
      | "small"
      | "contentlg"
      | "contentmd"
      | "contentsm"
      | "contentxs"
      | "plg"
      | "pmd"
      | "psm"
      | "pxs"
      | "box"
      | "boxsmall"
      | "border"
      | "title"
      | "titleWrap",
      string
    >
  >;
};

export default withStyles(styles)(({ classes, data, title, ...props }: BubbleProps & BoxProps & ClassProps) => {
  const [playState, setPlayState] = useState(PlayState.stop);

  useEffect(() => {
    setPlayState(PlayState.play);
  }, [data]);

  const BUBBLE_CLASSES = [classes!.large, classes!.normal, classes!.medium, classes!.small];
  const BUBBLE_CONTENT_CLASSES = [classes!.contentlg, classes!.contentmd, classes!.contentsm, classes!.contentxs];

  return (
    <Box className={classes.root} {...props}>
      <div title={title}>
        <Typography align={"left"} className={classes.title} variant={"body1"}>
          <Dotdotdot clamp={2}>{title}</Dotdotdot>
        </Typography>
      </div>
      <div className={data.length < 4 ? classes.box : classes.boxsmall}>
        {data.slice(0, 4).map((d, i) => {
          let bubbleClassname = BUBBLE_CLASSES[i];
          let bubbleContentClassname = BUBBLE_CONTENT_CLASSES[i];

          return (
            <Tween key={i} from={{ opacity: 0 }} to={{ opacity: 1 }} delay={(i + 1) * 0.3} duration={2.5} playState={playState}>
              <div data-id={i} className={clsx(classes!.bubble, bubbleClassname)}>
                <Typography className={!i ? classes.plg : i === 1 ? classes.pmd : i === 2 ? classes.psm : classes.pxs} variant={"body1"} color={"#FFF"}>
                  {d.percentage}
                </Typography>
                <div className={classes.titleWrap} title={d.value}>
                  <Typography className={bubbleContentClassname} variant={"h4"} noWrap={helperTextLength2Clamp(i, d.value) === 1} color={"#FFF"}>
                    {d.value}
                  </Typography>
                </div>
              </div>
            </Tween>
          );
        })}
      </div>
      <div className={classes.border}></div>
    </Box>
  );
});
