import React, { useCallback, useContext, useState } from "react";
import Button from "components/Button";
import Response from "components/Response";
import { Question, QuestionProps } from "./TextQuestion";
import {Tween, PlayState} from "react-gsap"
import { createStyles, withStyles } from "@material-ui/styles";
import {AnswersContext} from "providers/answers";

export interface MultipleAnswerQuestionProps extends QuestionProps {
  options?: Question[];
  columns: number;
  children: React.ReactNode;
  onAnimationEnd?: () => void;
  order: number;
  hasHint?: boolean;
}

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  edit: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "-80px",
    paddingLeft: "80px",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 25.3%, rgba(0, 0, 0, 0) 75.3%, rgba(0, 0, 0, 0.1) 100%)",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      paddingBottom: "48px"
    }
  },
  options: {
    display: "flex",
    width: "calc(50vw + 20px)",
    flexWrap: "wrap",
    paddingTop: "16px",
    [theme.breakpoints.down('md')]: {
      width: "calc(43vw + 20px)",
    },
    [theme.breakpoints.down('xs')]: {
      width: "85vw",
      paddingTop: "8px",
    }
  },
  option: {
    flexBasis: ({ columns }: MultipleAnswerQuestionProps) => `${100/columns}%`,
    paddingBottom: "16px",
    [theme.breakpoints.down('sm')]: {
      width: "85vw",
      paddingBottom: "8px"
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: "100% !important",
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      height: "unset !important",
      whiteSpace: "unset",
      borderRadius: 32,
    }
  },
  optionsbox: {
 
  },
  bubble: {
   paddingBottom: "32px",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: "16px",
    } 
  },
  wrap: {
    paddingLeft: "48px",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: "16px",
    }
  }
}));

type ClassProps = { classes: Partial<Record<"options" | "option" | "root" | "edit" | "button" | "optionsbox" | "bubble" | "wrap", string>> };

export const MultipleAnswerQuestion = withStyles(styles)(({ classes, children, columns = 1, options, hasHint, order, onChange, onAnimationEnd }: MultipleAnswerQuestionProps & ClassProps) => {
  const [answers, setAnswers] = useState(new Set<Question>());
  const [answered, setAnswered] = useState<Question[] | undefined>();
  const [respond, setRespond] = useState(false);
  const { data, setData } = useContext(AnswersContext);
  const [skipUpdate, setSkipUpdate] = useState(false);
  const [flexEnd, setFlexEnd] = useState(false);
  const [open, setOpen] = useState<boolean | undefined>();

  const toggleSelected = useCallback((option: Question) => {
    setAnswers((_s) => {
      const s = new Set(_s);
      if (option.text === "Prefer not to say") s.clear();
      if (s.has(option)) {
        s.delete(option);
      } else {
        s.forEach((_o) => {
          if (_o.text === "Prefer not to say") s.delete(_o);
        });
        s.add(option);
      }
      return new Set(s);
    });
  }, []);

  const submitAnswer = () => {
    onChange && onChange(Array.from<Question>(answers));
    setAnswered(Array.from<Question>(answers));
  }

  const animationEnd = () => {
    setRespond(true);
    var _skip = false;
    setSkipUpdate((s) => {
      if (s) _skip = s;
      return s;
    });
    if (onAnimationEnd && !_skip) onAnimationEnd();
  }

  const editAnswer = () => {
    setFlexEnd(false);
  }

  const submitChangedAnswer = (skipUpdate?: boolean) => {
    if (!skipUpdate) {
      onChange && onChange(Array.from<Question>(answers));
      setAnswered(Array.from<Question>(answers)); 
    }
    
    if (data?.[order]?.edit) {
      setSkipUpdate(true);
      let firstItem: any = data?.find(item => item.order === order+1) || {};
      firstItem.edit = false;
      firstItem.answer = Array.from<Question>(answers);
      if (setData) setData([...(data || [])]);
      setOpen(false);
    }
  }

  const basePadding = 8;

  return (
    <div className={classes.wrap}>
      <div className={classes.bubble}>{children}</div>
      <div className={answered && data?.[order]?.edit ? classes.edit : classes.root} style={{justifyContent: flexEnd ? "flex-end" : "space-between"}}>
        <Tween onComplete={() => setFlexEnd(!flexEnd)} to={{opacity: 0, height: 0}} delay={0.5} duration={0.5} playState={answered ? data?.[order]?.edit ? PlayState.reverse : PlayState.play : PlayState.stop}>
          <div className={classes.optionsbox} style={{ height: data?.[order]?.edit ? "unset !important" : undefined}}>
            <Tween onComplete={animationEnd} to={{opacity: 0}} duration={0.5} playState={answered ? data?.[order]?.edit ? PlayState.reverse : PlayState.play : PlayState.stop}>
              <div className={classes.options}>
                {(!answered || data?.[order]?.edit) && <>
                  {options?.map((option, i) => (
                    <div key={option.key} className={classes.option} style={{ paddingRight: `${2 * basePadding}px` }}>
                      <Button className={classes.button} multiline={columns === 1} size={"small"} color={answers.has(option) ? "#fff" : undefined} variant={answers.has(option) ? "filled" : "border"} onClick={() => toggleSelected(option)}>{option.text}</Button>
                    </div>
                  ))}
                </>}
              </div>
              <div style={{ display: "flex", paddingTop: `${basePadding}px`, paddingBottom: `${2*basePadding}px` }}>
                {(answers.size > 0 && !data?.[order]?.edit) && <Button disabled={answered ? true : false} style={{marginRight: "16px", width: "84px"}} variant={"filled"} onClick={submitAnswer}>{"Ok"}</Button>}
              </div>
              </Tween>
          </div>
        </Tween>
        {(answered && respond) && 
          <Response open={open} hasHint={hasHint} onOpen={() => {
              if (open) {
                submitChangedAnswer(true);
              }
              setOpen(!open);
            }
          } order={order} onSubmit={() => submitChangedAnswer()} onChange={editAnswer}>
            {answered.map((answer: Question, i) => (
              i < answered.length-1 ? i > 2 ? "" : i === 2 ? answer.text : answer.text+', ' : answered.length > 3 ? "..." : answer.text
            ))}
          </Response>
        }
      </div>
    </div>
  );
});