export const DEMO_TYPE = "DEMO_TYPE";

export const AUTHORIZE = "AUTHORIZE";
export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS";
export const AUTHORIZE_FAILED = "AUTHORIZE_FAILED";
export const AUTHORIZE_PROMPT = "AUTHORIZE_PROMPT";

export const avatars = [
    {key: 1, text: "avatar-blank.png"},
    {key: 2, text: "avatar-astronaut.png"},
    {key: 3, text: "avatar-batman.png"},
    {key: 4, text: "avatar-birthday.png"},
    {key: 5, text: "avatar-boy.png"},
    {key: 6, text: "avatar-cat.png"},
    {key: 7, text: "avatar-claus.png"},
    {key: 8, text: "avatar-clown.png"},
    {key: 9, text: "avatar-cool.png"},
    {key: 10, text: "avatar-detective.png"},
    {key: 11, text: "avatar-doctor.png"},
    {key: 12, text: "avatar-dog.png"},
    {key: 13, text: "avatar-duck.png"},
    {key: 14, text: "avatar-einstein.png"},
    {key: 15, text: "avatar-girl.png"},
    {key: 16, text: "avatar-glasses.png"},
    {key: 17, text: "avatar-granny.png"},
    {key: 18, text: "avatar-leonardo.png"},
    {key: 19, text: "avatar-luther-king.png"},
    {key: 20, text: "avatar-malala.png"},
    {key: 21, text: "avatar-man.png"},
    {key: 22, text: "avatar-mandela.png"},
    {key: 23, text: "avatar-mario.png"},
    {key: 24, text: "avatar-mummy.png"},
    {key: 25, text: "avatar-nun.png"},
    {key: 26, text: "avatar-obama.png"},
    {key: 27, text: "avatar-oprah.png"},
    {key: 28, text: "avatar-pacman.png"},
    {key: 29, text: "avatar-paper-bag.png"},
    {key: 30, text: "avatar-sheriff.png"},
    {key: 31, text: "avatar-vampire.png"},
    {key: 32, text: "avatar-viking.png"},
    {key: 33, text: "avatar-winter.png"},
    {key: 34, text: "avatar-wise-guy.png"},
    {key: 35, text: "avatar-woman.png"},
  ];