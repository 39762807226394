import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DATAUSAGE } from "routes/types";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  image: {
    position: "absolute",
    top: 128,
    right: 0,
    width: "400px",
    height: "480px",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 118,
      width: "340px",
      height: "408px",
    },
    [theme.breakpoints.down("xs")]: {
      top: 218,
      width: "220px",
      height: "264px",
    },
  },
  content: {
    width: "68vw",
    maxWidth: 848,
    paddingBottom: "104px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      position: "relative",
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    paddingTop: "40px",
    paddingLeft: 32,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100vw",
    },
  },
  border: {
    width: "90vw",
    marginRight: "5vw",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #E8E8E9",
      marginBottom: "10px",
    },
  },
  button: {
    height: 32,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  header: {
    display: "flex",
    paddingLeft: "80px",
    justifyContent: "center",
    alignItems: "center",
    width: "60vw",
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
      alignItems: "flex-start",
      marginTop: "42px",
      paddingLeft: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      height: "380px",
      alignItems: "flex-start",
      marginTop: "42px",
      paddingLeft: "24px",
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    paddingBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  whitebox: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    paddingBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "200px",
    },
  },
  back: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  quotes: {
    position: "absolute",
    left: 0,
    width: "18vw",
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      marginTop: 20,
    },
    [theme.breakpoints.down("xs")]: {
      top: -56,
      left: -20,
      width: 100,
    },
  },
  backtop: {
    position: "absolute",
    top: 23,
    left: 64,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      top: 13,
      left: 0,
    },
  },
  backbottom: {
    position: "absolute",
    top: -16,
    left: 32,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
  ul: {
    listStyle: "none",
  },
  superscript: {
    verticalAlign: "super",
    fontSize: 10,
  },
}));

type ClassProps = {
  classes: Partial<Record<"image" | "content" | "box" | "footer" | "border" | "whitebox" | "button" | "header" | "back" | "quotes" | "backtop" | "backbottom" | "ul" | "superscript", string>>;
};

export const SciencePage = withStyles(styles)(({ classes }: ClassProps) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      <Box className={classes.header}>
        <Typography variant={"h2"} color={"#fff"}>
          {"Why SeeMe @ Work?"}
        </Typography>
      </Box>
      <Box pt={14} className={classes.whitebox}>
        {history.length > 2 && (
          <Button onClick={back} className={classes.backtop} variant={"text"}>
            <Box display={"flex"} alignItems={"center"}>
              <img alt={""} src={"back.svg"} />
              <Box pl={"6px"} className={classes.back} component={"h4"}>
                {"Back"}
              </Box>
            </Box>
          </Button>
        )}
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Setting the Context for Intersectional Inclusion"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "The purpose of this digital Diversity, Inclusion and Belonging web app is to help people see their personal and professional identity: a self-defined picture of you and your intersecting identities."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "The premise is that, if people can see their multiple identities, they can understand and balance their (often competing) work and life priorities, stay economically active, bring their whole self to work and make a meaningful contribution."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "If you have a better picture of who you are at work, and are willing to share this, your organisation can use the anonymised data to drive more nuanced, progressive and inclusive strategies, ensuring:"
            }
          </Typography>
          <ul>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {"People with different backgrounds, identities and ways of thinking can contribute to creativity, innovation and sustainable performance."}
              </Typography>
            </li>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {"People are treated fairly and equitably, affirming the value and interconnectedness of each individual."}
              </Typography>
            </li>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {"Work/life integration is optimised, so people can prioritise what matters to them whilst doing purpose-led work which is meaningful."}
              </Typography>
            </li>
          </ul>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"SeeMe the Digital Tool"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"SeeMe comprises 26 questions across 13 areas and takes approximately 8-10 minutes to complete. Once you have answered the questions, you can view your SeeMe profile. "}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"Once you submit this, your answers are anonymised and your organisation will only see aggregated data, not your individual profile, unless you choose to share it with them yourself."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"See our data privacy policy for how we use your data."}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"The Science of SeeMe"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "The science of connection and belonging tells us that people who have a sense of belonging, of feeling part of an organisation, community, an entity perform better, are more creative and are more likely to be well – physically and mentally. A lack of connection and belonging can have a significant impact on our sense of self, identity and our health and wellbeing."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "The reason for this is that humans are hardwired for connection and belonging. Neuroscientists have shown how important it is to our brains, for us to be seen and recognised for who we are. Without a healthy sense of our own status in a group, our amygdala – a part of our brain’s limbic system, which is the seat of our emotions – triggers hormones which over time, inhibit our thinking and functioning. It also activates areas of our brains which are the same as those which trigger physical pain (Rock and Page, 2009)"
            }
            <span className={classes.superscript}>1</span>
            {". Not being ‘seen’ can be as painful as physical pain, because the brain perceives the two as the same (Rock, 2009)"}
            <span className={classes.superscript}>2</span>.
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"In 2006, author and professor Richard Boyatzis"}
            <span className={classes.superscript}>3</span>
            {
              " and a group of colleagues identified three kinds of stress: having too much to do, dealing with ambiguity, and not being seen by others. The last kind was shown to be the most stressful thing we can experience – in other words, we are at our most stressed when there is no sense of connection or belonging, and we do not feel acknowledged or known by others. This is the reason we developed SeeMe for the workplace. To make the workplace more human, foster diverse culture and a sense of inclusion and belonging."
            }
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Intersection of Your Data and the Science"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"Research"}
            <span className={classes.superscript}>4</span>
            {
              " shows that people value their data over the free services offered by the tech companies who collect it. This is where Glassmoon and the SeeMe service differ from most other digital providers. You have self-sovereignty over your demographic data, meaning it is yours and you should derive personal benefit from it. Our purpose in having access to your data is to make people’s lives better in important ways."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"The purpose of collecting your data in this way is three-fold:"}
          </Typography>
          <ul className={classes.ul}>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {
                  "1. To collate your personal data, SeeMe takes you on a journey designed to highlight the multifaceted and nuanced nature of our modern day lives. The picture it builds, and the story it tells, is designed to help you navigate your working life and keep you working well."
                }
              </Typography>
            </li>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {
                  "2. Your organisation will only ever see your individual data in an aggregated way. It will not see your individual data unless you choose to share it with colleagues, your line manager or others internally. Your organisation has committed to responsibly handling the aggregated data, in line with our data privacy policy, to assist it in cultivating an inclusive culture which supports 21st century work practices. Your board and senior executive team has committed to engaging with SeeMe as part of their commitment to your organisational efforts in Diversity, Inclusion and Belonging."
                }
              </Typography>
            </li>
            <li style={{ padding: "4px" }}>
              <Typography paragraph align={"justify"} variant={"body1"}>
                {
                  "3. The broader SeeMe community data will be utilised to illuminate major social and economic disparities. In areas such as gender and race discrimination, pay inequality and in-work poverty, social mobility, and social justice."
                }
              </Typography>
            </li>
          </ul>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Science and the Stories of SeeMe"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"The app is based on the science of connection and belonging however the science can only go so far. In "} <i>Burnout: Solve Your Stress Cycle</i>
            <span className={classes.superscript}>5</span>
            {", sisters Emily & Amelia Nagoski write about connection, rest and self-compassion and use an example from a movie to illustrate the point. The authors tell us "}{" "}
            <i>“research has found that people understand science better when it is told through storytelling”.</i>
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "Our individual lived experiences and intersectional identities are, by their very nature, unique. Humans are hardwired for stories as it makes it more relatable, meaningful and, therefore, actionable. "
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "Each data point you provide may have a story, personal narrative and meaning attached. Your demographic data may be tied to your values, beliefs and / or your faith. As you will see when you answer the questions, there are multiple options and you may wish to go back a question, to change your answer and you may even want to start again once you can view your SeeMe profile – your individual data dashboard of intersecting data."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"Each SeeMe question has a range of answers, and some have multiple options to enable you to provide a nuanced response to best suit your self-defined profile. "}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <img className={classes.quotes} alt={""} src={"hints.svg"} />
          <Box pb={6} pt={2}>
            <Typography paragraph color={"#0680C2"} align={"justify"} variant={"h3"}>
              {"Click the hints bar for more information on the question and response options. If you are uncomfortable answering any question, select the "}
              <strong>Prefer Not to Say</strong> option.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Turning Data into Knowledge, Insights and Wisdom"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "What makes SeeMe different from how people-related data is being gathered and analysed in organisations is that you get to choose what gets recorded to represent you. SeeMe is not just another workplace survey. It has been designed to give you self-sovereignty over how you want to be included in your workplace diversity data."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "The insights and stories which emerge from the data will enable progressive organisations to create fulfilling working lives and organisations that do not try to punish or exclude people with outdated practices, biases and systems. Instead, raise awareness, educate and create inclusive cultures for everyone based on data, science and the stories of our working lives. "
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "To cultivate a thriving workforce, organisations who appreciate the value of Diversity, Inclusion and Belonging and understand the impact of exclusion and discrimination will utilise SeeMe and the aggregated and nuanced data it provides to see people, give their lived experiences a voice and create workplaces fit for the 21st century. "
            }
          </Typography>
        </Box>
        <Box className={classes.content}>
          <img className={classes.quotes} alt={""} src={"quotes.svg"} />
          <Box pb={6} pt={2}>
            <Typography paragraph color={"#0680C2"} align={"justify"} variant={"h3"}>
              {"“We are changed by what we see. Just as we are changed when we are seen”"}
            </Typography>
            <Box color={"#0680C2"} fontSize={"16px"} fontWeight={600} component={"span"}>
              {"ITV Stand with Diversity"}
            </Box>
          </Box>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"justify"} variant={"h3"}>
            {"References"}
          </Typography>
          <Typography paragraph variant={"body2"}>
            {"1. Rock, D. & Page, L. (2009), Coaching with the Brain in Mind; Wiley & Sons"}
          </Typography>
          <Typography paragraph variant={"body2"}>
            {"2. Rock, D. (2009), Your Brain at Work, Harper Business"}
          </Typography>
          <Typography paragraph variant={"body2"}>
            {"3. Boyatzis, R.E., Smith, M.L., & Blaize, N. (2006), Developing Sustainable Leaders Through Coaching and Compassion, Academy of Learning and Education, 5(1), p.8-24"}
          </Typography>
          <a
            className={classes.button}
            href='https://www.publicissapient.com/insights/data-collection-and-consent-survey-IPSOS-research'
            title='Data Collection & Consent Survey – IPSOS Research | Publicis Sapient'
          >
            <Typography paragraph variant={"body2"}>
              {"4. Data Collection & Consent Survey – IPSOS Research | Publicis Sapient"}
            </Typography>
          </a>
          <Typography paragraph variant={"body2"}>
            {"5. Nagoski, E. and Nagoski, A. (2019). Burnout : the secret to solving the stress cycle. London: Vermilion"}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.footer}>
        {history.length > 2 && (
          <Button onClick={back} className={classes.backbottom} variant={"text"}>
            <Box display={"flex"} alignItems={"center"}>
              <img alt={""} src={"back.svg"} />
              <Box pl={"6px"} className={classes.back} component={"h4"}>
                {"Back"}
              </Box>
            </Box>
          </Button>
        )}
        <Box mt={2} className={classes.box}>
          <Button onClick={() => navigateTo(DATAUSAGE)} className={classes.button} size={"small"} variant={"simple"}>
            {"How we use data"}
          </Button>
          <Button onClick={() => window.scrollTo(0, 0)} className={classes.button} size={"small"} variant={"simple"}>
            {"How SeeMe works"}
          </Button>
        </Box>
        <Box className={classes.border}></Box>
        <Box pb={"4px"} pr={3}>
          <img width={200} alt={""} src={"powered-by.svg"} />
        </Box>
      </Box>
    </Box>
  );
});
