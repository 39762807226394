import { useCustomLogin } from "hooks/login";
import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "hooks/authorization";
import { COMMUNITY, HOME, AVATAR } from "routes/types";

const RubyPage = () => {
  const [, uid, login] = useCustomLogin();
  const history = useHistory();
  const [, , , , updateCode, , , , authorizeLoggedUser] = useAuthorization();
  const {
    location: { hash = "", search },
  } = history;

  useEffect(() => {
    let params = new URLSearchParams(search);
    let token = params.get("token");

    login(token || "");
  }, [login, search]);

  useEffect(() => {
    if (uid) {
      let params = new URLSearchParams(search);
      let code = params.get("code");
      let redirect = params.get("redirect");
      authorizeLoggedUser(code || "");

      if (redirect === "community") {
        history.push(COMMUNITY);
      } else if (redirect === "retake") {
        history.push(AVATAR);
      } else {
        history.push(HOME);
      }
    }
  }, [uid, search]);

  return <Fragment />;
};

export default RubyPage;
/*
http://localhost:3000/glassmoon/ruby?code=ERWV3TW1&token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTYxNzgyNzY3MCwiZXhwIjoxNjE3ODMxMjcwLCJpc3MiOiJnbS1leHBvcnRzQGdsYXNzbW9vbi1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJnbS1leHBvcnRzQGdsYXNzbW9vbi1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJLS0xiQkRZd1pmT3F3UjF5OU1BVzZlMkxEZWIyIn0.ZP5bgEHjT5bwpcDuZA2I0G-YjGj2YeJNrVI2RriEWLWS3a5dw0FRzsxDXhc_XYcQ4RzHJwkHcyqv-fwNEp6NmwroHzRMHBg3W43QqWyn6aP13jn9n20hro7S-HZ91V-zooA_Lw0SKvBf-xmb_On7fmSfI_tDo-cAaSTHLs1-aXMHn2q3SkzNCFxdgCbz4QXnhNi5Y0X8UTG2SU4iR7H9wNc47jfIbkXvuNwui6pXiQnLbRYWosMu0uv4p0t4_hE42BMxwt4m6pTk45fXxHvWEgVagI1R8j8f94BxMRlAgr2BfGvBzZ2jXCV_H3j0W9Sbnwp7eSP-vB9pgeA1AwXZjg
http://localhost:3000/glassmoon/ruby/#eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTYxNzgyNzY3MCwiZXhwIjoxNjE3ODMxMjcwLCJpc3MiOiJnbS1leHBvcnRzQGdsYXNzbW9vbi1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJnbS1leHBvcnRzQGdsYXNzbW9vbi1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJLS0xiQkRZd1pmT3F3UjF5OU1BVzZlMkxEZWIyIn0.ZP5bgEHjT5bwpcDuZA2I0G-YjGj2YeJNrVI2RriEWLWS3a5dw0FRzsxDXhc_XYcQ4RzHJwkHcyqv-fwNEp6NmwroHzRMHBg3W43QqWyn6aP13jn9n20hro7S-HZ91V-zooA_Lw0SKvBf-xmb_On7fmSfI_tDo-cAaSTHLs1-aXMHn2q3SkzNCFxdgCbz4QXnhNi5Y0X8UTG2SU4iR7H9wNc47jfIbkXvuNwui6pXiQnLbRYWosMu0uv4p0t4_hE42BMxwt4m6pTk45fXxHvWEgVagI1R8j8f94BxMRlAgr2BfGvBzZ2jXCV_H3j0W9Sbnwp7eSP-vB9pgeA1AwXZjg
*/
