import { styled } from "@material-ui/styles";
import React, { forwardRef } from "react";
import { Theme } from "theme";
import Box, { BoxProps, BoxRef } from "./Box";
import Typography from "components/Typography";

type BaseProps = {
  filled?: boolean;
  textAlign?: "center" | "left" | "right";
  size?: "small" | "medium" | "large" | "xl";
  disabled?: boolean;
}

type InputBaseProps = BaseProps & {
  error?: boolean;
  theme?: any;
}

const InputBase = styled(({ filled, error, size, textAlign, ...props }) => <input {...props} />)(({ theme, error, filled, size, textAlign = "left" }: { theme: Theme } & InputBaseProps) => (
  {
    width: "100%",
    borderRadius: 10000,
    fontWeight: size === "small" ? 500 : 600,
    height: size === "small" ? 56 : 73,
    fontSize: size === "small" ? "1.25em" : size === "xl" ? "4em" : size === "large" ? "2.375em" : "1.5em",
    padding: "0 40px",
    fontFamily: "'Montserrat', sans-serif",
    textAlign: textAlign,
    color: error ? theme.errorColor : filled ? "#0680C2" : "#fff",
    border: filled ? "none": "2px solid #fff",
    background: filled ? "#fff" : "transparent",
    "&:focus": {
      outline: "none",
    },
    "&:focus::placeholder": {
      color: "transparent",
    },
    "&:disabled": {
      color: "#0680C2",
      opacity: 1,
    },
    "&::placeholder": {
      fontSize: size === "xl" ? "0.75em" : "1.125em",
      fontWeight: size === "small" ? 500 : 600,
      color: filled ? theme.textDisabledColor : "#fff",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: size !== "xl" ? "18px" : undefined,
    },
    [theme.breakpoints.down('xs')]: {
      height: "46px !important",
    },
  }
));

const ErrorBase = styled(Box)(({ theme, error, filled, size, textAlign = "left" }: { theme: Theme } & InputBaseProps) => (
  {
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "130px",
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: "80px",
    }
  }
));

interface InputProps extends BaseProps {
  errorMessage?: string,
  errorColor?: string,
  placeholder?: string,
  textAlign?: any,
  value?: string,
  disabled?: boolean,
  onChange?: (e: string) => void,
}

export default forwardRef(({ errorMessage, errorColor, onChange, width, disabled, ...other }: InputProps & Omit<BoxProps, "onChange">, ref: BoxRef) => (
  <Box ref={ref} width={width} >
    <InputBase {...other} disabled={disabled} error={Boolean(errorMessage)} onChange={(e: any) => onChange && onChange(e.target.value)} />
    {errorMessage && <ErrorBase px={3} py={3}><Typography variant={"body1"} color={errorColor ? errorColor : "#F5F5F5"}>{errorMessage}</Typography></ErrorBase>}
  </Box>
));