import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { BoxProps } from "components/Box";
import Button from "components/Button";
import theme from "theme";
import CloseButton from "components/Close";

interface EditProps {
  edit?: boolean;
  open?: boolean;
  hasHint?: boolean;
  onEdit?: (event: any) => void;
  onOpen?: () => void;
  onHintOpen: () => void;
}

const styles = createStyles((theme: any) => ({
  root: {
    position: "absolute",
    display: "flex",
    overflow: "hidden",
    width: 466,
    height: ({ edit }: EditProps) => (edit ? "100%" : "50px"),
    fontSize: "1.25em",
    whiteSpace: "nowrap",
    color: "#fff",
    transition: "height .3s ease-out",
    pointerEvents: "all",
    transform: "translateX(456px)",
    [theme.breakpoints.down("sm")]: {
      width: 368,
      transform: "translateX(368px)",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
      transform: "translateX(368px)",
      width: 368,
      height: ({ edit }: EditProps) => (edit ? "120px" : "50px"),
    },
  },
  submit: {
    pointerEvents: ({ edit }: EditProps) => (edit ? "auto" : "none"),
    opacity: ({ edit }: EditProps) => (edit ? 1 : 0),
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "24px",
    paddingBottom: "12px",
    height: "60px",
    display: "flex",
    bottom: "16px",
    transition: ({ edit }: EditProps) => (edit ? '"opacity .3s ease-out"' : "opacity .1s ease-in"),
    transitionDelay: ({ edit }: EditProps) => (edit ? ".5s" : 0),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      height: undefined,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  box: {
    background: "linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 55.47%)",
    display: "flex",
    borderRadius: 32,
    alignItems: "flex-start",
    paddingLeft: "16px",
    [theme.breakpoints.down("xs")]: {
      background: ({ edit }: EditProps) => (edit ? "transparent" : "linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 55.47%)"),
      paddingLeft: ({ edit }: EditProps) => (edit ? 0 : "16px"),
    },
  },
  text: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  img: {
    cursor: "pointer",
  },
  textChange: {
    paddingLeft: "24px",
    paddingRight: "16px",
  },
  editWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  rowWrap: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    width: "100%",
    position: "relative",
    paddingRight: "56px",
  },
  editButton: {
    opacity: ({ edit }: EditProps) => (edit ? 0 : 1),
    transition: "opacity .3s ease-out",
    alignItems: "center",
  },
  closeWrap: {
    position: "absolute",
    right: "16px",
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "submit" | "box" | "text" | "img" | "textChange" | "closeWrap" | "editWrap" | "rowWrap" | "editButton", string>> };

export const Edit = withStyles(styles)(({ classes, edit, open, hasHint, onOpen, onEdit, onSubmit, onHintOpen }: EditProps & ClassProps & BoxProps) => {
  const submit = (event: any) => {
    if (onSubmit) onSubmit(event);
  };

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <div className={classes.editWrap}>
          <div className={classes.rowWrap}>
            <img className={classes.img} onClick={(e) => onOpen && onOpen()} src={`${process.env.PUBLIC_URL}/edit-icon.svg`} alt={""} />
            <span className={classes.textChange}>{"Change your answer?"}</span>
            <div className={classes.editButton}>
              <Button onClick={onEdit} size={"small"} style={{ minWidth: 84 }} variant={"border"}>
                {"Yes"}
              </Button>
            </div>
            <div className={classes.closeWrap}>
              <CloseButton onClick={(e) => onOpen && onOpen()} />
            </div>
          </div>
          <div className={classes.submit}>
            <Button size={"small"} variant={"border"} onClick={submit}>
              {"Submit"}
            </Button>
            {hasHint && (
              <Button
                size={"small"}
                variant={"simple"}
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  onHintOpen();
                }}
              >
                {"Learn more"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
