import firebase from "firebase/app";
import { useCallback, useState } from "react";

export const useAnonymousLogin = (): [boolean, string | undefined, () => void] => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [uid, setUid] = useState<string | undefined>(undefined);

  const login = useCallback(async () => {
    try {
      const data = await firebase.auth().signInAnonymously();
      setUid(data?.user?.uid);
    } catch (e) {
      console.log(e);
    }
    setIsLoaded(true);
  }, []);

  return [isLoaded, uid, login];
};

export const useCustomLogin = (): [boolean, string | undefined, (_: string) => void] => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [uid, setUid] = useState<string | undefined>(undefined);

  const login = useCallback(async (token: string) => {
    try {
      const data = await firebase.auth().signInWithCustomToken(token);
      setUid(data?.user?.uid);
    } catch (e) {
      console.log(e);
    }
    setIsLoaded(true);
  }, []);

  return [isLoaded, uid, login];
}
