import firebase from "firebase/app";
import { config } from "process";
import { Action } from "providers/store";
import { AUTHORIZE_FAILED, AUTHORIZE_PROMPT } from "providers/types";
import { isEmptyBindingElement } from "typescript";
import { TENANT } from "./config";

const AUTHORIZATION_CODE = "__AUTHORIZATION_CODE__";
const TENANT_DATA = "__TENANT__";
const AUTHORIZATION_TIME = "__AUTHORIZATION_TIME__";
const COMPLETED = "__COMPLETED__";
const COOKIE_CONSENT = "__COOKIE_CONSENT__";
const IS_EMPLOYER = "__IS_EMPLOYER__";

export const validateCode = async (code: string) => {
  const result = await firebase.firestore().collection("tenants").where("activation_code", "==", code).get();
  if (TENANT && result.docs?.[0]?.data()?.key !== TENANT) {
    throw new Error("Sorry that code isn’t working.");
  }
  const tenant = {
    id: result.docs?.[0]?.id,
    ...result.docs?.[0]?.data()
  };

  if (result.empty) {
    throw new Error("Sorry that code isn’t working.");
  }
  return tenant;
};

export const validateEmployer = async (code: string) => {
  const result = await firebase.firestore().collection("tenants").where("employer_code", "==", code).get();
  const tenant = {
    id: result.docs?.[0]?.id,
    ...result.docs?.[0]?.data()
  };

  if (result.empty) {
    throw new Error("Sorry that code isn’t working.");
  }
  return tenant;
};

export const IDLE_TIMEOUT = 4 * 60000;
export const IDLE_TIMEOUT_ALERT = 3 * 60000;
let LAST_ACTIVITY:number | undefined = undefined;

export const setLastActivity = () => {
  LAST_ACTIVITY = Date.now();
}

export const startTimer = (
  dispatch: React.Dispatch<Action>,
  timeoutIdRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
) => {
  timeoutIdRef!.current = setInterval(() => {
    if (!!LAST_ACTIVITY) {
      const diff = Date.now() - LAST_ACTIVITY;
      if (diff > IDLE_TIMEOUT) {
        const timeoutId = timeoutIdRef!.current;
        dispatch({ type: AUTHORIZE_FAILED });
        clearAuthorization();
        if (timeoutId) {
          clearInterval(timeoutId); 
        }
      } else if (diff > IDLE_TIMEOUT_ALERT) {
        dispatch({ type: AUTHORIZE_PROMPT });
      } 
    }
    else {
      console.log('No last activity');
      setLastActivity();
    }
  }, 1000);
};

export const clearTimer = (
  timeoutIdRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
) => {
  const timeoutId = timeoutIdRef!.current;
  if (timeoutId) {
    clearInterval(timeoutId);
  }

  return timeoutId;
}

export const storeAuthorization = (code: string, tenant: string, isEmployer?: boolean) => {
  localStorage.setItem(AUTHORIZATION_CODE, code);
  if (isEmployer) {
    localStorage.setItem(IS_EMPLOYER, isEmployer.toString()); 
  }
  
  localStorage.setItem(TENANT_DATA, tenant);
  localStorage.setItem(AUTHORIZATION_TIME, Date.now().toString());
};

export const clearAuthorization = () => {
  localStorage.removeItem(AUTHORIZATION_CODE);
  localStorage.removeItem(TENANT_DATA);
  localStorage.removeItem(AUTHORIZATION_TIME);
};

export const storeCompletionStatus = () => {
  localStorage.setItem(COMPLETED, "true")
};

export const clearCompletionStatus = () => {
  localStorage.removeItem(COMPLETED)
};

export const getCompletionStatus = () => {
  return localStorage.getItem(COMPLETED) === "true" ? true : false;
};

export const getCookieConsentStatus = () => {
  return localStorage.getItem(COOKIE_CONSENT);
};

export const setCookieConsentStatus = (optionalTracking:boolean) => {
  return localStorage.setItem(COOKIE_CONSENT, optionalTracking ? "2" : "1");
};

export const loadAuthorization = () => {
  const code = localStorage.getItem(AUTHORIZATION_CODE);
  const timer = localStorage.getItem(AUTHORIZATION_TIME);
  const tenant = localStorage.getItem(TENANT_DATA);
  const isEmployer = localStorage.getItem(IS_EMPLOYER) || false;
  const completed = localStorage.getItem(COMPLETED) === "true" ? true : false;

  return {
    code, timer: Number(timer), tenant, completed, isEmployer
  };
};