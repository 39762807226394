import React, { useEffect, useContext } from "react";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import Loader from "components/Loader";
import Typography from "components/Typography";
import { EMAIL_ADDRESS, EMAIL_SUBJECT, EMAIL_BODY, LOGO_BLACK_PATH } from "../services/config";
import { useAuthorization } from "hooks/authorization";
import { Redirect } from "react-router-dom";
import { DASHBOARD } from "routes/types";
import { createStyles, withStyles } from "@material-ui/styles";
import Logo from "components/Logo";
import Image from "components/Image";
import { useSubmit } from "hooks/submit";
import { useAnonymousLogin } from "hooks/login";
import { FirebaseAuthContext } from "providers/firebase";

const styles = createStyles((theme: any) => ({
  root: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    padding: "48px",
    justifyContent: "space-between",
    flexDirection: "row",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "60px 8px",
      justifyContent: "center",
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    maxWidth: "430px",
    minWidth: "370px",
    color: "#FFF",
    backgroundColor: "#111216",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      padding: "4px",
    },
    "&::placeholder": {
      fontSize: "24px",
    },
  },
  button: {
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
  title: {
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    color: "#A03A1F",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  logo: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    height: "104px",
    display: "flex",
    overflow: "hidden",
    padding: "50px 38px 0 38px",
    justifyContent: "space-between",
    fontSize: "0.75em",
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  image: {
    backgroundPosition: "top",
    marginTop: "-4px",
    width: "auto !important",
    maxWidth: "270px",
    maxHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "171px",
      maxHeight: "44px",
    },
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "box" | "input" | "button" | "title" | "subtitle" | "logo" | "image", string>> };

export const EmployerHomePage = withStyles(styles)(({ classes }: any & ClassProps) => {
  const [isAutorized, code, error, loading, updateCode, , isEmployer, authorizeEmployer] = useAuthorization();
  const [, , login] = useAnonymousLogin();
  const { isCookieConsentConfirmed } = useContext(FirebaseAuthContext);

  const submit = useSubmit(() => {
    if (authorizeEmployer) authorizeEmployer();
  }, []);

  useEffect(() => {
    if (isCookieConsentConfirmed) login();
    else {
      //TODO: Show popup and on submit login
    }
  }, [login, isCookieConsentConfirmed]);

  return (
    <>
      {isAutorized && isEmployer && <Redirect to={DASHBOARD} />}
      <Box className={classes.root} alignItems={"center"} p={4} height={"100%"}>
        <Box className={classes.logo}>
          <Typography variant={"body1"} color={"#111216"}>
            {"Employer Portal"}
          </Typography>
          <img className={classes.image} src={LOGO_BLACK_PATH} />
        </Box>
        <Box className={classes.title}>
          <Typography variant={"h1"} color={"#A03A1F"}>
            {"SeeMe"}
          </Typography>
          <Typography paragraph variant={"body1"} className={classes.subtitle}>
            {"Data to humanise our working lives"}
          </Typography>
        </Box>
        <Box>
          <Box className={classes.box} display={"flex"}>
            <form onSubmit={submit}>
              <Input className={classes.input} textAlign={"center"} errorColor={"#C45B43"} errorMessage={error} placeholder={"Enter your access code"} filled value={code} onChange={updateCode} />
            </form>
            <Button size={"large"} color={"#A03A1F"} className={classes.button} disabled={loading} onClick={authorizeEmployer}>
              <Box display={"flex"}>
                {"Log in"}
                {loading && <Loader ml={2} width={26} />}
              </Box>
            </Button>
          </Box>
          {error && (
            <Box position={"absolute"} mt={8} px={4} py={2}>
              <a style={{ textDecoration: "none" }} href={`mailto:${EMAIL_ADDRESS}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`}>
                <Typography color={"#A03A1F"} variant={"caption"}>
                  {"Request access"}
                </Typography>
              </a>
            </Box>
          )}
        </Box>
        <Logo variant={"bottom"}>
          <Image src='powered-by.svg' width={200} ratio={234 / 21} />
        </Logo>
      </Box>
    </>
  );
});
