import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint14 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"A reasonable adjustment is a change that must be made to remove or reduce a disadvantage related to an employee disability when doing their job and/or a job applicant's disability when applying for a job. A reasonable adjustment could involve making changes to your working environment or working practices."}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Widening this to include conditions that you do not consider a disability yet would benefit from more inclusive working practices are also part of this. If you believe you need either a reasonable adjustment or other changes to support you, please discuss this with your manager."}</Typography>
            </Box>
        </Box>
    </Box>
  );
});