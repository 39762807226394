import React, { useEffect, useState } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import Box, { BoxProps } from "./Box";
import Modal from "components/Modal";
import { Hint2 } from "components/hints/Hint2";
import { Hint3 } from "components/hints/Hint3";
import { Hint4 } from "components/hints/Hint4";
import { Hint5 } from "components/hints/Hint5";
import { Hint6 } from "components/hints/Hint6";
import { Hint7 } from "components/hints/Hint7";
import { Hint8 } from "components/hints/Hint8";
import { Hint10 } from "components/hints/Hint10";
import { Hint11 } from "components/hints/Hint11";
import { Hint12 } from "components/hints/Hint12";
import { Hint13 } from "components/hints/Hint13";
import { Hint14 } from "components/hints/Hint14";
import { Hint15 } from "components/hints/Hint15";
import { Hint16 } from "components/hints/Hint16";
import { Hint17 } from "components/hints/Hint17";
import { Hint18 } from "components/hints/Hint18";
import { Hint25 } from "components/hints/Hint25";
import { Hint37 } from "components/hints/Hint37";
import { Hint38 } from "components/hints/Hint38";
import theme from "theme";
import { Hint23 } from "./hints/Hint23";

interface HintProps {
  order?: number;
  isDialogVisible: boolean;
  setDialogVisible: (value: boolean) => void;
}

const styles = createStyles((theme: any) => ({}));

type ClassProps = { classes: Partial<Record<"root", string>> };

export const HintsManager = withStyles(styles)(({ classes, order, isDialogVisible, setDialogVisible }: HintProps & ClassProps & BoxProps) => {
  return (
    <Modal
      p={3}
      overflow={"hidden"}
      visible={isDialogVisible}
      onClose={() => {
        setDialogVisible(false);
      }}
      variant='filled'
      size='medium'
    >
      <Box py={4}>
        {order === 2 && <Hint2></Hint2>}
        {order === 3 && <Hint37></Hint37>}
        {order === 4 && <Hint38></Hint38>}
        {order === 5 && <Hint4></Hint4>}
        {order === 6 && <Hint5></Hint5>}
        {order === 7 && <Hint6></Hint6>}
        {order === 8 && <Hint7></Hint7>}
        {order === 9 && <Hint8></Hint8>}
        {order === 11 && <Hint10></Hint10>}
        {order === 12 && <Hint14></Hint14>}
        {order === 13 && <Hint15></Hint15>}
        {order === 14 && <Hint16></Hint16>}
        {order === 15 && <Hint17></Hint17>}
        {order === 16 && <Hint18></Hint18>}
        {order === 22 && <Hint25></Hint25>}
        {order === 23 && <Hint23></Hint23>}
      </Box>
    </Modal>
  );
});
