import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  subtitle: {
    color: "#0680C2",
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "subtitle" | "text", string>> };

export const Hint16 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Physical alterations"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"For example, disability access (such as ramps, lifts and wider doors) or a quiet space. A quiet area allows people with learning disabilities, mental health conditions (e.g. depression) who are neurodiverse (e.g. Asperger’s syndrome) to have a break if they feel overwhelmed by work and social activities. This quiet space could also include comfortable seating to allow people with arthritis to sit and rest."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Altering or moving job roles"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"A new or long-standing disability may affect a person’s ability to carry out certain tasks. For example, someone whose hearing deteriorates over time will struggle to keep talking to customers over the phone. They could be allowed to focus on email customer service instead."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Flexible working arrangements"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Many people need time to attend medical appointments, such as those with diabetes. Similarly, those who have just received a diagnosis or struggled with a particularly hard period may need a phased return to work."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Accessible job applications"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"For example making job applications accessible to those with disabilities by providing different formats, such as audio and Braille, and by making the style and content appropriate. The font size should be at least 12 and avoid stating requirements that may discriminate against disabilities."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Safety measures"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"For example, install audio-visual fire alarms or use vibrating pagers for employees who are deaf. This includes ensuring that employees with epilepsy have someone accompanying them at all times."}</Typography>
              </div>
              <div style={{paddingBottom: "20px"}}>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"Staff training"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Staff should know what they can do to accommodate the needs of workers with disabilities. For example, if a worker is deaf, colleagues should ensure they communicate important instructions through writing and talk clearly to the person so they can lip read. Furthermore, staff may need to help someone during an evacuation, so should know what to do."}</Typography>
              </div>
                <Typography paragraph className={classes.subtitle} align={"left"} variant={"body1"}>{"New work equipment or software"}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"For example, larger monitors will help people with visual impairments, while software that helps with grammatical and numerical tasks can support people with dyslexia."}</Typography>
            </Box>
        </Box>
    </Box>
  );
});