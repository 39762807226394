import { ThemeProvider } from "@material-ui/styles";
import { AuthorizationProvider } from "providers/authorization";
import FirebaseAuthProvider from "providers/firebase";
import React from "react";
import Routes from "routes";
import { loadAuthorization } from "services/autorization";
import theme from "theme";
import AnswersProvider from "./providers/answers";

const App = () => {
  const data = loadAuthorization();
  return (
    <FirebaseAuthProvider>
      <AuthorizationProvider isAuthorized={!!data.code}>
        <AnswersProvider>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </AnswersProvider>
      </AuthorizationProvider>
    </FirebaseAuthProvider>
  );
};

export default App;
