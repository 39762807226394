import { styled, withStyles } from "@material-ui/styles";
import { Theme } from "theme";
import Box, { BoxProps } from "components/Box";

export const Loader = styled(Box)(({ theme, ...props }: BoxProps & { theme: Theme }) => ({
  height: props.width,
  border: `${props.width / 10}px solid ${theme.textDisabledColor}`,
  borderTop: `${props.width / 10}px solid ${theme.primaryColor}`,
  borderRadius: "50%",
  animation: "spin 2s linear infinite",

}));

export default withStyles((theme) => ({
  root: {
    height: (props) => props.width,
    border: (props) => `${props.width / 8}px solid ${theme.textDisabledColor}`,
    borderTop: (props) => `${props.width / 8}px solid ${theme.primaryColor}`,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  "@global": {
    "@keyframes spin": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" }
    },

  }
}))(Box);