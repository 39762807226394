import { createStyles, withStyles } from "@material-ui/styles";
import { BoxProps } from "components/Box";
import Typography from "./Typography";
import Tabs from "./Tabs";
import Tab from "./Tab";
import BubbleChart from "./BubbleChart";
import IconBubbleChart from "./IconBubbleChart";

const styles = createStyles((theme: any) => ({
  chart: {
    display: "flex",
    paddingTop: "16px",
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "24px",
    }
  },
  title: {
    color: "#484955",
    fontSize: "18px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"chart" | "title", string>> };
type StatsProps = { stats: any | undefined };

export default withStyles(styles)(({ stats, classes, }: StatsProps & BoxProps & ClassProps) => {
  const { sortedAnnualEarningsRace = [], sortedAvailabilityWorkJobEnjoy = [], sortedGenderUnpaidResponsibilities = [] } = stats;
  return (
    <Tabs>
      <Tab title={"Gender and Annual Earnings"}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography className={classes.title} paragraph variant={"body1"}>{"Annual earnings before tax by gender"}</Typography>
          <div className={classes.chart}>
            {sortedGenderUnpaidResponsibilities.map((b: any) => (
              <BubbleChart data={
                b.items.map((item: any) => ({ value: item.titleb, tooltip: item.tooltip, percentage: `${((item.amount / b.total ) * 100).toFixed(0)}%`}))
              } title={b['key']} />  
            ))}
            </div>
        </div>
      </Tab>
      <Tab title={"Ethnicity and Current Position"}>
        <Typography className={classes.title} paragraph variant={"body1"}>{"Current position in the workplace by Ethnicity"}</Typography>
        <div className={classes.chart}>
          {sortedAnnualEarningsRace.map((b: any) => (
              <BubbleChart data={
                b.items.map((item: any) => ({ value: item.titleb, tooltip: item.tooltip, percentage: `${((item.amount / b.total ) * 100).toFixed(0)}%`}))
              } title={b['key']} />  
            ))}
        </div>
      </Tab>
      <Tab title={"Engagement and Working Hours"}>
        <Typography className={classes.title} paragraph variant={"body1"}>{"Employee engagement levels by working hours per week"}</Typography>
        <div className={classes.chart}>
          {sortedAvailabilityWorkJobEnjoy.map((b: any) => (
              <IconBubbleChart data={
                b.items.map((item: any) => ({avatar: item.answerKey, value: item.titleb, tooltip: item.tooltip, percentage: `${((item.amount / b.total ) * 100).toFixed(0)}%`}))
              } title={b['key']} />  
            ))}
        </div>
      </Tab>
    </Tabs> 
  )
});