import { styled } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { Theme } from "theme";
import Tooltip from "components/Tooltip";
import Box, { BoxProps } from "components/Box";
import bubbles from "data/circles.json";
import { Tween, PlayState } from "react-gsap";
import { avatars } from "../providers/types";

//Sorting bubbles so that larger size are last in rendering
//for better tooltip handling of zIndex
bubbles.sort((b1, b2) => (b1.width > b2.width ? 1 : -1));

const getDelay = (key: string) => {
  if ("1" === key) return 0.7;
  if (".8" === key) return 0.8;
  if (".6" === key) return 0.9;
  if (".4" === key) return 1.0;
  if (".3" === key) return 1.1;

  return 0;
};

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
};

const getRandomAvatars = (count: number) => {
  let avatarsCommunity: any = {};

  const mids = bubbles.filter((b: any) => b.tag === "mid");
  count = count > 14 ? 14 : count;

  for (let i = 0; i < count; i++) {
    const midIndex = getRandomInt(mids.length);
    const bIndex = bubbles.indexOf(mids[midIndex]);
    if (avatarsCommunity[bIndex]) {
      i--;
      continue;
    }
    avatarsCommunity[bIndex] = {
      avatar: avatars[getRandomInt(avatars.length)].text,
    };
  }

  return avatarsCommunity;
};

const randomizeCurrentAvatar = () => {
  const bigs = bubbles.filter((b: any) => b.tag === "big");
  return bubbles.indexOf(bigs[getRandomInt(bigs.length)]);
};

const getBackgroundImage = (i: number, avatarsCommunity: any, currentAvatarIndex: number, avatar: string) => {
  if (i === currentAvatarIndex) {
    return `url(${process.env.PUBLIC_URL}/avatars/${avatar})`;
  }

  if (avatarsCommunity[i]) {
    return `url(${process.env.PUBLIC_URL}/avatars/${avatarsCommunity[i].avatar})`;
  }

  return "none";
};

interface CommunityChartProps {
  avatar: string;
  nickname: string;
  count: number;
  variant?: "white" | "blue";
  params?: string;
}

export default styled(({ count, nickname, variant, params, avatar, ...props }: CommunityChartProps & BoxProps) => {
  const [playState, setPlayState] = useState(PlayState.stop);
  const [avatarsCommunity, setAvatarsCommunity] = useState<any>({});
  const [mainAvatarPosition, setMainAvatarPosition] = useState<number>(0);

  useEffect(() => {
    setPlayState(PlayState.stop);

    setTimeout(() => {
      setAvatarsCommunity(getRandomAvatars(count));
      setMainAvatarPosition(randomizeCurrentAvatar());
      setPlayState(PlayState.play);
    }, 100);
  }, [count, params]);

  return (
    <Box {...props}>
      {bubbles.map((b, i) => (
        <Tween key={i} from={{ opacity: 0 }} to={{ opacity: b.opacity && !avatarsCommunity[i] ? parseFloat(b.opacity) : 1 }} delay={getDelay(b.opacity || "1")} duration={2.5} playState={playState}>
          <div
            data-id={i}
            {...props}
            style={{
              width: b.width,
              height: b.height,
              left: b.x - 200,
              top: b.y - 150,
              position: "absolute",
              borderRadius: "50%",
              backgroundColor: variant === "blue" ? "#819BA3" : "#fff",
              backgroundImage: getBackgroundImage(i, avatarsCommunity, mainAvatarPosition, avatar),
              backgroundRepeat: "no-repeat",
              zIndex: 10001,
              backgroundSize: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {i === mainAvatarPosition && nickname && (
              <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} delay={2.5 + getDelay(b.opacity || "1")} duration={2} playState={playState}>
                <Tooltip text={nickname || ""} top={-bubbles[mainAvatarPosition].height / 2 - 5} />
              </Tween>
            )}
          </div>
        </Tween>
      ))}
    </Box>
  );
})(({ theme }: { theme: Theme }) => ({
  width: "900px",
  height: "900px",
  position: "relative",
  display: "flex",
}));
