declare global {
    interface Window { gtag: any; }
}

window.gtag = window.gtag || {};

export const activateGA = () => {
    console.log('ga enabled');
    window.gtag('js', new Date());

    window.gtag('config', process.env.REACT_APP_GA || 'UA-167620518-1');
}