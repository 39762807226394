import { styled } from "@material-ui/styles";
import React from "react";
import Box, { BoxProps } from "components/Box";

type RoundedBoxProps = {
    variant?: "white" | "grey" | "blue" | undefined;
}

export default styled(
    ({ children, variant, color, onClick, ...props }: RoundedBoxProps & BoxProps) => (
      <Box onClick={onClick} {...props}>
        {children}
      </Box>
    ),
  )({
    backgroundColor: ({variant}: RoundedBoxProps) => variant === "blue" ? "#034C74" : variant === "grey" ? "#F5F5F5" : "#FFF",
    borderRadius: 32,
  });