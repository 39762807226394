import React, { useEffect, useContext } from "react";
import { FirebaseAuthContext } from "providers/firebase";
import { Redirect, Route } from "react-router-dom";
import { useAuthorization, useAuthorizationIdle } from "hooks/authorization";
import { IntroPage } from "pages/Intro";
import { AvatarPage } from "pages/Avatar";
import ConversationPage from "pages/Conversation";
import ProfilePage from "pages/Profile";
import CommunityPage from "pages/Community";
import { HOME, INTRO, AVATAR, CONVERSATION, PROFILE, COMMUNITY } from "routes/types";

const { Consumer } = FirebaseAuthContext;

const AuthorizedScreen = ({ children }: { children: React.ReactNode }) => {
  const [isAuthorize] = useAuthorization();
  return <>{isAuthorize ? children : <Redirect to={HOME} />}</>;
};

const EmployeeRoutes = () => {
  const { isAnonymous } = useContext(FirebaseAuthContext);

  useAuthorizationIdle(isAnonymous);

  return (
    <>
      <AuthorizedScreen>
        <Route path={INTRO} exact>
          <IntroPage />
        </Route>
        <Route path={AVATAR} exact>
          <AvatarPage />
        </Route>
        <Route path={CONVERSATION} exact>
          <ConversationPage />
        </Route>
        <Route path={PROFILE} exact>
          <ProfilePage />
        </Route>
        <Route path={COMMUNITY} exact>
          <CommunityPage />
        </Route>
      </AuthorizedScreen>
    </>
  );
};

export default EmployeeRoutes;
