import React, { useContext, useState } from "react";
import { BoxProps } from "./Box";
import Image from "components/Image";
import { Edit } from "components/Edit";
import Typography from "components/Typography";
import {AnswersContext} from "providers/answers"
import { createStyles, withStyles } from '@material-ui/styles';
import {Tween, PlayState} from "react-gsap"
import { HintsManager } from "components/HintsManager";

const styles = createStyles((theme: any) => ({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    transform: "translateX(-490px)",
    pointerEvents: "none",
    [theme.breakpoints.down('sm')]: {
      transform: "translateX(-400px)",
    },
    [theme.breakpoints.down('xs')]: {
      transform: "translateX(-400px)",
    }
  },
  response: {
    display: "flex",
    margin: "0 10px",
    borderRadius: 32,
    minHeight: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.primaryColor,
    color: "#fff",
    marginRight: "16px",
    padding: "12px 32px",
    maxWidth: "50vw",
    transition: "opacity .3s ease-out",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "85vw",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "calc(100vw - 100px)",
    }
  },
  responseWrap: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-end",
    height: "auto",
    marginLeft: "-80px",
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  responseWrapSimple: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

interface ResponseProps {
  icon?: string;
  order?: number;
  open?: boolean;
  hasHint?: boolean;
  onOpen?: () => void;
}

type ClassProps = { classes: Partial<Record<"root" | "response" | "tween" | "responseWrap" | "responseWrapSimple", string>> };

export default withStyles(styles)(({ classes, children, onChange, onSubmit, onOpen, hasHint, open, icon, order }: ResponseProps & ClassProps & BoxProps ) => {  
  const [isDialogVisible, setDialogVisible] = useState(false);
  const { data, setData } = useContext(AnswersContext);
  const edit = (event: any) => {
    let firstItem: any = data?.find(item => item.order === order + 1) || {};
    firstItem.edit = true;    
    if(setData) setData([...(data || [])]);
    if (onChange) onChange(event);
  }
  const submit = (event: any) => {
    if (onSubmit) onSubmit(event);
  }

  let playState = PlayState.stop;
  if (open !== undefined) {
    playState = open ? PlayState.play : PlayState.reverse;
  }

  return (
      <div className={classes.responseWrap}>
        <Tween from={{transform: "translateX(-80px)"}} duration={0.5} playState={playState}>
          <div className={classes.root}>
              <div className={ classes.response} style={{opacity: data?.[order]?.edit ? 0 : 1}}>
                  {icon ? <Image src={`${process.env.PUBLIC_URL}/${icon}`} width={32} height={32}/> : <Typography variant={"caption"}>{children}</Typography>}
              </div> 
              <Edit open={open} onHintOpen={() => setDialogVisible(true)} onOpen={onOpen} edit={data?.[order]?.edit} hasHint={hasHint} onEdit={edit} onSubmit={submit}></Edit> 
          </div>     
        </Tween>
        <HintsManager isDialogVisible={isDialogVisible} setDialogVisible={(val) => {setDialogVisible(val) }} order={order} />
      </div>
    );
  }
);