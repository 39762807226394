import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint15 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}><strong>Neurodiversity</strong>{" is the diversity of human minds, the infinite variation in neurocognitive functioning that can lead to differences in thinking, attention and memory. Neurodiversity encompasses a range of differences in individual brain function and behavioural traits regarded as part of variation in the human population."}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}><strong>Neurodivergent</strong>{" means having a mind that functions in ways which diverge significantly from the dominant societal standards of typical cognitive function."}</Typography>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Neurodiversity is not a trait that any individual possesses or can possess. When an individual or group of individuals diverges from the dominant societal standards of typical neurocognitive functioning, they don’t “have neurodiversity,” they’re neurodivergent. "}<strong>Neurotypical</strong>{" is the opposite of neurodivergent and means having a style of neurocognitive functioning that falls within what is currently considered dominant societal standards."}</Typography>
            </Box>
        </Box>
    </Box>
  );
});