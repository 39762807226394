import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint6 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
        <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{backgroundColor: "#FFF"}}>
            <Box className={classes.content}>
                <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>{"Race is defined as “a category of humankind that shares certain distinctive physical traits“."}</Typography>
            </Box>
        </Box>
    </Box>
  );
});