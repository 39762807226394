import { store } from "providers/authorization";
import { AUTHORIZE, AUTHORIZE_FAILED, AUTHORIZE_SUCCESS } from "providers/types";
import { useCallback, useContext, useEffect, useState } from "react";
import { loadAuthorization, setLastActivity, startTimer, storeAuthorization, validateCode, validateEmployer } from "services/autorization";
import { hasTouch } from "services/utils";

export const useAuthorization = (): [boolean, string, string | undefined, boolean, (value: string) => void, (keepTimerStopped?: boolean) => void, boolean, () => void, (_code: string) => void] => {
  const { state, timeoutIdRef, timeoutAlertIdRef, dispatch } = useContext(store);
  const [code, updateCode] = useState(process.env.NODE_ENV === 'development' ? "ERWV3TW0" : "");

  const authorize = useCallback(async (keepTimerStopped?: boolean) => {
    dispatch!({ type: AUTHORIZE, payload: code });
    try {
      const tenant = await validateCode(code);
      dispatch!({ type: AUTHORIZE_SUCCESS, payload: {tenant} });
      storeAuthorization(code, JSON.stringify(tenant));
      if (!keepTimerStopped) {
        startTimer(dispatch!, timeoutIdRef!); 
      }
    } catch (e) {
      dispatch!({ type: AUTHORIZE_FAILED, payload: "Sorry that code isn’t working." });
    }
  }, [code, dispatch, timeoutIdRef, timeoutAlertIdRef]);

  const authorizeEmployer = useCallback(async () => {
    dispatch!({ type: AUTHORIZE, payload: code });
    try {
      const tenant = await validateEmployer(code);
      dispatch!({ type: AUTHORIZE_SUCCESS, payload: {tenant, isEmployer: true} });
      storeAuthorization(code, JSON.stringify(tenant), true);
      startTimer(dispatch!, timeoutIdRef!);
    } catch (e) {
      dispatch!({ type: AUTHORIZE_FAILED, payload: "Sorry that code isn’t working." });
    }
  }, [code, dispatch, timeoutIdRef, timeoutAlertIdRef]);


  const authorizeLoggedUser = useCallback(async (_code: string) => {
    dispatch!({ type: AUTHORIZE, payload: _code });
    try {
      const tenant = await validateCode(_code);
      dispatch!({ type: AUTHORIZE_SUCCESS, payload: {tenant} });
      storeAuthorization(_code, JSON.stringify(tenant));
    } catch (e) {
      dispatch!({ type: AUTHORIZE_FAILED, payload: "Sorry that code isn’t working." });
    }
  }, [dispatch, timeoutIdRef, timeoutAlertIdRef]);


  return [state!.isAuthorized, code, state!.error, state!.loading, updateCode, authorize, state!.isEmployer, authorizeEmployer, authorizeLoggedUser];
};

export const useAuthorizationIdle = (isAnonymous?: boolean) => {
  const { timeoutIdRef, dispatch, state } = useContext(store);

  const startTimeout = useCallback((offset: number = 0) => {
    startTimer(dispatch!, timeoutIdRef!);
  }, [timeoutIdRef, dispatch]);

  const resetActivity = useCallback(() => {
    if (!state?.prompt) {
      setLastActivity()
    }
  }, [setLastActivity, state?.prompt])

  useEffect(() => {
    document.onmousemove = resetActivity;
    document.onkeypress = resetActivity;
    if (hasTouch) {
      document.ontouchstart = resetActivity;
      document.ontouchmove = resetActivity;
    }

    resetActivity();
  }, [resetActivity]);

  useEffect(() => {    
    const data = loadAuthorization();

    if (state?.loading || state?.validated) {
      return;
    }
    
    if (data.completed) {
      return;  
    }

    if (!data.code) {
      dispatch!({ type: AUTHORIZE_FAILED });
      return;
    }

    (async () => {
        let tenant = null;  
      try {
          if (state?.isEmployer) {
            tenant = await validateEmployer(data.code!);          
          }
          else {
            tenant = await validateCode(data.code!);
          } 
        }
        catch(e) {}
        
        if (tenant) {
          dispatch!({ type: AUTHORIZE_SUCCESS, payload: {tenant} });
          if(isAnonymous) startTimeout(Date.now() - data.timer);
        } else {
          dispatch!({ type: AUTHORIZE_FAILED });
        }
      })();
  }, [dispatch, startTimeout, state?.isEmployer, isAnonymous]);
};