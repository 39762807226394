import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import Title from "components/Title";
import Bubble from "components/Bubble";

interface QuestionHeadingProps {
    d?: any;
}

const styles = createStyles((theme: any) => ({
    root: {

    },
    heading: {
        paddingLeft: "64px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "24px",
        }
    },
    hint: {
        paddingLeft: "80px",
        marginBottom: "24px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "40px",
            marginBottom: "16px",
        }
    }
}));

type ClassProps = { classes: Partial<Record<"root" | "heading" | "hint", string>> };

export default withStyles(styles)(({ d, classes }: QuestionHeadingProps & ClassProps) => {
    return (
        <div className={classes.root}>
            {d.group && <div className={classes.heading}><Title mt={-3} subtitle={d.subtitle}>{d.group}</Title></div>}
            {d.hint && <div className={classes.hint}><Bubble variant={"dark"}>{d.hint}</Bubble></div>}
        </div>
    );
});
