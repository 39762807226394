import firebase from "firebase/app";
import { FIREBASE } from "services/config";

export const submit = async (payload: any) => {
    try {
        if (payload?.answers?.[0]?.avatar) {
            await firebase.firestore().doc(`users/${firebase.auth().currentUser?.uid}/applications/see_me`).set({
            answers: [...payload.answers.map((answer: any) => ({
                id: answer.id,
                key: answer.key || "NA",
                answers: answer.answer || []
            }))],
            avatar: payload?.answers?.[0]?.avatar.indexOf('.png') > -1 ? payload?.answers?.[0]?.avatar : `${payload?.answers?.[0]?.avatar}.png`,
            tenant: payload.tenant,
            nickname: payload?.answers?.[0]?.answer?.[0]?.text,
            }, { merge: true });
        }
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    
    return true;
};


export const getAnswers = async () => {
    try {
        const data = (await firebase.firestore().doc(`users/${firebase.auth().currentUser?.uid}/applications/see_me`).get()).data() || {};
        
        const result = data?.answers?.reduce((acc: any, answer: any) => {
            acc[answer.key] = {
                answer: answer.answers,
                avatar: answer.key === 'nickname' ? data.avatar : undefined,
            }
            return acc;
        }, {}) || {};

        return result;
    }
    catch (e) {
        console.log(e);
        throw e;
    }    
};

export const getEmployerStats = async (tenant: string, qa: string, qb: string) => {
        try {
        const resp = await fetch(`https://us-central1-${FIREBASE['projectId']}.cloudfunctions.net/getStats`, {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "data": 
                    {
                        "tenant": tenant,
                        "env": process.env.REACT_APP_ENV || "dev",
                        "qa": qa,
                        "qb": qb
                    }
            })
        })
        if (resp.status === 200) {
            const result = await resp.json();
            return result["result"];
        }
        
        return [];
    }
    catch (e) {
        console.log(e);
    }
    
    return 0; 
};

export const getResultsCount = async (filters: any, tenant: string) => {
    try {
        const [q1, q2, q3] = filters.split('-');

        if (!q1 && !q2 && !q3) {
            return;
        }

        const resp = await fetch(`https://us-central1-${FIREBASE['projectId']}.cloudfunctions.net/getReport`, {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "data": 
                    {
                        "tenant": tenant,
                        "q1": q1,
                        "q2": q2,
                        "q3": q3,
                    }
            })
        })
        if (resp.status === 200) {
            const result = await resp.json();
            return result['result'][0]['AMOUNT'];
        }
        
        return 0;
    }
    catch (e) {
        console.log(e);
    }
    
    return 0; 
}
