import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SCIENCE } from "routes/types";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  image: {
    position: "absolute",
    top: 128,
    right: 0,
    width: "400px",
    height: "480px",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 118,
      width: "340px",
      height: "408px",
    },
    [theme.breakpoints.down("xs")]: {
      top: 218,
      width: "220px",
      height: "264px",
    },
  },
  content: {
    width: "68vw",
    paddingBottom: "104px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    paddingTop: "40px",
    paddingLeft: 32,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100vw",
    },
  },
  border: {
    width: "90vw",
    marginRight: "5vw",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #E8E8E9",
      marginBottom: "10px",
    },
  },
  button: {
    height: 32,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  header: {
    display: "flex",
    paddingLeft: "24px",
    justifyContent: "center",
    alignItems: "center",
    width: "60vw",
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
      alignItems: "flex-start",
      marginTop: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "380px",
      alignItems: "flex-start",
      marginTop: "42px",
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    paddingBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  whitebox: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    paddingBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "200px",
    },
  },
  back: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  quotes: {
    position: "absolute",
    top: 50,
    left: "-20vw",
    width: "18vw",
    [theme.breakpoints.down("xs")]: {
      top: 8,
      left: "-10vw",
    },
  },
  backtop: {
    position: "absolute",
    top: 23,
    left: 64,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      top: 13,
      left: 0,
    },
  },
  backbottom: {
    position: "absolute",
    top: -16,
    left: 32,
    color: "#0680C2",
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
}));

type ClassProps = { classes: Partial<Record<"image" | "content" | "box" | "button" | "header" | "footer" | "whitebox" | "border" | "back" | "quotes" | "backtop" | "backbottom", string>> };

export const DataPage = withStyles(styles)(({ classes }: ClassProps) => {
  const history = useHistory();
  console.log(history);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      <Box className={classes.header}>
        <Typography variant={"h2"} color={"#fff"}>
          {"How we use data"}
        </Typography>
      </Box>
      <Box pt={18} className={classes.whitebox}>
        {history.length > 2 && (
          <Button onClick={back} className={classes.backtop} variant={"text"}>
            <Box display={"flex"} alignItems={"center"}>
              <img alt={""} src={"back.svg"} />
              <Box pl={"6px"} className={classes.back} component={"h4"}>
                {"Back"}
              </Box>
            </Box>
          </Button>
        )}
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Data that helps humanise the workplace"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "By collecting, analysing and presenting data across many different organisations we can show the huge variety of people that make up the workplace – helping your organisation to understand its people better; to form more inclusive strategies and to ensure that each individual feels seen and heard."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "Research has proven that a feeling of ‘belonging’ at a company has a strong impact on engagement, that those from underrepresented backgrounds tend to experience company culture less positively than employees in a majority group, and that this disparity is compounded for employees in multiple minority groups."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "We anonymise and aggregate the data provided by everyone who completes SeeMe to build the picture your company needs to truly ‘See’ all its employees, and to understand and embrace the human complexity of its workforce."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "Your organisation will only ever see individual data in an aggregated way. It will not see your individual data unless you choose to share it with colleagues, your line manager or others internally. Your organisation has committed to responsibly handling the aggregated data, in line with our data privacy policy, to help it cultivate an inclusive culture which supports 21st-century work practices."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"Your leadership team has committed to engaging with the SeeMe digital tool and process as one of the ways to support the organisation on its journey to ensure diversity, inclusion and a sense of belonging."}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Our aim is to make people’s lives better in important ways with personal data"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"To collate your personal data, SeeMe takes you on a journey designed to highlight the multifaceted and nuanced nature of our modern day lives."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"The picture it builds, and the story it tells, will help you navigate your working-life and keep you working well."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "As well as helping organisations better understand the people who work for them, SeeMe is designed to help you see your intersecting identities and work/life priorities by bringing your data together into your SeeMe profile, once you’ve submitted your data."
            }
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {
              "Whilst your data is anonymised, you can see in the community screen other people who share some of the same data points as you. You will only ever see your avatar and that of others to keep your data confidential."
            }
          </Typography>
        </Box>
        <Box className={classes.content} position={"relative"}>
          <Typography paragraph color={"#0680C2"} align={"left"} variant={"h3"}>
            {"Security at the heart of everything"}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"We have designed our technology and practices to protect your personal information, keeping it safe from fraud, crime or misuse."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"We will never sell your personal data and we only keep it for the minimum period necessary to provide you with our services."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"All data is stripped of any personally identifiable tags, and we keep the aggregated, anonymised data only for statistical and research purposes."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"We recognise that trust is earned and that, by providing us with your personal information, you have understandably high expectations of us."}
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"We commit to listening and responding to user feedback, so please contact us at"}{" "}
            <a style={{ color: "#0680C2" }} href='mailto:dataprivacy@glassmoon.co.uk' title='Email to Glassmoon Data Privacy'>
              dataprivacy@glassmoon.co.uk
            </a>{" "}
            with any questions about our policies or practices.
          </Typography>
          <Typography paragraph align={"justify"} variant={"body1"}>
            {"You can access our full Data Privacy Policy at"}{" "}
            <a style={{ color: "#0680C2" }} href='https://www.glassmoon.co.uk/privacy-policy' target='_blank' title='Glassmoon Privacy Policy'>
              www.glassmoon.co.uk/privacy-policy
            </a>
            .
          </Typography>
        </Box>
      </Box>
      <Box className={classes.footer}>
        {history.length > 2 && (
          <Button onClick={back} className={classes.backbottom} variant={"text"}>
            <Box display={"flex"} alignItems={"center"}>
              <img alt={""} src={"back.svg"} />
              <Box pl={"6px"} className={classes.back} component={"h4"}>
                {"Back"}
              </Box>
            </Box>
          </Button>
        )}
        <Box mt={2} className={classes.box}>
          <Button onClick={() => window.scrollTo(0, 0)} className={classes.button} size={"small"} variant={"simple"}>
            {"How we use data"}
          </Button>
          <Button onClick={() => navigateTo(SCIENCE)} className={classes.button} size={"small"} variant={"simple"}>
            {"How SeeMe works"}
          </Button>
        </Box>
        <Box className={classes.border}></Box>
        <Box pb={"4px"} pr={3}>
          <img width={200} alt={""} src={"powered-by.svg"} />
        </Box>
      </Box>
    </Box>
  );
});
