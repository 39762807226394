import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import LoadingBubble from "components/LoadingBubble";

const styles = createStyles((theme: any) => ({
    root: {
        marginLeft: "80px",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "40px"
        }
    },
}));

type ClassProps = { classes: Partial<Record<"root", string>> };

export default withStyles(styles)(({ classes }: ClassProps) => {
    return (
        <div className={classes.root}>
            <LoadingBubble /> 
        </div>
    );
});