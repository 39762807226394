import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
    },
  },
  subtitle: {
    color: "#0680C2",
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "subtitle" | "text", string>> };

export const Hint25 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      <Box display={"flex"} position={"relative"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{ backgroundColor: "#FFF" }}>
        <Box className={classes.content}>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Artistic pursuits (including drawing, painting and sculpture) "}
            </Typography>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Home-based hobbies (baking, cooking and gardening)"}
            </Typography>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Solo sports (including cycling and running)"}
            </Typography>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Group sports (including rugby, cricket and netball)"}
            </Typography>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Traditional recreations (including calligraphy, knitting and origami) "}
            </Typography>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>
              {"Performance pastimes (including acting, dancing and stand-up comedy)"}
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
});
