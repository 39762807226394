import Box from "components/Box";
import Bubble from "components/Bubble";
import Loading from "components/Loading";
import QuestionHeading from "components/QuestionHeading";
import { MultipleAnswerQuestion } from "components/questions/MultipleAnswerQuestion";
import { SingleAnswerQuestion } from "components/questions/SingleAnswerQuestion";
import { Question, TextQuestion } from "components/questions/TextQuestion";
import React, { useRef, useState, useCallback, useContext, useEffect } from "react";
import Typography from "components/Typography";
import Button from "components/Button";

import { AnswerProps, getQuestions } from "services/questions";
import { useHistory } from "react-router-dom";
import { AVATAR, PROFILE } from "routes/types";
import { AnswersContext } from "../providers/answers";
import { FirebaseAuthContext } from "providers/firebase";
import { Hint } from "components/Hint";
import { Tween, PlayState } from "react-gsap";
import Logo from "components/Logo";
import Image from "components/Image";
import { LOGO_WHITE_PATH } from "services/config";

const ConversationPage = () => {
  var [number, setNumber] = useState(1);
  var [keys, setKeys] = useState<any>({});
  var [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { data, setData, getAnswers } = useContext(AnswersContext);
  const { isAnonymous } = useContext(FirebaseAuthContext);
  const INDEXES_WITH_HINTS = [2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 22, 23];
  var [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    (async () => {
      const nicknameQuestion = data?.find((d) => d.key === "nickname");
      const ageQuestion = data?.find((d) => d.key === "age");
      const { key = "", answer } = nicknameQuestion || {};

      if (answer?.[0]?.text === "Prefer not to say") {
        if (ageQuestion) {
          ageQuestion.title = ageQuestion?.originalTitle?.replace(" <nickname>,", ",") || "";
        }
      } else if (ageQuestion) {
        ageQuestion.title = ageQuestion?.originalTitle?.replace("<nickname>", answer?.[0]?.text || "") || "";
      }
      if (!isAnonymous) {
        setNumber(2);
      }
      if (setData) {
        setData([...(data || [])]);
      }
      setScrollWidth(window.innerWidth - document.documentElement.clientWidth);
    })();
  }, []);

  const navigateToProfile = useCallback(() => {
    if (setData) setData([...(data || [])]);
    history.push(PROFILE);
  }, [data, setData, history]);

  const navigateToAvatar = useCallback(() => {
    history.push(AVATAR);
  }, [data, setData, history]);

  useEffect(() => {
    (async () => {
      if (!data?.length) {
        navigateToAvatar();
      }

      if (getAnswers) {
        let answers = await getAnswers();
        if (Object.keys(answers).length > 0 && isAnonymous) {
          navigateToProfile();
        }
      }
    })();
  }, [navigateToProfile, getAnswers, data]);

  const nextQuestion = (value: AnswerProps) => {
    setKeys(() => {
      return { ...keys, [value.key]: value.answer[0].text === "Prefer not to say" ? "" : value.key === "nickname" ? value.answer[0].text : `<${value.answer[0].text}>` };
    });
    let firstItem: any = data?.find((item) => item.id === value.id) || {};
    firstItem.answer = value.answer;
    if (setData) setData([...(data || [])]);
  };

  const onAnimationEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setNumber((numb) => numb + 1);
      setLoading(false);
      ref.current && ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 1000);
  };

  return (
    <Box pt={8} pb={5} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
      <Box display={"flex"} flexDirection={"column"} width={"100vw"} pb={"28px"} pt={5} pl={2} pr={2} textAlign={"center"}>
        <Typography variant={"h3"} paragraph color={"#fff"}>{`So there are ${data?.length ? data?.length + 1 : 0} questions in all.`}</Typography>
      </Box>
      {data?.map((d, i) => {
        return (
          number >= i && (
            <div key={i} style={{ display: !isAnonymous && d.key === "age" ? "none" : "flex" }}>
              {i === number && <div ref={ref}></div>}
              <div style={{ display: "flex", marginTop: "50px", flexDirection: "column", flex: 1 }}>
                <QuestionHeading d={d} />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Hint invisible={loading || !i || INDEXES_WITH_HINTS.indexOf(i) === -1 || number !== i} order={i}></Hint>
                  <div style={{ width: `calc(100vw - ${scrollWidth}px)` }}>
                    {d.type === "text" ? (
                      <></>
                    ) : d.type.includes("single") ? (
                      <SingleAnswerQuestion
                        order={i}
                        hasHint={INDEXES_WITH_HINTS.indexOf(i) !== -1}
                        variant={d.type}
                        columns={d.columns}
                        options={d.options}
                        subOptions={d.subOptions}
                        onChange={(value) => {
                          nextQuestion({ ...d, answer: value });
                        }}
                        onSkip={() => nextQuestion({ ...d, answer: [{ key: -1, text: "Prefer not to say" }] })}
                        onAnimationEnd={onAnimationEnd}
                      >
                        <Bubble>{d.title.split(/(?:<|>)+/).map((p) => (p === "br/" ? <p key={"p" + i}></p> : keys.hasOwnProperty(p) ? keys[p] : p))}</Bubble>
                      </SingleAnswerQuestion>
                    ) : (
                      <MultipleAnswerQuestion
                        order={i}
                        hasHint={INDEXES_WITH_HINTS.indexOf(i) !== -1}
                        columns={d.columns}
                        options={d.options}
                        onChange={(value) => {
                          nextQuestion({ ...d, answer: value });
                        }}
                        onAnimationEnd={onAnimationEnd}
                      >
                        <Bubble>{d.title}</Bubble>
                      </MultipleAnswerQuestion>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })}
      {loading && <Loading />}

      {data && number === data.length && number > 0 && (
        <Box display={"flex"} flexDirection={"column"} width={"100vw"} pt={5} pb={"140px"} textAlign={"center"}>
          <Typography color={"#FFF"} variant={"h3"}>
            {"Well done! You got there."}
          </Typography>
          <Typography color={"#FFF"} variant={"h3"}>
            {"Ready to see your SeeMe profile?"}
          </Typography>
          <Button onClick={navigateToProfile} size={"large"} variant={"filled"} style={{ margin: "14px auto", padding: "9px 40px !important", display: "flex" }}>
            Submit
          </Button>
        </Box>
      )}

      {data && number < data.length && <div style={{ marginBottom: "320px" }}></div>}

      {isAnonymous && (
        <Logo variant={"bottom"}>
          <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
        </Logo>
      )}
    </Box>
  );
};

export default ConversationPage;
