import { styled } from "@material-ui/styles";
import React from "react";
import Box, { BoxProps } from "components/Box";
import Typography from "components/Typography";
import theme from 'theme';

interface BubbleProps {
  variant?: "light" | "dark";
}

export default styled(
  ({ children, variant, ...props }: BoxProps & BubbleProps) => {
    return (
      <Box {...props}>
        <Typography variant={"caption"}>{children}</Typography>
      </Box>
    );
  }
)({
  borderRadius: 32,
  borderTopLeftRadius: 0,
  width: "50vw",
  backgroundColor: ({ variant }: BubbleProps) => variant === 'dark' ? theme.primaryColor : "#fff",
  color: ({ variant }: BubbleProps) => variant === 'dark' ? "#fff" : theme.primaryColor,
  padding: "20px 32px",
  [theme.breakpoints.down('xs')]: {
    width: "85vw",
    padding: "12px 16px",
  }
});