import { createStyles, styled, withStyles } from "@material-ui/styles";
import Box, { BoxProps } from "components/Box";
import Button from "components/Button";
import Image from "components/Image";
import Logo from "components/Logo";
// import Modal from "components/Modal";
import Typography from "components/Typography";
import React, { ReactFragment, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AVATAR, SCIENCE, DATAUSAGE, PROFILE } from "routes/types";
import { AnswersContext } from "providers/answers";
import { FirebaseAuthContext } from "providers/firebase";
import theme from "theme";
import { LOGO_WHITE_PATH } from "services/config";

interface ImageButtonProps {
  src?: string;
  text?: string[];
  optional?: string;
  children?: ReactFragment
}

const getTexts = (text?: string[]) => {
  return text?.map((t: string) => (
    <>
      <Typography align='center' variant={"h5"} color={"#fff"}>
        {t}
      </Typography>
    </>
  ));
};

const ImageButton = styled(({ onClick, src, text, optional, children, ...props }: ImageButtonProps & BoxProps) => (
  <Box flexDirection={'column'} m={1} {...props} onClick={onClick}>
    <Box alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
      {getTexts(text)}
    </Box>
    {optional && <Box m={6} mt={4} mb={4}>
      <Typography align='center' variant={"body1"} color={"#fff"}>{optional}</Typography>
    </Box>}
    {children}
  </Box>
))({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "34vw",
  height: "34vw",
  borderRadius: 10000,
  backgroundColor: theme.primaryColor,
  [theme.breakpoints.down("sm")]: {
    width: "60vh",
    height: "60vh",
  },
  [theme.breakpoints.down("xs")]: {
    width: "calc(100vw + 10)",
    height: "calc(100vw + 10)",
  },
});

const styles = createStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
    },
  },
  button: {
    marginTop: "16px",
    marginLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonInCircle: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    marginTop: "16px",
    marginLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      height: 43,
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    paddingLeft: "32px",
    paddingBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100vw",
    },
  },
  border: {
    width: "90vw",
    marginRight: "5vw",
    height: "1px",
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid white",
      marginBottom: "40px",
      paddingTop: "10px",
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  link: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "28px !important",
      paddingLeft: "20px !important",
    },
  },
  logo: {
    paddingBottom: "8px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
  },
}));

type ClassProps = { classes: Partial<Record<"root" | "button" | "buttonInCircle" | "box" | "footer" | "border" | "link" | "logo", string>> };

export const IntroPage = withStyles(styles)(({ classes }: ClassProps) => {
  // const [isVideoVisible, setVideoVisible] = useState(false);
  const history = useHistory();
  const { getAnswers } = useContext(AnswersContext);
  const { isAnonymous } = useContext(FirebaseAuthContext);
  const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  useEffect(() => {
    (async () => {
      if (getAnswers) {
        let answers = await getAnswers();
        if (Object.keys(answers).length > 0) {
          navigateTo(PROFILE);
        } else {
          setHasLoadedAnswers(true);
        }
      }
    })();
  }, [navigateTo, getAnswers]);

  return (
    <Box display={"flex"} p={6} height={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"stretch"}>
      {hasLoadedAnswers && (
        <Box className={classes.root}>
          <Typography variant={"h2"} color={"#fff"} align={'center'}>
            {isAnonymous ? "Welcome" : "Welcome to SeeMe"}
          </Typography>
          <ImageButton text={["A tool for Diversity,", "Equity, Intersectional Inclusion", "& Belonging."]} optional={isAnonymous ? undefined : "SeeMe will ask you a series of questions. This enables you to see your intersecting identities. Helping you to navigate life with a sense of purpose & meaning and keeping you well."}>
            <>
              {<Button className={classes.buttonInCircle} size={"large"} onClick={() => navigateTo(AVATAR)}>
                {"Start"}
              </Button>}
            </>
          </ImageButton>
          <Button className={classes.button} size={"large"} onClick={() => navigateTo(AVATAR)}>
            {"Start"}
          </Button>
          {isAnonymous && (
            <Box className={classes.footer}>
              <Box className={classes.box}>
                <Button onClick={() => navigateTo(DATAUSAGE)} size={"small"} className={classes.link} variant={"simple"}>
                  {"How we use data"}
                </Button>
                <Button onClick={() => navigateTo(SCIENCE)} size={"small"} className={classes.link} variant={"simple"}>
                  {"How SeeMe works"}
                </Button>
              </Box>
              <Box className={classes.border}></Box>
              <Logo className={classes.logo} variant={"bottom"}>
                <Image src={"powered-by-white.svg"} width={200} ratio={234 / 21} />
              </Logo>
            </Box>
          )}
          {/* <Modal visible={isVideoVisible} onClose={() => {
          setVideoVisible(false);
        }} variant="none" size="medium">
          <iframe title="vimeo-player" src="https://player.vimeo.com/video/336812660" width="640" height="360"></iframe>
        </Modal> */}
        </Box>
      )}
    </Box>
  );
});
