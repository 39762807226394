import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { FIREBASE } from "./config";


firebase.initializeApp(FIREBASE);
if (process.env.NODE_ENV === 'development') {
    //firebase.firestore().useEmulator("localhost", 8080);
}

firebase.firestore();

export const FieldValue = firebase.firestore.FieldValue;