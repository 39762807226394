import Box from "components/Box";
import Typography from "components/Typography";
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";

const styles = createStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    height: "100%",
    [theme.breakpoints.down('xs')]: {
        width: "70vw",
    }
  },
  text: {
    color: "#565966",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
}));

type ClassProps = { classes: Partial<Record<"content" | "text", string>> };

export const Hint37 = withStyles(styles)(({ classes }: ClassProps) => {
  return (
    <Box className={classes.content}>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>This question enables people to tell us if they are a person of trans experience. </Typography>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>Most people are not trans, so most people will answer 'Yes' to this question:</Typography>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>If you are a man who was assigned male at birth (i.e. you are a man and you had 'M' or 'boy' put on your birth certificate) then you would typically answer 'Yes' to this question.</Typography>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>If you are a woman who was assigned female at birth (i.e. you are a woman and you had 'F' or 'girl' put on your birth certificate) then you would typically answer 'Yes' to this question.</Typography>
        <Typography paragraph className={classes.text} align={"left"} variant={"body1"}>People who answer ‘Prefer to self-describe' to this question include people who are trans, non-binary, gender fluid, agender, men with a trans history, women with a trans history, people with culturally-specific gender identities, and others who have other identities / experiences like or similar to these.</Typography>
    </Box>
  );
});