export const LOGIN = "/__login";
export const TOKEN = "/__token";
export const HOME = "/";
export const INTRO = "/p/intro";
export const AVATAR = "/p/avatar";
export const SCIENCE = "/science";
export const DATAUSAGE = "/data";
export const CONVERSATION = "/p/conversation";
export const PROFILE = "/p/profile";
export const COMMUNITY = "/p/community";
export const COMPLETED = "/completed";
export const PROTECTED = "/p"
export const EMPLOYER_PROTECTED = "/e"
export const EMPLOYER_HOME = "/employer"
export const DASHBOARD = "/e/dashboard";
export const RUBY = "/ruby";
export const COOKIE = "/cookie";